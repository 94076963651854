import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './utils/helper'

/**
 * Components
 */
import UserCard from './components/users/card/UserCard.vue'
Vue.component("user-card", UserCard);

import Documents from './components/Documents/DocumentsComponent.vue'
Vue.component("documents-component", Documents);

import DocumentsPaginate from './components/Documents/DocumentsPaginate/DocumentsPaginateComponent.vue'
Vue.component("documents-paginate", DocumentsPaginate);

import DocumentTypesPaginate from './components/Documents/DocumentTypesPaginate/DocumentTypesPaginateComponent.vue'
Vue.component("document-types-paginate", DocumentTypesPaginate);

import UsersProject from './components/users/UsersProject/UsersProjectComponent.vue'
Vue.component("users-project", UsersProject);

import ProjectsUser from './components/Projects/ProjectsUser/ProjectsUserComponent.vue'
Vue.component("projects-user", ProjectsUser);

import Template from './components/Projects/Template/TemplateComponent.vue'
Vue.component("template-project", Template);

import TransactionTypes from './components/Transactions/TransactionTypes/TransactionTypesComponent.vue'
Vue.component("transaction-types", TransactionTypes);

import TransactionTypesProject from './components/Transactions/TransactionTypes/TransactionTypesProjectComponent.vue'
Vue.component("transaction-types-project", TransactionTypesProject);

import TransactionTypesPaginate from './components/Transactions/TransactionTypesPaginate/TransactionTypesPaginateComponent.vue'
Vue.component("transaction-types-paginate", TransactionTypesPaginate);

import DocumentExportsPaginateComponent from './components/DocumentExports/DocumentExportsPaginateComponent.vue'
Vue.component("document-exports-paginate-component", DocumentExportsPaginateComponent); 

import TransactionsProject from './views/Projects/transactions/transactions.vue'
Vue.component("transactions-project", TransactionsProject);

import TransactionView from './components/Transactions/TransactionView/TransactionViewComponent.vue'
Vue.component("transaction-view", TransactionView);

import TransactionStatus from './components/Transactions/TransactionStatus/TransactionStatusComponent.vue'
Vue.component("transaction-status", TransactionStatus);

import SwitchButtonComponent from './utils/SwitchButtonComponent.vue'
Vue.component("switch-button", SwitchButtonComponent);

import JsonExcel from './utils/JsonExcel.vue'
Vue.component('downloadExcel', JsonExcel)

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); 

import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './utils/toast'

import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import { ValidationProvider, extend } from 'vee-validate';
import i18n from './i18n'

import vSelect from "vue-select";
Vue.component("v-select", vSelect); 
 

import "vue-select/dist/vue-select.css";

import App from '@/App'
import store from './store'

// As a plugin
import VMask from 'v-mask'
import Lightbox from 'vue-easy-lightbox'
import money from 'v-money'

import VueMoment from "vue-moment";
Vue.use(VueMoment);

Vue.use(Lightbox)

import VueRx from 'vue-rx'
// Use build files
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'
import 'vuejs-clipper/dist/vuejs-clipper.css'

Vue.use(VueRx)
Vue.use(VuejsClipper)


Vue.config.productionTip = false

extend('secret', {
    validate: value => value === 'example',
    message: 'This is not the magic word'
});

import '@/assets/scss/app.scss'

Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(money, {precision: 4})
Vue.use(require("vue-chartist"));
Vue.use(VMask);

Vue.prototype.$title = 'IBS Painel Financeiro'

new Vue({
    router,
    store,
    i18n,
    base: process.env.BASE_URL,
    render: h => h(App)
}).$mount('#app')