import Vue from 'vue';

Vue.prototype.$alt = function(title = 'success', icon = 'success') {
    Vue.prototype.$swal({
        toast: false,
        icon,
        title,
        showConfirmButton: true,
        timer: 3500,
        timerProgressBar: true
    })
}

Vue.prototype.$toast = function(title = 'success', icon = 'success') {
    Vue.prototype.$swal({
        toast: true,
        position: 'top-right',
        icon,
        title,
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
    })
}

Vue.prototype.$confirmDelete = function(title = 'Deletar?', confirmCallback = function() { return false }, denyCallback = function() { return false }) {
    Vue.prototype.$swal({
        title,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Confirmar',
        denyButtonText: `Cancelar`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            confirmCallback()
        } else if (result.isDenied) {
            denyCallback()
        }
    })
}

Vue.prototype.$overlay = function(){
    Vue.prototype.$swal({
        toast: false,
        icon: null,
        title: null,
        showConfirmButton: false,
        timer: null,
        timerProgressBar: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        background: '#fff0',
        backdrop: '#fff9',
        didOpen: () => {
            Vue.prototype.$swal.showLoading()
        }
    })
}
Vue.prototype.$overlayClose = function(){
    Vue.prototype.$swal.close()
}