const axios = require("axios");
axios.defaults.withCredentials = true

import Paginate from '@/utils/api/paginate.js';
import Auth from '@/utils/auth.js';
import { validateError } from '@/utils/errors';
 

const transfersModel = {
    async getPaginate(params) {
        params.query = Paginate.formatQueryParams(params.query) 
        const response = await axios.get(encodeURI(`${process.env.VUE_APP_API_HOST}/projects/${params.id}/transfers/?`) + params.query, {
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err));
        return response
    },
    create(params) { 
        return axios.post(`${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transfers/` , params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    update(params) {
        params.data.append('_method', 'PUT');
        return axios.post(`${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transfers/`, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    confirm(params) {
        params.data = {_method: 'PUT'};
        return axios.post(`${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transfers/${params.id}/confirm`, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    cancel(params) {
        params.data._method = 'PUT';
        return axios.post(`${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transfers/${params.id}/cancel`, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    delete(params) {
        params.data = {_method: 'DELETE'};
        return axios.delete(`${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transfers/${params.id}`, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },

    async getById(params) {
        if(!params) params = {}
        if(!params.query) params.query = {
            'with[]': [
                'transactions',
                'transactions.transaction_type',
                'transactions.project',
                'toProject',
                'fromProject',
            ]
        },
        params.query = Paginate.formatQueryParams(params.query) 
        const response = await axios.get(process.env.VUE_APP_API_HOST + '/projects/'+params.project_id+'/transfers/' +  params.transfer_id + '?' + params.query, {
            headers: {
                'Accept': 'application/json',
            }
        }).catch(err => validateError(err));
        return response
    },
    count(params) {
        let paginateQueryString = Paginate.convertPaginateOptionsAndFiltersToQueryString(params);
        return axios.get(process.env.VUE_APP_API_HOST + '/transfers/count?' + paginateQueryString, {
            headers: { 'X-XSRF-TOKEN': Auth.getAuthorizationToken() }
        });
    }, 
};

export default transfersModel;