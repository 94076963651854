import Vue from "vue"

export const ADMIN = 1
export const MANAGER = 2
export const OPERATOR = 3
export const ACCOUNTANT = 4

/**
 * 
 * @returns Object
 */
export function listFieldById(){
    let list = {}
    list[ADMIN] = 'ADMIN' 
    list[MANAGER] = 'MANAGER' 
    list[OPERATOR] = 'OPERATOR' 
    list[ACCOUNTANT] = 'ACCOUNTANT' 
    return list
}  

export const FEATURES_SCOPES = [
    {
        path: 'app.settings',
        scopes: [ADMIN]
    }, 
    {
        path: 'projects.index.btn_create',
        scopes: [ADMIN]
    }, 
    {
        path: 'projects.index.btn_edit',
        scopes: [ADMIN, MANAGER]
    },  
    {
        path: 'projects.index.btn_documents',
        scopes: [ADMIN, MANAGER]
    }, 
    {
        path: 'projects.index.btn_users',
        scopes: [ADMIN, MANAGER]
    }, 
    {
        path: 'projects.index.btn_reports',
        scopes: [ADMIN, MANAGER, ACCOUNTANT]
    }, 
    {
        path: 'projects.index.btn_dashboard',
        scopes: [ADMIN, MANAGER, ACCOUNTANT]
    }, 
    {
        path: 'projects.index.subprojects.btn_create',
        scopes: [ADMIN]
    },
    {
        path: 'projects.transactions.btn_create',
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'projects.transaction.btn_project_edit',
        scopes: [ADMIN, MANAGER]
    },
    {
        path: 'projects.transaction.view.actions',
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'projects.transaction.project_balance',
        scopes: [ADMIN, MANAGER, ACCOUNTANT]
    },
    {
        path: 'projects.transfer.create',
        scopes: [ADMIN, MANAGER]
    },
    {
        path: 'projects.edit.btn_create_template',
        scopes: [ADMIN]
    },
    {
        path: 'projects.edit.transaction_types',
        scopes: [ADMIN]
    },
    {
        path: 'projects.view.budget_estimative',
        scopes: [ADMIN, MANAGER, ACCOUNTANT]
    },
    
    /** USERS */
    
    {
        path: 'users.index.btn_create',
        scopes: [ADMIN, MANAGER]
    },
    {
        path: 'users.index.btn_edit',
        scopes: [ADMIN, MANAGER]
    },
    {
        path: 'users.edit.group_id.' + ADMIN,
        scopes: [ADMIN]
    },
    {
        path: 'users.edit.group_id.' + MANAGER,
        scopes: [ADMIN, MANAGER]
    },
    {
        path: 'users.edit.group_id.' + ACCOUNTANT,
        scopes: [ADMIN, ACCOUNTANT]
    },
    {
        path: 'users.edit.group_id.' + OPERATOR,
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'users.edit.credencials',
        scopes: [ADMIN, MANAGER]
    },
    
    /** DOCUMENTS */
    {
        path: 'documents.select_document_type',
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'documents.create_document_type',
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'documents.btn_edit',
        scopes: [ADMIN, MANAGER, OPERATOR]
    }, 
    {
        path: 'documents.btn_save',
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'documents.dropzone',
        scopes: [ADMIN, MANAGER, OPERATOR]
    },
    {
        path: 'documents.export',
        scopes: [MANAGER, ACCOUNTANT]
    },
    

    /** DASHBOARD */

    
    {
        path: 'dashboard.btn_documents',
        scopes: [ADMIN, MANAGER]
    },
    
    
]


