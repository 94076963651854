
import PageHeader from "@/components/PageHeader/PageHeader";
import appConfig from "@/app.config";
import VueApexCharts from 'vue-apexcharts';
import { mapState, mapActions } from "vuex";

/**
 * Starter page
 */
export default {
  components: { 
    apexchart: VueApexCharts,
    PageHeader 
    },
  page: {
    title: "Starter Page",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Início",
          href: "/",
          active: true,
        },
      ],

      series: [
        {
          name: 'Custos',
          type: 'column',
          data: [23, 11, 22, 27, 13, 22, 37, 21, 44]
        }, 
        /*{
          name: 'Entradas',
          type: 'line',
          data: [30, 25, 36, 30, 45, 35, 64, 52, 59]
        }*/
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        dataLabels: {
          enabled: false
        }, 
        colors:['#017bc2', '#d7ad07', '#00f'],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            //inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: [
          '01/08', 
          '02/08', 
          '03/08', 
          '04/08', 
          '05/08', 
          '06/08', 
          '07/08', 
          '08/08', 
          '09/08', 
        ],
        markers: {
          size: 0
        },
        xaxis: {
          //type: 'datetime'
        },
        yaxis: {
          title: {
            //text: 'Points',
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                if (y == "0") return "R$ 0,00";
                y = parseFloat(y / 100);
                let priceBr = y.toLocaleString("pt-br", { minimumFractionDigits: 2 });
                return 'R$ ' + priceBr;
              }
              return y;
        
            }
          }
        }
      },

      LoadingSeries: [
        {
          name: 'Carregando',
          type: 'column',
          data: [23, 11, 22, 27, 13, 22, 37, 21, 44]
        }, {
          name: 'Dados',
          type: 'line',
          data: [30, 25, 36, 30, 45, 35, 64, 52, 59]
        }
      ],
      LodingChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        dataLabels: {
          enabled: false
        }, 
        colors:['#eee', '#eee', '#00f'],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            //inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: [
          '01/08', 
          '02/08', 
          '03/08', 
          '04/08', 
          '05/08', 
          '06/08', 
          '07/08', 
          '08/08', 
          '09/08', 
        ],
        markers: {
          size: 0
        },
        xaxis: {
          //type: 'datetime'
        },
        yaxis: {
          title: {
            //text: 'Points',
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
        
            }
          }
        }
      },

       PaginateQuery: {
          'with[]':['subprojects'],
          orderBy:'name',
          orderDirection: 'asc',
          'withCount[]':['documents', 'transactions'],
        },

      api_domain: process.env.VUE_APP_API_HOST,
      Projects: null,
      ProjectSelected: null,
      FinancialSummarie: null,
      TransactionsPaginate: null,
      DocumentsPaginate: null,
      LoadingDays: false,
      Days: null,
    }
  },
methods:{
    ...mapActions("projects", ["getProjects", "getProjectById"]), 
    ...mapActions("financialSummaries", ["getfinancialSummaries"]),
    ...mapActions('transactions', ['getTransactions']),
    ...mapActions('documents', ['getDocuments']),
    async get(){

        let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
        const response = await this.getProjects({query: PaginateQuery})
        
        if(response){
          let projects = []
          let subprojects = []

          response.data.data.map(project => {})

            projects = response.data.data
            projects.map(project => {
              project.subprojects.map(subproject => {
                subprojects.push(subproject.id)
              })
            })

            this.Projects = []
            projects.map(project => {
              if(subprojects.indexOf(project.id) !== -1) return project

              this.Projects.push(project)
              project.subprojects.map(subproject => {
                subproject.sub = true
                this.Projects.push(subproject)
              })
            })  

            if(!this.Projects[0]) return false

            for(let i in this.Projects){
              if(this.Projects[i].id == this.$route.params.project_id){
                this.ProjectSelected = this.Projects[i]
                break
              }
            }
            
            if(!this.ProjectSelected && this.Projects[0]){
                this.ProjectSelected = this.Projects[0]
            }

            this.getProjectData()

        }
        
    },

    async projectSelected(){
        this.getProjectData()
    },

    async getfinancialSummariesByMonth(){
        this.FinancialSummarie = null
        const response = await this.getfinancialSummaries({
          id: this.ProjectSelected.id, 
          data: {
            reference_date: `${this.$moment().format('YYYY-MM-')}01`, 
            period_code: 'MONTH'
          }
        })

        if(response){
            this.FinancialSummarie = response.data
        }
    },

    async getfinancialSummariesByDay(date){
      const response = await this.getfinancialSummaries({
        id: this.ProjectSelected.id, 
        data: {
          reference_date: date.format('YYYY-MM-DD'), 
          period_code: 'DAY'
        }
      })

      if(response){
        this.Days.map(day => {
          if(day.date === date.format('DD/MM'))
          day.expenses_volume = response.data.financial_summary.expenses_volume
          day.incomes_volume = response.data.financial_summary.incomes_volume
          day.prev_project_balance = response.data.previous_financial_summary.project_balance
          return day
        })
      }
    },

    async getRepotsDays(){ 
        var times = this.$moment().diff(this.$moment().subtract(10, 'days'), 'days')
        this.LoadingDays = true
        this.Days = []
        var id = this.$route.params.id
        var promises = []
        for(let i=0; i<=times; i++){
            
            let date =  this.$moment().subtract(i, 'days')
            this.Days = [{
              date: date.format('DD/MM'), 
              expenses_volume: 0,
              incomes_volume: 0,
              prev_project_balance: 0,
            }, ...this.Days ]
            promises = [...promises, this.getfinancialSummariesByDay(date)]
        }
       // this.loading = true 
        const response = await Promise.all(promises) 
        this.LoadingDays = false

        //this.prepareJsonToXlsExport()


        //this.series[0].data = []
        //this.series[1].data = []
        //this.chartOptions.labels = []
        let expenses_volumes = [] 
        let incomes_volumes = [] 
        let labels = []
        let expenses_volume_sum = 0
        let incomes_volume_sum = 0
        this.Days.map((day, i) => {
          labels = [...labels, day.date]
          expenses_volume_sum
          expenses_volumes = [...expenses_volumes, day.expenses_volume ] 
          
          if(i===0){
            incomes_volume_sum += day.prev_project_balance 
          }else{
            incomes_volume_sum += day.incomes_volume 
          }
          incomes_volumes = [...incomes_volumes,  incomes_volume_sum]
        })
        
        this.series[0].data = expenses_volumes
        //this.series[1].data = incomes_volumes
        this.chartOptions.labels = labels
        if(response){
            
        }

    },
    
    async getProject(){
        const response = await this.getProjectById() 
        if(response){
        }
    },

    async getDocumentsPaginate(){
        this.DocumentsPaginate = []

        const response = await this.getDocuments({
          endpoint: 'projects',
          documentable_id: this.ProjectSelected.id,
          query: {
            orderBy: 'id',
            orderDirection: 'asc',
            limit: 4,
            'with[]': 'document_type'
        },
        }) 
        
        if(response){
            response.data.data.map(transaction => { 
                this.DocumentsPaginate = [...this.DocumentsPaginate, transaction]
                return transaction
            })
        }  
    },

    async getTransactionsPaginate(){
        this.TransactionsPaginate = []

        const response = await this.getTransactions({
            id: this.ProjectSelected.id,
            query: {
                page: 1,
                orderBy: 'id',
                orderDirection: 'desc',
                limit: 3,
                'with[]': [
                    //'transaction_type',
                    'createdBy',
                    'createdBy.group',
                ],
            }
        }) 
        
        if(response){
            response.data.data.map(transaction => { 
                this.TransactionsPaginate = [...this.TransactionsPaginate, transaction]
                return transaction
            })
        }  
    },
    is_processed(transaction){
        if(!transaction.processed_at)
        return false
        
        if(this.$moment(transaction.processed_at).unix() <= this.$moment().unix())
        return true
        
        return false
    },
    is_awaiting_processing(transaction){
        if(!transaction.processed_at)
        if(this.$moment(transaction.date).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD'))
        return true
        
        return false
    },
    is_pending_confirmation(transaction){

        if(this.is_processed(transaction)){
            return false
        }else if(this.is_awaiting_processing(transaction)){
            return false
        } else {
            return true
        }

    },
    is_overdue(transaction){
        if(transaction.processed_at)
        return false

        if(this.$moment(transaction.date).unix() < this.$moment().unix())
        return true
        
    },

    async getProjectData(){
        this.getfinancialSummariesByMonth()
        this.getTransactionsPaginate() 
        this.getDocumentsPaginate() 
        this.getRepotsDays()
        //this.getProject() 
    },

    documentsAuthorized(stateUser){
      return this.$featureAuthorizer.isAuthorized('dashboard.btn_documents', stateUser)
    },

  },
  created() {
      this.get()
  },
  computed:{
    stateUser: {
      get(){
        return this.$store.state.auth.user
      }
    },
    getSeries(){
      return this.series
    }
  },
  middleware: "authentication",
}; 
