const cloneDeep = require('lodash.clonedeep');

export default {
    formatFormData(data) {
        const formData = new FormData();
        let sanitizedData = this.sanitizeData(data);
        this.appendFormValue(sanitizedData, formData);
        return formData;
    },
    appendFormValue(dataObject, formData, originalKey = "") {
        for (let key in dataObject) {
            if (!dataObject.hasOwnProperty(key)) continue;
            if (typeof dataObject[key] === 'undefined') continue;
            if (typeof dataObject[key] !== "object" || dataObject[key] instanceof File || (Array.isArray(dataObject[key]) && dataObject[key].length == 0)) {
                formData.append(this.formatKey(originalKey, key), dataObject[key]);
            } else {
                this.appendFormValue(dataObject[key], formData, this.formatKey(originalKey, key));
            }
        }
    },
    formatKey(originalKey, key) {
        if (originalKey) {
            return originalKey + "[" + key + "]";
        } else {
            return key;
        }
    },
    sanitizeData(data) {
        let sanitizedData = cloneDeep(data);
        return sanitizedData;
    }
}