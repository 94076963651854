import documentExportsModel from '@/models/documentExports/documentExports';

const actions = { 
    getDocumentExports({ commit, dispatch },params) {
        return documentExportsModel.getPaginate(params)
    },
    getDocumentExportsByProjectId({}, id) {
        return documentExportsModel.getPaginateByProjectId(id)
    },
    getDocumentExportById({ commit, dispatch },id) {
        return documentExportsModel.getById(id)
    },
    createDocumentExport({ commit, dispatch },data) {
        return documentExportsModel.create(data)
    },
    updateDocumentExport({ commit, dispatch },params) {
        return documentExportsModel.update(params)
    },
    syncDocumentExports({ commit, dispatch },params) {
        return documentExportsModel.sync(params)
    },
    deleteDocumentExport({ commit, dispatch },id) {
        return documentExportsModel.delete(id)
    },
}

export default actions;