const axios = require("axios");
axios.defaults.withCredentials = true

import Paginate from "@/utils/api/paginate.js";
import { validateError } from '@/utils/errors';

const groupModel = {
    async getPaginate(params) {
      params.query = Paginate.formatQueryParams(params.query) 
      const response = await axios.get(`${process.env.VUE_APP_API_HOST}/groups/?${params.query}`, {
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err));
        return response
    },
};

export default groupModel;