import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import FormatFormData from '@/utils/api/users/format-form-data.js';
import { mapActions } from 'vuex';
import PageHeader from "@/components/PageHeader/PageHeader";
import Auth from '@/utils/auth.js';
import { ADMIN } from "../../../authguard/groups.map";

export default {
    mixins: [validationMixin],
    components: {
        PageHeader,
    },
    data() {
        return {
            UserId: null,
            api_domain: process.env.VUE_APP_API_HOST,
            headerItems: [{
                text: 'Início',
                href: '/'
                },
                {
                    text: 'Usuários',
                    active: false
                },
                {
                    text: 'Usuário',
                    active: true
                }
            ],
            ADMIN,
            formData: {
                username: null,
                name: null,
                cpf: null,
                email: null,
                password: null,
                group_id: '',
            },
            passFormData: {
                password: null,
                confirmpass: null,
            },
            GroupsList: null,
            apiFormErrors: {},
            creating: false,
            openForm: false,
            title: 'Usuários',
            saving: false,
            saving_pass: false,
            imgURL: null,
            Avatar: null,
            User: null,
        }

    },
    validations: {
        formData: {
            username: {
                required,
                minLength: minLength(3)
            },
            name: {
                required,
                minLength: minLength(6)
            },
            group_id: {
                required,
                minLength: minLength(1)
            },
            email: {
                required,
                email
            },
        },
        passFormData:{
            password: {
                required,
                minLength: minLength(6)
            },
            confirmpass: {
                required,
                sameAsPassword: sameAs(function() {
                    return this.passFormData.password;
                  })
            },
        }
    },
    created(){
        document.title = 'Editar Usuário - ' + this.$title

        this.UserId = Auth.getAuthUserId()
        try{
            this.UserId = this.$route.params.id ? this.$route.params.id : this.UserId
        }catch(err){}
        this.get()
    },
    methods: {
        ...mapActions('users', ['updateUser','getUserById']),
        ...mapActions("groups", ["getGroups"]),

        avatarUpload(e){
            if (e.target.files.length !== 0) {
                this.$bvModal.show('modal-clipper')
                if(this.imgURL) URL.revokeObjectURL(this.imgURL)
                this.imgURL = window.URL.createObjectURL(e.target.files[0]);
            }
        },

        getAvatarCurrentUser(avatar) { 
            return avatar ? avatar : `${this.api_domain}/users/${this.UserId}/avatar`
        },

        getAvatarImage() {
            const canvas = this.$refs.clipper.clip();//call component's clip method
            this.Avatar = canvas.toDataURL("image/jpeg", 1);//canvas->image
            this.$bvModal.hide('modal-clipper')
        },

        async getUser(){

            const user_resp = await this.getUserById(this.UserId)
            if(user_resp.data.id)
            this.setUserFormData(user_resp.data)
            this.User = user_resp.data
        },
        async getGroupsList(){
            const group_resp = await this.getGroups({query: {'withScopes[]': 'authUserCanCreate'}})
            this.GroupsList = await group_resp ? group_resp.data.data : null
        },
        
        async get() { 
            this.getUser()
            this.getGroupsList()
        },

        setUserFormData(data){
            this.formData.username = data.username
            this.formData.name = data.name
            this.formData.cpf = data.cpf
            this.formData.email = data.email
            this.formData.group_id = data.group_id
        }, 

        async sendFormPass(e) {
            e.preventDefault();

            this.$v.passFormData.$touch();
            if (this.$v.passFormData.$anyError || this.saving) {
                return;
            }

            this.saveUser(this.passFormData)
        },
        async sendForm(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError || this.saving) {
                return;
            }

            let data = JSON.parse(JSON.stringify(this.formData)) 
            let file = null

            if(this.Avatar)
            file = this.imageBase64ToBlob(this.Avatar) 

            this.saveUser(data, file)
        },

        imageBase64ToBlob(dataURL){
            var blobBin = atob(dataURL.split(',')[1]);
            var array = [];
            for(var i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            let file =  new Blob([new Uint8Array(array)], {type: 'image/jpg'});
            return file
        },
         
        async saveUser(data, avatar = null){
            
            this.saving = true
            const formattedFormData = FormatFormData.formatFormData(data);

            if(avatar)
            formattedFormData.append('avatar_file', avatar, 'avatar.jpg' )

            const response = await this.updateUser({id: this.UserId, data: formattedFormData})
            this.saving = false

            if(response) this.$toast('Dados salvos!')
            
        },
        editUserIsAuthorized(stateUser, user){ 
            return stateUser.id === user.created_by_user_id || stateUser.id === user.id || this.$featureAuthorizer.isAuthorized('users.edit.group_id.'+user.group_id, stateUser)
        },
        editUserCredencialsIsAuthorized(stateUser){ 
            return this.$featureAuthorizer.isAuthorized('users.edit.credencials', stateUser)
        },
    },
    computed:{
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        }
    },
}