import { validateError } from '@/utils/errors';
const axios = require("axios");

const loginModel = {

    async sendLogin(data) {
        const response = await axios.post(process.env.VUE_APP_API_HOST + '/login', data, {
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err))
        return response
    },
    async emailToPasswordReset(data) {
        const response = await axios.post(process.env.VUE_APP_API_HOST + '/forgot-password', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err))
        return response
    },
    async passwordReset(data) {
        const response = await axios.post(process.env.VUE_APP_API_HOST + '/reset-password', data, {
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err))
        return response
    },
};

export default loginModel;