<template>
  <span>
    <b-button
      :variant="btnType"
      :size="btnSize"
      v-b-modal="`modal-${token}`"
      v-html="text"
    ></b-button>
    <b-modal
      :ref="`modal-${token}`"
      @shown="focusInput()"
      :id="`modal-${token}`"
      hide-header
      hide-footer
      centered
    >
      <div class="">
        <h4>Criar {{ $t("titles.transaction_type") }}</h4>

        <b-form
          ref="createTransactionType"
          @submit.prevent="sendForm"
          method="post"
        >
          <div class="py-2">
            <label class="mb-0" for="name-1"
              >{{ $t("titles.transaction_type") }} Pai</label
            >
            <div v-if="transactionTypesPaginate">
              <v-select
                v-if="!transactionType"
                :reduce="(types) => types.id"
                @option:selected="transactionTypeSelected"
                v-model="formData.parent_id"
                :options="transactionTypesPaginate"
                label="name"
                :selectable="(option) => !option.subtype_disabled"
              ></v-select>
              <h5 v-else>
                {{
                  transactionType.parent
                    ? transactionType.parent.name
                    : "Nenhuma"
                }}
              </h5>
            </div>
          </div>

          <div class="py-2">
            <b-form-group id="input-group-1" class="mb-0" label-for="name-1">
              <label class="mb-0" for="name-1"
                >Nome da {{ $t("titles.transaction") }}</label
              >
              <b-form-input
                class="pb-0"
                ref="transactionName"
                v-model.trim="$v.formData.name.$model"
                type="text"
                placeholder="Ex.: ..."
                :class="{ 'is-invalid': $v.formData.name.$error }"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="py-2">
            <!-- <b-form-group v-if="!transactionType" class="pb-0"  v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    id="btn-radios-1"
                                    v-model="formData.is_expense"
                                    :options="[
                                        {value: true, text: 'Despesa'},
                                        {value: false, text: 'Receita'},
                                    ]"
                                    :aria-describedby="ariaDescribedby"
                                    name="radios-btn-is-expense"
                                    class="pb-0"
                                    buttons
                                ></b-form-radio-group>
                            </b-form-group> -->

            <!-- <div v-else>
                                <label>Modelo</label>
                                <h5>{{transactionType.is_expense ? 'Despesa' : 'Receita'}}</h5>
                            </div> -->
          </div>

          <div class="d-flex justify-content-end py-2">
            <b-form-group id="input-group-1" class="mb-0" label-for="name-1">
              <b-button
                variant="link"
                @click="cancelForm"
                v-b-toggle="`create-transaction-type-${token}`"
                type="button"
                >Cancelar</b-button
              >
              <b-button variant="primary" :disabled="creating" type="submit"
                ><b-spinner small label="salvando" v-if="creating"></b-spinner>
                Salvar</b-button
              >
            </b-form-group>
          </div>
        </b-form>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { uuid } from "vue-uuid";
import FormatFormData from "@/utils/api/transactions/format-form-data.js";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  props: {
    projectId: null,
    transactionTypesPaginate: {
      required: true,
      type: Array,
      default: [],
    },
    text: {
      default: `Criar Nova Rúbrica`,
      type: String,
    },
    btnType: {
      default: `primary`,
      type: String,
    },
    btnSize: {
      default: `sm`,
      type: String,
    },
    transactionType: {
      default: null,
    },
  },
  data() {
    return {
      token: uuid.v4(),
      creating: false,
      formData: {
        parent_id: null,
        name: null,
        //is_expense: true,
      },
    };
  },
  methods: {
    ...mapActions("transactionTypes", [
      "createTransactionType",
      "updateTransactionType",
    ]),
    focusInput() {
      this.$refs.transactionName.focus();
    },
    cancelForm() {
      this.formData.name = null;
      this.formData.parent_id = null;
      this.$refs.createTransactionType.reset();
      this.$v.formData.$reset();
      this.$refs[`modal-${this.token}`].hide();
    },
    async sendForm(e) {
      e.preventDefault();

      this.$v.formData.$touch();
      if (this.$v.formData.$anyError || this.creating) {
        return;
      }

      let data = {
        name: this.formData.name,
      };
      if (this.formData.parent_id) {
        data.parent_id = this.formData.parent_id;
      } else {
        //data.is_expense = this.formData.is_expense;
      }

      this.creating = true;
      if (this.transactionType) {
        const response = await this.updateTransactionType({
          id: this.transactionType.id,
          data: data,
        });
        if (response) {
          this.transactionType.name = JSON.parse(
            JSON.stringify(this.formData.name)
          );
          this.$emit("saved", response.data);
        }
      } else {
        const response = await this.createTransactionType(data);
        if (response) this.$emit("created", response.data);
      }
      this.creating = false;
      this.cancelForm();
    },
    transactionTypeSelected(e) {},
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  mounted() {
    if (this.transactionType) {
      this.formData.name = JSON.parse(
        JSON.stringify(this.transactionType.name)
      );
    }
  },
};
</script>

<style lang="scss">
.new-transaction-type-container {
  border: 3px dashed #ddd;
  border-radius: 10px;
  min-height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  transform: translateY(-10px);
  .btn.btn-secondary {
    margin-bottom: 0;
  }
}
</style>
