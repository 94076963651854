import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
    components:{
        draggable,
    },
    props:{
        projectId: null,
        EnabledUsers: null,
        UsersSelected: null,
        placeholder: {
            default: 'Selecionar quais usuários estão neste projeto'
        },
        emptyUsersPlaceholder: {
            default: 'Não há Usuários adicionadas neste projeto.'
        },
    },
    data(){
        return {
            UsersPaginate: null,
            Users: null,
            UsersDetach: [],
            user_temp: null,
            saving: false,
            loading: false,
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        ...mapActions("projects", ["getUsersByProjectId",'attachUserProject','detachUserProject']),
        ...mapActions("users", ["getUsers"]),
        async get(){ 
            await this.paginateUsers()
            await this.getUsersProject()
        },
        

        async getUsersProject(){
            
            this.Users = []
            if(this.EnabledUsers) {
                if(this.UsersSelected) 
                this.Users = this.UsersSelected
                return 
            }
            
            this.loading = true
            const response = await this.getUsersByProjectId({ id: this.projectId })
            this.loading = false

            if(response){
                let users       =   response.data.data
                let usersPag    =   this.UsersPaginate
                for(let i in users) {
                    for(let ii in usersPag) { 
                        if(usersPag[ii].id === users[i].id ){
                            usersPag[ii].saved = true
                            this.usersSelected(usersPag[ii])
                        }
                    }
                }
            }
        },

        async paginateUsers(){
            if(this.EnabledUsers) {
                this.UsersPaginate = this.EnabledUsers
                return 
            }

            this.loading = true
            const UsersResponse = await this.getUsers({query: {'with[]':['group'], 'where[group_id:<>]': 1}})
            this.loading = false
            
            this.UsersPaginate = []
            UsersResponse.data.data.map(user => { 
                this.UsersPaginate = [
                    ...this.UsersPaginate, 
                    user
                ] 
            }) 
        },    

        usersCreated(type){
            if(!type.id) return false

            type.selected = true
            this.UsersPaginate = this.$Util.quickSort([...this.UsersPaginate, type], 'name', 'text' )
            this.usersSelected(type)
        },

        removeUsers(type){
            this.Users.map((user, i) => {
                if(user.id === type.id){
                    if(user.saved)
                    this.UsersDetach = [...this.UsersDetach , user]
                    this.Users.splice(i,1)
                } 
            })
            this.UsersPaginate.map((user, i) => {
                if(user.id === type.id){
                    user.selected = false
                    user.saved = false
                } 

                return user
            })
        }, 
        
        usersSelected(user){
            this.user_temp = null

            if(!user.id) return false
            user.selected = true 
            
            
            this.Users = [...this.Users, user] 
            
            for(let i in this.UsersDetach){
                if( this.UsersDetach[i].id === user.id ){
                    this.UsersDetach.splice(i,1)
                    break
                }
            }
            
        },
        

        async sendUsers(){
            var promises = []
            this.UsersDetach.map(user => {
                promises = [...promises, 
                    this.sendUser(user, true) 
                ]
            })
            const responseDetach = await Promise.all(promises)
            this.UsersDetach = []

            promises = []
            this.Users.map(user => {
                if(!user.saved)
                promises = [...promises, 
                    this.sendUser(user) 
                ]
            });
            const response = await Promise.all(promises)
            this.$toast(this.$t('titles.users') + ' salvos', 'success')
            this.saving = false
        },
        

        async sendUser(user, detach = false){
            this.saving = true 
            let formData = {
                project_id: this.projectId,
                user_id: user.id,                
                data:{}
            }  
            if(!user.saved){
                const response = detach ? 
                await this.detachUserProject( formData ) :
                await this.attachUserProject( formData )  

                if(response){
                    user.saved = !detach 
                }else{
                    if(detach){
                        //user.saved = false
                        //this.usersSelected(user)
                    }else{
                        //user.saved = false
                        //this.removeUsers(user)
                    }
                }
            }
            this.saving = false
        },

        editUserCredencialsIsAuthorized(stateUser){ 
            return this.$featureAuthorizer.isAuthorized('users.edit.credencials', stateUser)
        },

        setAllSelect(set) {
            this.Users = []
            this.UsersPaginate.map(user => set ? this.usersSelected(user) : this.removeUsers(user))
        }
    },
    computed: {
        stateUser:{
            get(){
                return this.$store.state.auth.user
            }
        },
        getUsersComputed() {
        return this.UsersPaginate;
        }, 
    },

    watch:{
        Users:{
            handler(){
                this.$emit('update', this.Users )
            }
        }
    },
    
}