import documentsModel from '@/models/documents/documents';

const actions = { 
    getAllDocuments({}, params) {
        return documentsModel.getAllPaginate(params)
    },
    getDocuments({}, params) {
        return documentsModel.getPaginate(params)
    },
    getDocumentById({ commit, dispatch },id) {
        return documentsModel.getById(id)
    },
    createDocument({ commit, dispatch },data) {
        return documentsModel.create(data)
    },
    updateDocument({ commit, dispatch },params) {
        return documentsModel.update(params)
    },
    deleteDocument({ commit, dispatch },params) {
        return documentsModel.delete(params)
    },
}

export default actions;