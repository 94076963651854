const state = {
    projects: {
        id: null,
        parent_id: null,
        project_template_id: null,
        name: null,
        description: null,
        budget_estimative: null,
        balance: null,
        created_at: null,
        updated_at: null,
    }
}

export default state