<template>
    <div> 
        <span v-if="transaction.cancelled_at"  class="d-block badge badge-pill bg-soft-secondary font-size-10">Cancelado</span>
        <span v-else  >
            <span v-if="item.is_processed" class="d-block badge badge-pill bg-soft-success font-size-10">Processado</span>
            <span v-else-if="item.is_awaiting_processing" class="d-block badge badge-pill bg-soft-info font-size-10">Aguardando Processamento</span>
            <span
            v-else-if="item.is_pending_confirmation"
            class="d-block badge badge-pill font-size-10"
            :class="{
                'bg-soft-warning' : !item.is_overdue,
                'bg-soft-danger' : item.is_overdue,
            }"
            > Pendente Confirmação </span>
        </span>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props:{
        transaction: {},
    },
    data(){
        return {
            item:{
                is_processed: false,
                is_awaiting_processing: false,
                is_pending_confirmation: false,
                is_overdue: false,
            }
        }
    },
    methods:{
        ...mapActions("transactions", ["is_processed", "is_awaiting_processing", "is_pending_confirmation", "is_overdue"]),
        async init(){
            this.item.is_processed = await this.is_processed(this.transaction)
            this.item.is_awaiting_processing = await this.is_awaiting_processing(this.transaction) 
            this.item.is_pending_confirmation = await this.is_pending_confirmation(this.transaction) 
            this.item.is_overdue = await this.is_overdue(this.transaction)
        }
    },
    mounted(){
        this.init()
    },
    watch:{
        transaction:{
            handler(oldData, newData){
                this.init()
            }
        }
    }
}
</script>