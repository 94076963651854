
import PageHeader from "@/components/PageHeader/PageHeader";
import { mapState, mapActions } from "vuex";
import Card from "@/components/Projects/card/card.vue";
import Create from "@/components/Projects/create/create.vue";
//import { featureAuthorizer } from "../../../authguard/actions";

export default {
    components: {
        PageHeader,
        Card,
        Create
    },
    data() {
        return{
            title: 'Projetos', 
            Paginate: null,
            Subprojects: [],
            FavoritesProjects: [],
            headerItems: [
                {
                    text: 'Início',
                    href: '/'
                },
                {
                    text: 'Projetos',
                    active: true
                }
            ],

            PaginateQuery: {
                'with[]':['subprojects'],
                orderBy:'id',
                orderDirection: 'desc',
                'withCount[]':['documents', 'transactions'],
            },

            PaginateFilter: {
                name: null,
            },

            favoriting: false,

            /// featureAuthorizer

        }
    },
    methods:{
        ...mapActions("projects", ["getProjects", 'setFavorites', 'deleteFavorite']), 
        async get(){
            
            
            this.Paginate = null
            let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
            PaginateQuery['withScopes[]'] = 'notFavorite'
            PaginateQuery = this.setAdditionalsFilters(PaginateQuery)
            const response = await this.getProjects({query: PaginateQuery})
            this.Subprojects = []
            this.Paginate = response.data.data
            this.Paginate.map(project => {
                project.subprojects.map(subproject => {
                    this.Subprojects.push(subproject.id)
                })
            })
        }, 

        setAdditionalsFilters(PaginateQuery){

            if(this.PaginateFilter.name)
            PaginateQuery['where[name:%like%]'] = this.PaginateFilter.name
            
            if(this.PaginateFilter.document_type)
            PaginateQuery['where[document_type_id]'] = this.PaginateFilter.document_type

            if(this.PaginateFilter.documentable_type)
            PaginateQuery['where[documentable_type]'] = this.PaginateFilter.documentable_type
            
            return PaginateQuery
        }, 
        
        showProject(id){
            return this.Subprojects.indexOf(id) === -1 ? true : false
        },

        /* featureAuthorizer */

        createProjectIsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('projects.index.btn_create', stateUser)
        },
        editProjectIsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('projects.index.btn_edit', stateUser)
        },

        async getFavoritesProjects(){
            try{
                this.FavoritesProjects = []
                let query = JSON.parse(JSON.stringify(this.PaginateQuery))
                query['withScopes[]'] = 'favorite'
                const response = await this.getProjects({query})
                if(response)
                this.FavoritesProjects = response.data.data
            } catch(err){
            }
        },
        
        async setFavoriteProject(project){
            if(this.favoriting) return
            this.favoriting = true
            const response = await this.setFavorites(project.id)
            this.favoriting = false
            if(response)
            this.init()
        },
        
        async deleteFavoriteProject(project){
            if(this.favoriting) return
            this.favoriting = true
            const response = await this.deleteFavorite(project.id)
            this.favoriting = false
            if(response)
            this.init()
        },
        init(){
            this.get()
            this.getFavoritesProjects()
        }
    },
    created() {
        this.init()
        document.title = "Projetos - " + this.$title;
    }, 
    computed:{
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        }
    }
}
