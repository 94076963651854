import templatesModel from '@/models/templates/templates';

const actions = { 
    getTemplates({ commit, dispatch }, params) {
        return templatesModel.getPaginate(params)
    }, 
    createTemplate({ commit, dispatch },data) {
        return templatesModel.create(data)
    },
    updateTemplate({ commit, dispatch },params) {
        return templatesModel.update(params)
    },
    deleteTemplate({ commit, dispatch },id) {
        return templatesModel.delete(id)
    }, 
     
}

export default actions;