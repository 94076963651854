import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes.map'
import { AuthGuard } from "./../authguard/actions"
import Auth from '../utils/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach(async (to, from, next) => { 
  if (!Auth.getAuthorizationToken() && to.path !== '/login' && to.matched[0]) {
    if(to.matched[0].path === '/login') return 
    window.location.assign('/login?redirect='+btoa(to.fullPath))
  } 
  
  if( to.path !== '/' && to.path !== '/login' && await AuthGuard.isUnauthorized(to.meta.authGroupScopes)){
    Vue.prototype.$toast('Sem permissões para acessar esta área!', 'error')
    router.push({path: '/'})
  }

  next() 
})

export default router
