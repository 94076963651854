<template>
  <div class="">
    <div class="text-right">
      <button
        v-if="!inTable"
        v-b-modal="`modal-create-${token}`"
        class="btn btn-primary"
      >
        Nova Exportação
      </button>
      <b-button
        v-else
        v-b-modal="`modal-create-${token}`"
        variant="link"
        class="text-info"
        ><i class="uil-pen"></i
      ></b-button>
    </div>

    <b-modal
      :ref="`modal-create-${token}`"
      :id="`modal-create-${token}`"
      hide-footer
      centered
      size="lg"
    >
      <b-tabs
        v-model="tabCurrent"
        content-class="mt-3"
        class="nav-tabs-custom remove-nav"
        active-nav-item-class="bg-transparent"
      >
        <b-tab :title="$t('titles.projects')" :active="!inTable">
          <b-form-group class="mb-2" id="input-group-2">
            <label for="" class="p-0 mb-0"><h4>Projetos</h4> </label>
            <div class="min-height-modal row">
              <div class="col-md-8">
                <projects-user
                  @update="updateProjectsUser"
                  :paginateQuery="{
                    'with[]': ['users', 'users.group', 'transaction_types'],
                  }"
                  placeholder="Selecionar projetos para exportação"
                />
              </div>
              <div class="col">
                <p>Selecione os projetos inclusos nesse exportação.</p>
                <p>
                  Todas as transações e documentos ficarão disponíveis para
                  download dentro do período de 30 dias.
                </p>
              </div>
            </div>
          </b-form-group>

          <div class="mt-3 text-end">
            <b-button
              @click="tabCurrent = 1"
              variant="primary"
              :disabled="creating || ProjectsUser.length === 0"
              type="button"
            >
              Avançar
            </b-button>
          </div>
        </b-tab>

        <b-tab :title="$t('titles.users')">
          <b-form-group class="mb-2" id="input-group-2">
            <label for="" class="p-0 mb-0"><h4>Usuários</h4> </label>
            <div class="min-height-modal row">
              <div class="col-md-8">
                <users-project
                  v-if="EnabledUsers && tabCurrent === 1"
                  @update="updateUsers"
                  placeholder="Selecione quem pode ver esta exportação"
                  emptyUsersPlaceholder="Não há usuários selecionados"
                  :EnabledUsers="EnabledUsers"
                  :UsersSelected="UsersSelected"
                />
                <h4 v-else class="">Selecione no mínimo um projeto.</h4>
              </div>
              <div class="col">
                <p>
                  Selecione os usuários que poderão ter acesso a esta
                  exportação.
                </p>
                <p>
                  Apenas usuários vinculados a todos os projetos selecionados
                  aparecerão na listagem.
                </p>
              </div>
            </div>
          </b-form-group>

          <div class="mt-3 text-end">
            <button
              v-if="!creating"
              type="button"
              class="btn"
              @click="tabCurrent = 0"
            >
              voltar
            </button>
            <b-button
              @click="tabCurrent = 2"
              variant="primary"
              :disabled="creating"
              type="button"
            >
              Avançar
            </b-button>
          </div>
        </b-tab>

        <b-tab :title="`Datas e ${$t('titles.transaction_types')}`">
          <label for="" class="p-0 mb-0"
            ><h4>{{ $t("titles.transaction_types") }}</h4>
          </label>
          <div class="min-height-modal row">
            <div class="col-md-8">
              <div>
                <b-form-group
                  class="mb-3"
                  :label="`Transações entre as datas:`"
                >
                  <date-picker
                    v-model="dataRange"
                    :id="`date-filter-item`"
                    type="date"
                    :range="true"
                    format="DD/MM/YYYY"
                    :clearable="false"
                  />
                </b-form-group>
              </div>
              <b-form-group
                class="mb-2"
                id="input-group-2"
                :label="$t('titles.transaction_types')"
              >
                <transaction-types-project
                  v-if="EnabledTransactionTypes && tabCurrent === 2"
                  @update="updateTransactionTypes"
                  :parentUse="true"
                  :EnabledTransactionTypes="EnabledTransactionTypes"
                  :TransactionTypesSelected="TransactionTypesSelected"
                  placeholder="Selecione quais rúbricas deseja exportar"
                />
                <h4 v-else class="">Selecione no mínimo um projeto.</h4>
              </b-form-group>
            </div>
            <div class="col">
              <p>Selecione as Rúbricas que devem constar na exportação.</p>
              <p>
                Apenas as Rúbricas vinculadas aos projetos selecionados
                aparecerão na listagem.
              </p>
            </div>
          </div>

          <div class="mt-3 text-end">
            <button
              :disabled="creating"
              type="button"
              class="btn"
              @click="tabCurrent = 1"
            >
              voltar
            </button>
            <b-button
              variant="primary"
              @click="sendDocumentExport"
              :disabled="creating"
            >
              <b-spinner small label="Criando" v-if="creating"></b-spinner>
              Criar Exportação
            </b-button>
          </div>
        </b-tab>

        <b-tab :title="'Editar Exportação'" :active="inTable">
          <div v-if="documentExport" class="row">
            <div class="col-6 text-muted align-items-center mb-2 d-flex">
              <b class="mr-2">Gerado por:</b>
              <div class="">
                <user-card :user="documentExport.created_by" />
              </div>
            </div>
            <p class="col-6 text-muted mb-2 d-flex">
              <b class="mr-2">Projeto(s): </b>
              <small class="text-truncate text-truncate-line-3">
                <small
                  v-for="(project, index) in documentExport.projects"
                  :key="`project-${project.id}`"
                  >{{ index > 0 ? ";" : "" }} {{ project.name }}</small
                >
              </small>
            </p>
            <p class="col-6 text-muted mb-2">
              <b class="mr-2">Criado em: </b>
              {{ $Util.formatDate(documentExport.created_at) }}
            </p>
          </div>

          <hr />

          <b-form-group class="mb-2" id="input-group-2">
            <div class="min-height-modal row">
              <div class="col-md-8">
                <div class="mb-3">
                  <label for="" class="p-0 mb-0"
                    ><h4>Data de expiração</h4>
                  </label>
                  <date-picker
                    :id="`date-expiration_at`"
                    type="date"
                    v-model="expiration_at"
                    format="DD/MM/YYYY"
                    :clearable="false"
                  ></date-picker>
                </div>

                <label for="" class="p-0 mb-0"><h4>Usuários</h4> </label>
                <users-project
                  v-if="EnabledUsers && tabCurrent === 3"
                  @update="updateUsers"
                  placeholder="Selecione quem pode ver esta exportação"
                  emptyUsersPlaceholder="Não há usuários selecionados"
                  :EnabledUsers="EnabledUsers"
                  :UsersSelected="UsersSelected"
                />
                <h4 v-else class="">Selecione no mínimo um projeto.</h4>
              </div>
              <div class="col">
                <p>
                  Altera a data que essa exportação deve ser ocultada do
                  sistema. Selecione apenas datas apartir de hoje.
                </p>
                <p>
                  Selecione os usuários que poderão ter acesso a esta
                  exportação.
                </p>
                <p>
                  Apenas usuários vinculados a todos os projetos selecionados
                  aparecerão na listagem.
                </p>
              </div>
            </div>
          </b-form-group>

          <div class="mt-3 text-end">
            <b-button
              @click="saveDocumentExport()"
              variant="primary"
              :disabled="creating"
              type="button"
            >
              salvar
            </b-button>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  maxLength,
} from "vuelidate/lib/validators";
import FormatFormData from "@/utils/api/users/format-form-data.js";
import { mapActions } from "vuex";
import { ADMIN, OPERATOR } from "../../../authguard/groups.map";
import DatePicker from "vue2-datepicker";
import { uuid } from "vue-uuid";

export default {
  mixins: [validationMixin],
  props: {
    groups: {
      default: null,
    },
    documentExport: null,
    inTable: {
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      token: uuid.v4(),

      formData: {
        username: null,
        name: null,
        cpf: null,
        email: null,
        password: null,
        group_id: null,
      },
      ADMIN,
      apiFormErrors: {},
      creating: false,
      openForm: false,
      tabCurrent: 0,

      ProjectsUser: [],
      UsersSelected: [],
      TransactionTypesSelected: [],

      expiration_at: null,

      EnabledUsers: null,
      EnabledTransactionTypes: null,
      dataRange: [
        this.$moment().subtract(3, "months").toDate(),
        this.$moment().toDate(),
      ],
    };
  },
  validations: {
    formData: {
      username: {
        required,
        minLength: minLength(3),
      },
      name: {
        required,
        minLength: minLength(6),
      },
      group_id: {
        required,
        minLength: minLength(1),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  mounted() {
    if (this.documentExport) {
      this.updateProjectsUser(this.documentExport.projects);
      this.updateUsers(this.documentExport.users);
      this.updateTransactionTypes(this.documentExport.transaction_types);
      this.expiration_at = this.$moment(
        this.documentExport.expiration_at
      ).toDate();
    }
  },
  methods: {
    ...mapActions("documentExports", [
      "createDocumentExport",
      "updateDocumentExport",
    ]),

    updateProjectsUser(data) {
      this.ProjectsUser = data;
    },

    updateUsers(data) {
      this.UsersSelected = data;
    },

    updateTransactionTypes(data) {
      this.TransactionTypesSelected = data;
    },

    formatDataForSend() {
      let data = {
        filters: {
          transactions: {
            start_date: this.$moment(this.dataRange[0]).format("YYYY-MM-DD"),
            end_date: this.$moment(this.dataRange[1]).format("YYYY-MM-DD"),
          },
        },
      };

      let projects = { _ids: [] };
      this.ProjectsUser.map((project) => projects._ids.push(project.id));
      if (projects._ids.length !== 0) data = { ...data, projects };

      let users = { _ids: [] };
      this.UsersSelected.map((user) => {
        [ADMIN, OPERATOR].indexOf(user.group_id) === -1 &&
          users._ids.push(user.id);
      });

      data = { ...data, users };

      let transaction_types = { _ids: [] };
      this.TransactionTypesSelected.map((transaction_type) =>
        transaction_types._ids.push(transaction_type.id)
      );
      if (transaction_types._ids.length !== 0)
        data = { ...data, transaction_types };

      return data;
    },

    async sendDocumentExport() {
      this.creating = true;
      try {
        const response = await this.createDocumentExport({
          data: this.formatDataForSend(),
        });
        const result = response.data;
        this.$toast("Exportação Criada!");
        this.$refs[`modal-create-${this.token}`].hide();
        this.$emit("create", true);
      } catch (err) {}

      this.creating = false;
    },

    async saveDocumentExport() {
      this.creating = true;
      try {
        const response = await this.updateDocumentExport({
          id: this.documentExport.id,
          data: {
            ...this.formatDataForSend(),
            expiration_at: this.$moment(this.expiration_at).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            filters: null,
          },
        });
        const result = response.data;
        this.$toast("Exportação Alterada!");
        this.$refs[`modal-create-${this.token}`].hide();
        this.$emit("update", true);
      } catch (err) {
        console.error("err", err);
      }

      this.creating = false;
    },

    resetModal() {
      this.tabCurrent = 0;
      this.ProjectsUser = [];
    },

    findObjectsWithSameId(...arrays) {
      let objectsWithSameId = [];

      if (arrays.length === 1) return arrays[0];

      arrays.forEach((array, index) => {
        array.forEach((object) => {
          for (let i = index + 1; i < arrays.length; i++) {
            let otherObject = arrays[i].find((o) => o.id === object.id);
            if (otherObject) {
              objectsWithSameId.push(object);
              //objectsWithSameId.push(otherObject);
            }
          }
        });
      });

      return objectsWithSameId;
    },
  },
  watch: {
    ProjectsUser: {
      handler() {
        let users = [];
        let userIds = [];
        let projects = [];

        let TransactionTypes = [];
        let TransactionTypesIds = [];

        this.EnabledUsers = null;
        this.EnabledTransactionTypes = null;

        if (!this.documentExport) {
          this.UsersSelected = null;
          this.TransactionTypesSelected = null;
        }

        this.ProjectsUser.map((project, index) => {
          projects[index] = [];
          project.users.map((user) => {
            users.push(user);
            if ([ADMIN, OPERATOR].indexOf(user.group_id) === -1) {
              user.selected = false;
              projects[index].push(user);
            }
          });

          project.transaction_types.map((transaction_type) => {
            if (TransactionTypesIds.indexOf(transaction_type.id) == -1) {
              TransactionTypesIds.push(transaction_type.id);
              transaction_type.selected = false;
              TransactionTypes.push(transaction_type);
            }
          });
        });

        this.EnabledUsers = this.findObjectsWithSameId(...projects);
        this.EnabledTransactionTypes = TransactionTypes;
      },
    },
  },
};
</script>
<style lang="scss">
.nav-tabs-custom.remove-nav {
  .nav.nav-tabs {
    display: none;
  }
}
.min-height-modal {
  min-height: 300px;
}
</style>
