import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
    components:{
        draggable,
    },
    props:{
        paginateQuery: {
            default: {},
            type: Object,
        },
        userProps: null,
        placeholder: {
            default: `Selecionar projetos deste usuário`,
            type: String,
        } 

    },
    data(){
        return {
            ProjectsPaginate: null,
            Projects: null,
            ProjectsDetach: [],
            project_temp: null,
            saving: false,
            loading: false,
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        ...mapActions("projects", ["getProjects", "getUsersByProjectId",'attachUserProject','detachUserProject']),
        ...mapActions("users", ["getUsers", "getUserById"]),
        async get(){
            await this.paginateProjects()
            if(this.userProps)
            await this.getUsersProject()
        },

         async getUsersProject(){
            //this.loading = true
            //const response = await this.getUserById({ id: this.projectId })
            //this.loading = false
            this.Projects = []
            if(this.userProps)
            this.Projects = this.userProps.projects

            return 

            if(response){
                let projects       =   response.data.data
                let projectsPag    =   this.ProjectsPaginate
                for(let i in projects) {
                    for(let ii in projectsPag) { 
                        if(projectsPag[ii].id === projects[i].id ) {
                            projectsPag[ii].saved = true
                            this.projectsSelected(projectsPag[ii])
                        }
                    }
                }
            }
        },

        async paginateProjects(){
            this.loading = true
            const ProjectsResponse = await this.getProjects({ query: {
                ...this.paginateQuery, 
            } })
            this.loading = false
            
            this.ProjectsPaginate = []
            ProjectsResponse.data.data.map(project => { 
                this.ProjectsPaginate = [
                    ...this.ProjectsPaginate, 
                    project
                ] 
            }) 
        },    

        removeProjects(type){
            this.Projects.map((project, i) => {
                if(project.id === type.id){
                    if(project.saved)
                    this.ProjectsDetach = [...this.ProjectsDetach , project]
                    this.Projects.splice(i,1)
                } 
            })
            this.ProjectsPaginate.map((project, i) => {
                if(project.id === type.id){
                    project.selected = false
                    project.saved = false
                } 

                return project
            })
        }, 
        
        projectsSelected(project) {
            this.project_temp = null

            if(!project.id) return false
            project.selected = true 

            if(!this.Projects)
            this.Projects = []

            this.Projects = [...this.Projects, project] 
            
           // console.log('this.Projects', this.Projects);
            
            for(let i in this.ProjectsDetach){
                if( this.ProjectsDetach[i].id === project.id ){
                    this.ProjectsDetach.splice(i,1)
                    break
                }
            }
            
        },
        

        async sendProjects(){
            var promises = []
            this.ProjectsDetach.map(project => {
                promises = [...promises, 
                    this.sendProject(project, true) 
                ]
            })
            const responseDetach = await Promise.all(promises)
            this.ProjectsDetach = []

            promises = []
            this.Projects.map(project => {
                if(!project.saved)
                promises = [...promises, 
                    this.sendProject(project) 
                ]
            });
            const response = await Promise.all(promises)
            this.$toast(this.$t('titles.projects') + ' salvos', 'success')
            this.saving = false
        },
        

        async sendProject(project, detach = false){
            this.saving = true 
            let formData = {
                project_id: project.id,
                user_id: this.userProps.id,                
                data:{}
            }  
            if(!project.saved){
                const response = detach ? 
                await this.detachUserProject( formData ) :
                await this.attachUserProject( formData ) 

                if(response){
                    project.saved = !detach 
                }else{
                    if(detach){
                        //user.saved = false
                        //this.projectsSelected(user)
                    }else{
                        //user.saved = false
                        //this.removeUsers(user)
                    }
                }
            }
            this.saving = false
        },

        editUserCredencialsIsAuthorized(stateUser){ 
            return this.$featureAuthorizer.isAuthorized('users.edit.credencials', stateUser)
        },
    },
    watch:{
        Projects:{
            handler(){
                this.$emit('update', this.Projects )
            }
        }
    },
    computed: {
        stateUser:{
            get(){
                return this.$store.state.auth.user
            }
        },
        getUsersComputed() {
        return this.ProjectsPaginate;
        }, 
    },
    
}