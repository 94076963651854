<script>
export default {
    props:{ 
        project: null, 
        favoriting: {
          requeired: true,
          default: false
        }
    },
    data() {
        return {}
    },
    methods:{ 
      createProjectIsAuthorized(stateUser){
          return this.$featureAuthorizer.isAuthorized('projects.index.btn_create', stateUser)
      },
      editProjectIsAuthorized(stateUser){
          return this.$featureAuthorizer.isAuthorized('projects.index.btn_edit', stateUser)
      },
      reportProjectIsAuthorized(stateUser){
          return this.$featureAuthorizer.isAuthorized('projects.index.btn_reports', stateUser)
      },
      dashboardProjectIsAuthorized(stateUser){
          return this.$featureAuthorizer.isAuthorized('projects.index.btn_dashboard', stateUser)
      },
    },
    computed:{
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        }
    }
}
</script>

<template>
    <b-card>
        <div class="row align-items-center">
            <div class="col-md-6">
                <small class="small">Nome do <span v-html="project.parent_id ? $t('titles.subproject') : $t('titles.project')"></span>:</small>
                <h3 class="mb-0">
                    {{project.name}} 
                    <button href="javascript:;" :disabled="favoriting" @click="$parent.setFavoriteProject(project)" class="btn btn-link favorite-project"><i title="Fixar Projeto no topo" v-b-tooltip class="fas fa-thumbtack"></i></button>
                    <button href="javascript:;" :disabled="favoriting" @click="$parent.deleteFavoriteProject(project)" class="btn btn-link favorite-project unset"><i title="Desfixar Projeto" v-b-tooltip class="fas fa-thumbtack "></i></button>
                  </h3>
                <small class="small">
                  <b-button class="p-0" variant="link" size="sm" v-b-toggle="'subprojects-'+project.id" v-if="!project.parent_id" >{{$t('titles.subprojects')}}</b-button>
                </small>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <div><router-link v-if="dashboardProjectIsAuthorized(stateUser)"  class="mx-2 btn btn-outline-secondary btn-sm" :to="`/dashboard/${project.id}`" >Dashboard</router-link></div>
                <div><router-link v-if="reportProjectIsAuthorized(stateUser)" class="mx-2 btn btn-outline-secondary btn-sm" :to="`/projects/reports/${project.id}/${project.name}`" >Relatórios</router-link></div>
                <div><router-link class="mx-2 btn btn-outline-secondary btn-sm" :to="`/projects/transactions/${project.id}/${project.name}`"> {{$t('titles.transactions')}} </router-link></div>
                <div><router-link class="mx-2 btn btn-outline-secondary btn-sm"  :to="`/projects/edit/${project.id}/${project.name}#documents`" v-if="editProjectIsAuthorized(stateUser)" >{{$t('titles.documents')}} </router-link></div>
                <b-dropdown
                  class="d-inline-block"
                  toggle-class=""
                  right
                  variant="white"
                  menu-class="dropdown-menu-end"
                >
                  <template v-slot:button-content class="btn btn-sm" >
                     <i class="fas fa-ellipsis-h"></i>
                  </template> 

                  <router-link v-if="editProjectIsAuthorized(stateUser)" :to="`/projects/edit/${project.id}/${project.name}`" class="dropdown-item">
                    <div><i class="uil-edit-alt"></i> <span class=" pl-2"> Editar</span></div>
                  </router-link>
                  <router-link v-else :to="`/projects/view/${project.id}/${project.name}`" class="dropdown-item">
                    <div ><i class="uil-folder-check"></i> <span class="pl-2"> Visualizar</span></div>
                  </router-link>

                  <router-link v-if="reportProjectIsAuthorized(stateUser)" :to="`/projects/reports/${project.id}/${project.name}`" class="dropdown-item">
                    <i class="uil-chart-pie"></i> 
                    <span class=" pl-2"> Relatórios</span>
                  </router-link>
                  
                  <router-link :to="`/projects/transactions/${project.id}/${project.name}`" class="dropdown-item">
                    <i class="uil-dollar-sign"></i> 
                    <span class=" pl-2"> {{$t('titles.transactions')}}</span>
                  </router-link>
                  
                  <router-link v-if="editProjectIsAuthorized(stateUser)" :to="`/projects/edit/${project.id}/${project.name}#transaction-types`" class="dropdown-item">
                    <i class="uil-file-copy-alt"></i> 
                    <span class=" pl-2"> {{$t('titles.transaction_types')}}</span>
                  </router-link> 

                  <router-link v-if="editProjectIsAuthorized(stateUser)" :to="`/projects/edit/${project.id}/${project.name}#documents`" class="dropdown-item">
                    <i class="uil-file-plus-alt"></i> 
                    <span class=" pl-2"> {{$t('titles.documents')}}</span>
                  </router-link> 
                  
                  <router-link v-if="editProjectIsAuthorized(stateUser)" :to="`/projects/edit/${project.id}/${project.name}#users`" class="dropdown-item">
                    <i class="uil-folder-lock"></i> 
                    <span class=" pl-2"> Permissões </span>
                  </router-link>

                  <!-- <a href="javascript:;" @click="$emit('del',{item: item, index: i})" class="dropdown-item">
                    <i class="uil-trash-alt"></i> 
                    <span class=" pl-2"> Excluir</span>
                  </a> -->
  
                </b-dropdown>
            </div>
        </div>
        <b-collapse :id="'subprojects-'+project.id" v-if="!project.parent_id" class="paginate-subprojects" >
            <slot></slot>
        </b-collapse>
        <!-- <pre>{{project}}</pre> -->
    </b-card>
</template>
<style lang="scss">
.favorite-project{
  position: relative;
  display: inline-block;
  padding: 5px !important;
  font-size: 20px !important;
  color: #ccc !important;
  cursor: pointer !important;
  i{
    transform: rotateZ(45deg)
  }
  &:hover{
    color: #222 !important;
  }
  &.unset::before,
  &.unset::after{
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -1px;
    width: 100%;
    height: 4px;
    background: #fff;
    transform: rotateZ(45deg) translateY(-50%);
    transform-origin: center center;
    z-index: 1;
  }
   &.unset::before{
    background: currentColor;
     z-index: 2;
    left: -2px;
    top: 51%;
      height: 2px;
   }
}
</style>