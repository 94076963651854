import documentTypesModel from '@/models/documentTypes/documentTypes';

const actions = { 
    getDocumentTypes({ commit, dispatch },params) {
        return documentTypesModel.getPaginate(params)
    },
    getDocumentTypeById({ commit, dispatch },id) {
        return documentTypesModel.getById(id)
    },
    createDocumentType({ commit, dispatch },data) {
        return documentTypesModel.create(data)
    },
    updateDocumentType({ commit, dispatch },params) {
        return documentTypesModel.update(params)
    },
    deleteDocumentType({ commit, dispatch },id) {
        return documentTypesModel.delete(id)
    },
}

export default actions;