
import Vue from 'vue';
import transfersModel from '@/models/transfers/transfers';

const actions = { 
    getTransfers({}, params) {
        return transfersModel.getPaginate( params)
    },
    getTransferById({ commit, dispatch },id) {
        return transfersModel.getById(id)
    },
    createTransfer({ commit, dispatch },data) {
        return transfersModel.create(data)
    },
    updateTransfer({ commit, dispatch },params) {
        return transfersModel.update(params)
    },
    confirmTransfer({ commit, dispatch },params) {
        return transfersModel.confirm(params)
    },
    cancelTransfer({ commit, dispatch },params) {
        return transfersModel.cancel(params)
    },
    deleteTransfer({ commit, dispatch },params) {
        return transfersModel.delete(params)
    },
}

export default actions;