import transactionTypesModel from '@/models/transactionTypes/transactionTypes';

const actions = { 
    getTransactionTypes({ commit, dispatch },params) {
        return transactionTypesModel.getPaginate(params)
    },
    getTransactionTypesByProjectId({}, id) {
        return transactionTypesModel.getPaginateByProjectId(id)
    },
    getTransactionTypeById({ commit, dispatch },id) {
        return transactionTypesModel.getById(id)
    },
    createTransactionType({ commit, dispatch },data) {
        return transactionTypesModel.create(data)
    },
    updateTransactionType({ commit, dispatch },params) {
        return transactionTypesModel.update(params)
    },
    syncTransactionTypes({ commit, dispatch },params) {
        return transactionTypesModel.sync(params)
    },
    deleteTransactionType({ commit, dispatch },id) {
        return transactionTypesModel.delete(id)
    },
}

export default actions;