import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    projectId: {
      default: null,
    },
    placeholder: {
      default: "Selecionar quais rúbricas estão neste projeto",
    },
    emptyItemsPlaceholder: {
      default: "Não há Rúbricas adicionadas neste projeto. ",
    },
    EnabledTransactionTypes: {
      default: null,
    },
    TransactionTypesSelected: null,
    parentUse: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      TransactionTypesPaginate: null,
      TransactionTypes: null,
      transaction_type_temp: null,
      saving: false,
      loading: false,
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions("projects", ["getTransactionTypesByProjectId"]),
    ...mapActions("transactionTypes", [
      "getTransactionTypes",
      "syncTransactionTypes",
    ]),
    async get() {
      await this.paginateTransactionTypes();
      await this.getTransactionTypesProject();
    },

    async getTransactionTypesProject() {
      this.TransactionTypes = [];

      if (this.EnabledTransactionTypes !== null) {
        if (this.TransactionTypesSelected !== null)
          this.TransactionTypes = this.TransactionTypesSelected;
        return;
      }

      this.loading = true;
      const response = await this.getTransactionTypesByProjectId(
        this.projectId
      );
      this.loading = false;

      if (response) {
        let types = response.data.data;
        let typesPag = this.TransactionTypesPaginate;
        for (let i in types) {
          for (let ii in typesPag) {
            if (typesPag[ii].id === types[i].id) {
              this.transactionTypesSelected(typesPag[ii]);
            }
          }
        }
      }
    },

    async paginateTransactionTypes() {
      this.TransactionTypesPaginate = [];

      if (this.EnabledTransactionTypes !== null) {
        this.TransactionTypesPaginate = this.EnabledTransactionTypes;
        return;
      }

      this.loading = true;
      const TransactionTypesResponse = await this.getTransactionTypes({
        query: {
          "with[]": "subtypes",
          "whereDoesntHave[]": "parent",
          orderBy: "name",
          orderDirection: "asc",
          "where[is_systemic]": 0,
          limit: 500,
        },
      });
      this.loading = false;

      TransactionTypesResponse.data.data.map((type) => {
        this.TransactionTypesPaginate = [
          ...this.TransactionTypesPaginate,
          type,
        ];

        if (type.subtypes.length > 0) {
          type.subtypes = this.$Util.quickSort(type.subtypes, "name", "text");
          type.subtypes.map((subtype) => {
            subtype.subtype_disabled = true;
            subtype.name = `${type.name} > ${subtype.name}`;
            this.TransactionTypesPaginate = [
              ...this.TransactionTypesPaginate,
              subtype,
            ];
          });
        }
      });
    },

    transactionTypesCreated(type) {
      if (!type.id) return false;

      type.selected = true;
      this.TransactionTypesPaginate = this.$Util.quickSort(
        [...this.TransactionTypesPaginate, type],
        "name",
        "text"
      );
      this.transactionTypesSelected(type);
    },
    removeTransactionTypes(type) {
      this.TransactionTypes.map((transaction_type, i) => {
        if (transaction_type.id === type.id) {
          this.TransactionTypes.splice(i, 1);
        }
        if (transaction_type.subtypes)
          transaction_type.subtypes.map((subtype, subi) => {
            if (subtype.id === type.id) {
              this.TransactionTypes[i].subtypes.splice(subi, 1);
            }
          });
      });
      this.TransactionTypesPaginate.map((transaction_type, i) => {
        if (transaction_type.id === type.id) {
          transaction_type.selected = false;
        }
        if (type.subtypes)
          type.subtypes.map((subtype, subi) => {
            this.removeTransactionTypes(subtype);
          });

        return transaction_type;
      });
    },

    getTransactionTypeInPaginate(parent_id, types, get_index = false) {
      for (let i in types) {
        if (types[i].id === parent_id) {
          return get_index ? i : types[i];
        }
      }
      return null;
    },

    hasTransactionTypesParent(type) {
      if (!type.parent_id) return false;

      let index = this.getTransactionTypeInPaginate(
        type.parent_id,
        this.TransactionTypes,
        true
      );
      if (index) {
        if (!this.TransactionTypes[index].subtypes)
          this.TransactionTypes[index].subtypes = [];

        this.TransactionTypes[index].selected = true;
        this.TransactionTypes[index].subtypes = [
          ...this.TransactionTypes[index].subtypes,
          type,
        ];
        return true;
      }

      let parent_type = this.getTransactionTypeInPaginate(
        type.parent_id,
        this.TransactionTypesPaginate
      );

      if (!parent_type) return false;
      parent_type.selected = true;
      parent_type.subtypes = [type];
      this.TransactionTypes = [...this.TransactionTypes, parent_type];

      return true;
    },

    transactionTypesSelected(type) {
      this.transaction_type_temp = null;

      if (!type.id) return false;
      type.selected = true;
      type.budget_estimative = type.pivot?.budget_estimative ?? 0;
      type.subtypes = [];

      if (this.hasTransactionTypesParent(type)) {
        return false;
      }

      this.TransactionTypes = [...this.TransactionTypes, type];
    },

    async sendTransactionTypes() {
      this.saving = true;
      let formData = {
        id: this.projectId,
        data: { list: [] },
      };

      let positions = 1;

      this.TransactionTypes.map((type) => {
        formData.data.list = [
          ...formData.data.list,
          {
            id: type.id,
            position: positions++,
            budget_estimative: this.$Util.clearMoney(
              type.budget_estimative ?? 0
            ),
          },
        ];
        type.subtypes.map((subtype) => {
          formData.data.list = [
            ...formData.data.list,
            {
              id: subtype.id,
              position: positions++,
              budget_estimative: this.$Util.clearMoney(
                subtype.budget_estimative ?? 0
              ),
            },
          ];
        });
      });

      const response = await this.syncTransactionTypes(formData);
      this.saving = false;

      if (response) {
        //this.$emit('created', response.data)
        this.$toast(this.$t("titles.transaction_types") + " salvos", "success");
      }
    },

    createTemplateProjectIsAuthorized(stateUser) {
      return this.$featureAuthorizer.isAuthorized(
        "projects.edit.btn_create_template",
        stateUser
      );
    },
    editTransactionTypeInProjectIsAuthorized(stateUser) {
      return this.$featureAuthorizer.isAuthorized(
        "projects.edit.transaction_types",
        stateUser
      );
    },
    setAllSelect(set) {
      this.TransactionTypes = [];
      this.TransactionTypesPaginate.map((type) =>
        set
          ? this.transactionTypesSelected(type)
          : this.removeTransactionTypes(type)
      );
    },
  },

  watch: {
    TransactionTypes: {
      handler() {
        this.$emit("update", this.TransactionTypes);
      },
    },
  },

  computed: {
    stateUser: {
      get() {
        return this.$store.state.auth.user;
      },
    },
    getTransactionTypesComputed() {
      return this.TransactionTypesPaginate;
    },
  },
};
