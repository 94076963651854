<template>
    <div> 
        <b-modal 
        :ref="`transaction-modal-${token}`"   
        :id="`transaction-modal-${token}`" 
        hide-footer 
        centered  
        @hidden="RedirectTo"
        >
            <transaction-view 
            v-if="transaction"
            :transaction="transaction" 
            />
            <div v-else>
                <b-row>
                    <b-col><b-skeleton animation="fade" width="30%"></b-skeleton></b-col>
                    <b-col><b-skeleton animation="fade" width="100%"></b-skeleton></b-col>
                    <b-col class="d-flex justify-content-end"><b-skeleton animation="fade" width="60%"></b-skeleton></b-col>
                </b-row>
                <b-row class="py-3">
                    <b-col class="d-flex justify-content-center"><b-skeleton animation="fade" width="40%" height="40px"></b-skeleton></b-col>
                </b-row>
                <b-row class="pb-3">
                    <b-col class="d-flex justify-content-center"><b-skeleton animation="fade" width="70%"  ></b-skeleton></b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col><b-skeleton animation="fade" width="40%"></b-skeleton></b-col>
                    <b-col><b-skeleton animation="fade" width="80%"></b-skeleton></b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col><b-skeleton animation="fade" width="30%"></b-skeleton></b-col>
                    <b-col><b-skeleton animation="fade" width="60%"></b-skeleton></b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col><b-skeleton animation="fade" width="50%"></b-skeleton></b-col>
                    <b-col><b-skeleton animation="fade" width="40%"></b-skeleton></b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col><b-skeleton animation="fade" width="40%"></b-skeleton></b-col>
                    <b-col><b-skeleton animation="fade" width="70%"></b-skeleton></b-col>
                </b-row>
                <hr>
                <div class="py-5">
                    <div class="d-flex justify-content-center"><b-skeleton animation="fade" width="30%"  ></b-skeleton></div>
                    <div class="d-flex justify-content-center"><b-skeleton animation="fade" width="70%"  ></b-skeleton></div>
                </div>
                <div class="mb-5 pb-5">
                    <div class=""><b-skeleton animation="fade" width="30%"  ></b-skeleton></div>
                    <div class=""><b-skeleton animation="fade" width="70%"  ></b-skeleton></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { uuid } from "vue-uuid";
import { mapActions } from 'vuex';

export default {
    data(){
        return {
            token: uuid.v4(),
            transaction: null
        }
    },
    methods:{
        ...mapActions('transactions', ['getTransactionById']),
        async get(){
            this.$bvModal.show(`transaction-modal-${this.token}`)
            const response = await this.getTransactionById({project_id: this.$route.params.id, transaction_id: this.$route.params.transaction_id})
            if(response){
                this.transaction = response.data
            }else{
                this.$bvModal.hide(`transaction-modal-${this.token}`)
            }
        },
        RedirectTo(){
            this.$router.push({path: `/projects/transactions/${this.$route.params.id}`})
        }
    },
    mounted(){
        this.get()
    }
}
</script>