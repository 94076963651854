import Vue from "vue";
import { uuid } from "vue-uuid";



Vue.prototype.$Util = {
  getUuid() {
    return uuid.v4();
  },

  formatDate(date, format = 'Normal'){
    switch(format){
      case 'Normal':
        return `${Vue.prototype.$moment(date).format('DD/MM/YYYY')} às ${Vue.prototype.$moment(date).format('HH:mm')}`
      case 'Date':
        return `${Vue.prototype.$moment(date).format('DD/MM/YYYY')}`
      case 'Hours':
        return `${Vue.prototype.$moment(date).format('HH:mm')}`
      case 'Archive':
        return `${Vue.prototype.$moment(date).format('YYYY-MM-DD')}`
      default:
        return `${Vue.prototype.$moment(date).format('DD/MM/YYYY')} às ${Vue.prototype.$moment(date).format('HH:mm')}`

    }
  },

  clearMoney(money) {
    return Number(
      money
        .replace("R$", "")
        .replace(" ", "")
        .replace(/\./gi, "")
        .replace(",", "")
    );
  },

  leftPad(value, totalWidth, paddingChar) {
    var length = totalWidth - value.toString().length + 1;
    return Array(length).join(paddingChar || "0") + value;
  },

  formatTransactionsId(id) {
    return this.leftPad(id, 5);
  },

  brMoney(price) {
    if (price == "0") return "0,00";
    price = parseFloat(price / 100);
    let priceBr = price.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    return priceBr;
  },

  nameInicials(name) {
    if (typeof name !== "string") {
      return "";
    }
    let arr = name.split(" ");
    if (arr[0] !== null && arr[1] !== undefined && arr[1] !== null) {
      return `${arr[0].slice(0, 1)}${arr[1].slice(0, 1)}`;
    }
    return `${arr[0].slice(0, 2)}`;
  },

  splitLoopHashFields(obj, field_hash) {
    let fields = field_hash.split(".");
    fields.map((field) => {
      obj = obj[field];
    });
    return obj;
  },

  setOrdinationString(arr, field) {
    if (arr.length <= 1) {
      return arr;
    }

    let all_values = [];
    for (let i = 0; i < arr.length; i++) {
      all_values = [...all_values, this.splitLoopHashFields(arr[i], field)];
    }
    all_values = all_values.sort();

    arr.map((item) => {
      let field_val = (item.__ordination__ = all_values.indexOf(
        this.splitLoopHashFields(item, field)
      ));
      return item;
    });
    return arr;
  },

  quickSort(arr, field = null, value_type = "int") {
    if (arr.length <= 1) {
      return arr;
    }

    if (value_type === "text") {
      arr = this.setOrdinationString(arr, field);
      field = "__ordination__";
      value_type = "int";
    }

    let pivot = arr[0];

    let left = [];
    let center = [pivot];
    let right = [];

    for (let i = 1; i < arr.length; i++) {
      let val =
        field !== null ? this.splitLoopHashFields(arr[i], field) : arr[i];
      let ps_pivot =
        field !== null ? this.splitLoopHashFields(pivot, field) : pivot;

      if (value_type === "date") {
        val = new Date(val);
        val = val.getTime();
        ps_pivot = new Date(ps_pivot);
        ps_pivot = ps_pivot.getTime();
      }

      if (val < ps_pivot) {
        left.push(arr[i]);
      } else if (val > ps_pivot) {
        right.push(arr[i]);
      } else {
        center.push(arr[i]);
      }
    }
    return this.quickSort(left, field, value_type).concat(
      center,
      this.quickSort(right, field, value_type)
    );
  },
};
