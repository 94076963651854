import PageHeader from "@/components/PageHeader/PageHeader";
import { mapState, mapActions } from "vuex";
import { ADMIN } from "../../../authguard/groups.map";
import create from '../create/create.vue';

export default {
    components: {
        PageHeader,
        create
    },
    computed: {
        ...mapState({
            //table: (state) => state.users.table,
            //tableCurrentPage: state => state.users.table.current_page
        }),
    },
    data() {
        return {
            title: 'Usuários',
            //TODO
            api_domain: process.env.VUE_APP_API_HOST,
            cards: {
                total: null,
                active: null,
                inactive: null
            },
            Paginate: [],
            headerItems: [{
                    text: 'Início',
                    href: '/'
                },
                {
                    text: 'Usuários',
                    active: true
                }
            ],
            GroupsList: null,
            GroupsListToCreate: null,
            deletingUsers: {},

            PaginateQuery: {
                'with[]':['group'],
                orderBy: 'id',
                orderDirection: 'desc',
                page: 1,
                limit: 10,
            },

            PaginateFilter: {
                name: null,
                email: null,
                group_id: null,
            },

            loading: false,
        };
    },
    created() {
        document.title = 'Usuários - ' + this.$title
        this.get()
        this.getListGroups()
        this.getListGroupsToCreate()
    },
    methods: {
        ...mapActions("groups", ["getGroups"]),
        ...mapActions("users", ["getUsers", "deleteUser"]),
        
        async getListGroups() {
            const response = await this.getGroups({query:{}})
            this.GroupsList = await response ? response.data.data : null
        }, 
        async getListGroupsToCreate() {
            const response = await this.getGroups({query: {'withScopes[]': 'authUserCanCreate'}})
            this.GroupsListToCreate = await response ? response.data.data : null
        }, 
        
        async get(){
            this.Paginate = null
            this.PaginateQuery.page = 1
            this.getPaginateUsers()
        }, 
        
        getMore(){
            this.getPaginateUsers()
        }, 

        async getPaginateUsers(){
            let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
            
            PaginateQuery = this.setAdditionalsFilters(PaginateQuery)

            this.loading = true
            const response = await this.getUsers({query: PaginateQuery})
            this.loading = false

            if(!this.Paginate) this.Paginate = []
            response.data.data.map(item => {
                this.Paginate = [...this.Paginate, item]
            })
        },

        setAdditionalsFilters(PaginateQuery){

            if(this.PaginateFilter.name)
            PaginateQuery['where[name:%like%]'] = this.PaginateFilter.name

            if(this.PaginateFilter.email)
            PaginateQuery['where[email:%like%]'] = this.PaginateFilter.email
            
            if(this.PaginateFilter.group_id)
            PaginateQuery['where[group_id]'] = this.PaginateFilter.group_id 
            
            return PaginateQuery
        }, 
        insertUser(user){ 
            this.Paginate = [user, ...this.Paginate]
            //this.$toast("Novo usuário cadastrado!", 'success') 
        }, 

        async delUser(user, index){

            const result = await this.$swal({
                title: 'Excluir este usuário?',
                text: user.name,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: `Não`,
            }) 

            if (!result.isConfirmed) return false 
            
            this.deletingUsers[user.id] = true
            const response = await this.deleteUser(user.id)

                if(response){
                    this.Paginate.splice(index, 1)
                    this.$toast("Usuário excluido!", 'success') 
                }
            

        },

        createUserIsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('users.index.btn_create', stateUser)
        },
        editUserIsAuthorized(stateUser, user){ 
            return stateUser.id === user.created_by_user_id || stateUser.id === user.id || this.$featureAuthorizer.isAuthorized('users.edit.group_id.'+user.group_id, stateUser)
        },

        deleting(user){
            return this.deletingUsers[user.id]
        }
    },
    computed:{
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        }
    },
}