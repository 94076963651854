const axios = require("axios").default;
import loginModel from '@/models/login/login';
import { validateError } from '@/utils/errors';

const actions = {
    async sendTo({ commit, dispatch }, tableOptions) {
    axios.defaults.withCredentials = true;
    await axios.get(process.env.VUE_APP_API_HOST + '/sanctum/csrf-cookie').catch(err => validateError(err))
        return loginModel.sendLogin(tableOptions)
    },
    async sendEmailToPasswordReset({ commit, dispatch }, tableOptions) {
    axios.defaults.withCredentials = true;
    await axios.get(process.env.VUE_APP_API_HOST + '/sanctum/csrf-cookie').catch(err => validateError(err))
        return loginModel.emailToPasswordReset(tableOptions)
    },
    async sendPasswordReset({ commit, dispatch }, tableOptions) {
    axios.defaults.withCredentials = true;
    await axios.get(process.env.VUE_APP_API_HOST + '/sanctum/csrf-cookie').catch(err => validateError(err))
        return loginModel.passwordReset(tableOptions)
    },
}

export default actions;