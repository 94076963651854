const state = {
    user: {
        id: Number,
        group_id: Number,
        name: String,
        username: String,
        email: String,
        email_verified_at: Number,
        created_at: String,
        updated_at: String
    }
};

export default state;