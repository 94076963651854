import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';
import { mapActions } from 'vuex';

export default {
    components: {
        DatePicker,
    },
    data(){
        return {
            api_domain: process.env.VUE_APP_API_HOST,

            DocumentableType:[
                {id: null,                      name: 'Selecione'},
                {id:"App\\Models\\Transaction", name: this.$t('titles.transactions')},
                {id:"App\\Models\\Transfer",    name: this.$t('titles.transfer')},
                {id:"App\\Models\\Project",     name: this.$t('titles.projects')},
                {id:"App\\Models\\User",        name: this.$t('titles.users')},
            ],

            PaginateFilter:{
                name: null,
                dataRange: [null, null],
                document_type: null,
                documentable_type: null,
            },
            PaginateQuery:{
                orderBy: 'id',
                orderDirection: 'desc',
                'with[]': [
                    //'document_type',
                    'project',
                    'user',
                    'transaction',
                    'transfer',
                    'createdBy',
                    'createdBy.group',
                ],
                limit: 10,
                page: 1,
            },
            PaginateParams: null,
            Paginate: null,
            DocumentTypes: [],


            fields: [ 
                { key: 'name', label: 'Documento' }, 
                { key: 'document_type', label: this.$t('titles.document_types') }, 
                { key: 'documentable_type', label: 'Setor' }, 
                { key: 'createdBy', label: 'Criado por' }, 
                { key: 'created_at', label: 'Criado em' }, 
                { key: 'actions', label: 'Ação' }, 
                 
              ],
            items: null,
            saving: false,

        }
    },
    methods:{
        ...mapActions("documentTypes", ["getDocumentTypes"]), 
        ...mapActions('documents', ["getAllDocuments", "deleteDocument"]),

        async getDocumentsTypes(){
            try{
                const response = await this.getDocumentTypes({query:{
                    orderBy: 'name'
                }})
                this.DocumentTypes = [{id: null, name: 'Selecione'}, ...response.data.data]
            } catch(err) {}
        },

        async DocumentsPaginates(){
            let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
            
            PaginateQuery = this.setAdditionalsFilters(PaginateQuery)

            const response = await this.getAllDocuments({query: PaginateQuery})
            this.Paginate = response.data
            this.items = []
            this.Paginate.data.map(item => {
                let data = {
                    id: item.id,
                    name: item,
                    document_type: item.document_type,
                    documentable_type: item.documentable_type,
                    created_at: item.created_at,
                    document: item,
                    deleting: false,
                }
                this.items = [...this.items, data]
            })
            
        },



        closeConfirm(document){
            let index = `del-doc-confirm-${document.id}`
            try{
                this.$refs[index].doClose()
            } catch(err) {
            }
        },
        
        async delDocument(document, item){ 
            this.closeConfirm(document)
            

            item.deleting = true 
            const response = await this.deleteDocument({
                id: document.id, 
                endpoint: this.$t('endpoints.' + document.documentable_type), 
                documentable_id: document.documentable_id
            })
            item.deleting = false 
            
            if(!response) return false 

            this.$toast('Documento deletado!')
            this.removeItem(document)
        },

        removeItem(document){
            for(let i in this.items){
                if(this.items[i].id === document.id){
                    this.items.splice(i,1)
                    break 
                }
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.deleting) return 'table-danger'
        },

        getDataModel(document){
            switch(this.$t('endpoints.' + document.documentable_type)) {
                case 'transactions':
                    return document.transaction
                case 'projects':
                    return document.project
                case 'users':
                    return document.user 
            }
            return ''
        },
        
        setAdditionalsFilters(PaginateQuery){

            if(this.PaginateFilter.name)
            PaginateQuery['where[name:%like%]'] = this.PaginateFilter.name
            
            if(this.PaginateFilter.document_type)
            PaginateQuery['where[document_type_id]'] = this.PaginateFilter.document_type

            if(this.PaginateFilter.documentable_type)
            PaginateQuery['where[documentable_type]'] = this.PaginateFilter.documentable_type
            
            if(this.PaginateFilter.dataRange[0]){
                PaginateQuery['where[created_at:>=]'] = this.PaginateFilter.dataRange[0]
                PaginateQuery['where[created_at:<=]'] = this.PaginateFilter.dataRange[1]
            }
            
            return PaginateQuery
        },

        get(){
            this.items = null
            this.DocumentsPaginates()
        }
        

    },
    created(){
        document.title = "Configurações - Documentos";
    },
    mounted(){
        this.get()
        this.getDocumentsTypes()
    },
    watch:{
        'PaginateQuery.page':{
            handler(){
                this.get()
            }
        }
    }

}