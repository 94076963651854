import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/Auth'
import login from '@/store/modules/Login'
import users from '@/store/modules/Users'
import groups from '@/store/modules/Groups'
import projects from '@/store/modules/Projects'
import templates from '@/store/modules/Templates'
import documents from '@/store/modules/Documents'
import documentTypes from '@/store/modules/DocumentTypes' 
import transactions from '@/store/modules/Transactions'
import transactionTypes from '@/store/modules/TransactionTypes' 
import transfers from '@/store/modules/Transfers' 
import financialSummaries from '@/store/modules/FinancialSummaries' 
import documentExports from '@/store/modules/DocumentExports' 

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        login,
        users,
        auth,
        groups,
        projects,
        templates,
        documents,
        documentTypes,
        transactions,
        transactionTypes,
        transfers,
        financialSummaries,
        documentExports,
    }
})

export default store