import Vue from "vue";
import {
  ADMIN,
  MANAGER,
  OPERATOR,
  ACCOUNTANT,
} from "./../authguard/groups.map";

const routes = [
  {
    path: "/login",
    component: Vue.component(
      "Login",
      require("@/views/Login/login.vue").default
    ),
  },
  {
    path: "/forgot-password",
    component: Vue.component(
      "ForguetPassword",
      require("@/views/ForguetPassword/ForguetPassword.vue").default
    ),
  },
  {
    path: "/reset-password",
    component: Vue.component(
      "ForguetPassword",
      require("@/views/ForguetPassword/ForguetPassword.vue").default
    ),
  },
  {
    path: "/logout",
    component: Vue.component(
      "Logout",
      require("@/views/Logout/logout.vue").default
    ),
  },
  {
    path: "/",
    component: Vue.component(
      "Default",
      require("@/layouts/Default.vue").default
    ),
    children: [
      {
        path: "/",
        component: Vue.component(
          "Projects",
          require("@/views/Projects/index/index.vue").default
        ),
      },
      {
        path: "/document_exports",
        component: Vue.component(
          "DocumentExports",
          require("@/views/DocumentExports/index.vue").default
        ),
        meta: { authGroupScopes: { MANAGER, ACCOUNTANT } },
      },
      {
        path: "/dashboard/:project_id",
        component: Vue.component(
          "Starter",
          require("@/views/Starter/Starter.vue").default
        ),
        meta: { authGroupScopes: { ADMIN, MANAGER, ACCOUNTANT } },
      },
      {
        path: "/settings",
        component: Vue.component(
          "Settings",
          require("@/views/Settings/index/index.vue").default
        ),
        meta: { authGroupScopes: { ADMIN } },
      },
      {
        path: "/users",
        component: Vue.component(
          "Users",
          require("@/views/Users/index/index.vue").default
        ),
        meta: { authGroupScopes: { ADMIN, MANAGER } },
      },
      {
        path: "/users/current",
        component: Vue.component(
          "Users",
          require("@/views/Users/edit/edit.vue").default
        ),
      },
      {
        path: "/users/:id",
        component: Vue.component(
          "Users",
          require("@/views/Users/edit/edit.vue").default
        ),
        meta: { authGroupScopes: { ADMIN, MANAGER } },
      },
      {
        path: "/projects",
        component: Vue.component(
          "Projects",
          require("@/views/Projects/index/index.vue").default
        ),
      },
      {
        path: "/projects/edit/:id/:name",
        component: Vue.component(
          "Projects",
          require("@/views/Projects/edit/edit.vue").default
        ),
        meta: { authGroupScopes: { ADMIN, MANAGER } },
      },
      {
        path: "/projects/view/:id/:name",
        component: Vue.component(
          "Projects",
          require("@/views/Projects/view/view.vue").default
        ),
      },
      {
        path: "/projects/transactions/:id",
        component: Vue.component(
          "Transactions",
          require("@/views/Projects/transactions/transactions.vue").default
        ),
        children: [
          {
            path: "view/:transaction_id",
            component: Vue.component(
              "Transactions",
              require("@/views/Transactions/view/view.vue").default
            ),
          },
          {
            path: "transfer/view/:transfer_id",
            component: Vue.component(
              "Transfers",
              require("@/views/Transfers/view/view.vue").default
            ),
          },
        ],
      },
      {
        path: "/projects/:id/transaction_types/:transaction_type_id",
        component: Vue.component(
          "TransactionTypes",
          require("@/views/Projects/transaction_types/transaction_types.vue")
            .default
        ),
      },
      {
        path: "/projects/transactions/:id/:name",
        component: Vue.component(
          "Projects",
          require("@/views/Projects/transactions/transactions.vue").default
        ),
      },
      {
        path: "/projects/reports/:id/:name",
        component: Vue.component(
          "Projects",
          require("@/views/Projects/reports/reports.vue").default
        ),
        meta: { authGroupScopes: { ADMIN, MANAGER, ACCOUNTANT } },
      },
    ],
  },
];

export default routes;
