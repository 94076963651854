<template>
    <div> 
        <b-modal 
        :ref="`transfer-modal-${token}`"   
        :id="`transfer-modal-${token}`" 
        size="lg"
        hide-footer 
        centered  
        @hidden="RedirectTo"
        >
            <div v-if="transfer">
                <b-row>
                    <b-col md="6" v-for="transaction in transfer.transactions" :key="`transfer-transaction-${transaction.id}`">
                        <transaction-view
                        v-if="transaction"
                        :transaction="transaction"
                        />
                        <div v-else>
                            <b-row>
                                <b-col><b-skeleton animation="fade" width="30%"></b-skeleton></b-col>
                                <b-col><b-skeleton animation="fade" width="100%"></b-skeleton></b-col>
                                <b-col class="d-flex justify-content-end"><b-skeleton animation="fade" width="60%"></b-skeleton></b-col>
                            </b-row>
                            <b-row class="py-3">
                                <b-col class="d-flex justify-content-center"><b-skeleton animation="fade" width="40%" height="40px"></b-skeleton></b-col>
                            </b-row>
                            <b-row class="pb-3">
                                <b-col class="d-flex justify-content-center"><b-skeleton animation="fade" width="70%"  ></b-skeleton></b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col><b-skeleton animation="fade" width="40%"></b-skeleton></b-col>
                                <b-col><b-skeleton animation="fade" width="80%"></b-skeleton></b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col><b-skeleton animation="fade" width="30%"></b-skeleton></b-col>
                                <b-col><b-skeleton animation="fade" width="60%"></b-skeleton></b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col><b-skeleton animation="fade" width="50%"></b-skeleton></b-col>
                                <b-col><b-skeleton animation="fade" width="40%"></b-skeleton></b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col><b-skeleton animation="fade" width="40%"></b-skeleton></b-col>
                                <b-col><b-skeleton animation="fade" width="70%"></b-skeleton></b-col>
                            </b-row>
                            <hr>
                            <div class="py-5">
                                <div class="d-flex justify-content-center"><b-skeleton animation="fade" width="30%"  ></b-skeleton></div>
                                <div class="d-flex justify-content-center"><b-skeleton animation="fade" width="70%"  ></b-skeleton></div>
                            </div>
                            <div class="mb-5 pb-5">
                                <div class=""><b-skeleton animation="fade" width="30%"  ></b-skeleton></div>
                                <div class=""><b-skeleton animation="fade" width="70%"  ></b-skeleton></div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { uuid } from "vue-uuid";
import { mapActions } from 'vuex';

export default {
    data(){
        return {
            token: uuid.v4(),
            transfer: null
        }
    },
    methods:{
        ...mapActions('transfers', ['getTransferById']),
        async get(){
            this.$bvModal.show(`transfer-modal-${this.token}`)
            const response = await this.getTransferById({project_id: this.$route.params.id, transfer_id: this.$route.params.transfer_id})
            if(response){
                this.transfer = response.data
            }else{
                this.$bvModal.hide(`transfer-modal-${this.token}`)
            }
        },
        RedirectTo(){
            this.$router.push({path: `/projects/transactions/${this.$route.params.id}`})
        }
    },
    mounted(){
        this.get()
    }
}
</script>