<template>
    <span>
        <b-button :disabled="disabledButton" :variant="btnType" :class="btnClass" :size="btnSize" v-b-modal="`modal-${token}`" v-html="text"></b-button>
        <b-modal :ref="`modal-${token}`" @shown="focusInput()" :id="`modal-${token}`" hide-header hide-footer centered  >
            <div class="" >
            <h4>Criar Template</h4>

                <b-form ref="createTemplate" @submit.prevent="sendForm" method="post">
 

                        <div class=" py-2">
                            <b-form-group id="input-group-1" class="mb-0" label-for="name-1">
                                <label class="mb-0" for="name-1">Nome do Template</label>
                                <b-form-input
                                v-if="!modeView"
                                class="pb-0"
                                ref="templateName"
                                v-model.trim="$v.formData.name.$model"
                                type="text"
                                placeholder="Ex.: ..."
                                :class="{ 'is-invalid': $v.formData.name.$error}"
                                ></b-form-input>
                                <h5 v-else>{{template.name}}</h5>
                            </b-form-group>
                        </div> 
                        <div class="py-2">
                            <label class="mb-0" for="name-1">{{$t('titles.transaction_types')}}</label>
                            <b-list-group>
                                <div v-for="item in templateTypes" :key="`item${item.id}`">
                                    <b-list-group-item >{{item.name}}</b-list-group-item> 
                                    <b-list-group-item v-for="subitem in item.subtypes" :key="`subitem${subitem.id}`">{{subitem.name}}</b-list-group-item> 
                                </div>
                            </b-list-group> 
                        </div>

                        <div class="d-flex justify-content-end py-2" v-if="!modeView">
                            <b-form-group id="input-group-1" class="mb-0" label-for="name-1">
                                <b-button variant="link" @click="cancelForm" v-b-toggle="`create-template-type-${token}`" type="button" >Cancelar</b-button>
                                <b-button variant="primary" :disabled="creating" type="submit" ><b-spinner small label="salvando" v-if="creating"></b-spinner> Salvar</b-button>
                            </b-form-group>
                        </div>
                        
                </b-form>
            </div>
            
            
        </b-modal>
    </span>
</template>
<script>  
import { uuid } from 'vue-uuid';
import { required, minLength} from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
    props:{
        template: null,
        templateTypes: null,
        text: {
            default: `Salvar Template`,
            type: String,
        },
        btnType: {
            default: `outline-primary`,
            type: String,
        },
        btnClass: {
            default: ``,
            type: String,
        },
        btnSize: {
            default: `sm`,
            type: String,
        },
        disabledButton: {
            default: false,
            type: Boolean,
        },
        modeView: {
            default: false,
            type: Boolean,
        },
    },
    data(){
        return {
            token: uuid.v4(),
            formData: {
                name: null
            },
            creating: false,
        }
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(3)
            }, 
        }
    },
    methods:{
        ...mapActions("templates", ["createTemplate", "updateTemplate"]), 
        focusInput() {
             if(!this.template)
            this.$refs.templateName.focus() 
        },
        cancelForm(){
            this.formData.name = null
            this.$refs.createTemplate.reset()
            this.$v.formData.$reset();
            this.$refs[`modal-${this.token}`].hide()
        },  

        async sendForm(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError || this.creating) {
                return;
            } 

            let formData = {
                data: { 
                    name: this.formData.name,
                    transaction_types: {
                        _ids: []
                    }
                } 
            }
             this.templateTypes.map(type => {
                formData.data.transaction_types._ids = [...formData.data.transaction_types._ids, type.id]
                type.subtypes.map(subtype => {
                    formData.data.transaction_types._ids = [...formData.data.transaction_types._ids, subtype.id]
                })
            });

            this.creating = true
            try{
                if(this.template){
                    const response = await this.updateTemplate({id:this.template.id, data: formData})
                    if(response){
                        this.template.name = JSON.parse(JSON.stringify(this.formData.name))
                        this.$toast('Template salvo!')
                    }
                }else{
                    const response = await this.createTemplate(formData)
                    if(response)
                    this.$toast('Novo Template Criado!')
                }
                this.cancelForm()
            } catch(err){
            }
            this.creating = false
        },  
        
    },
    mounted(){
        
    }
}
</script>