import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';
import { mapActions } from 'vuex';
import TransactionTypesComponent from "./../TransactionTypes/TransactionTypesComponent.vue";

export default {
    components: {
        DatePicker,
        'transaction-types': TransactionTypesComponent
    },

    data(){
        return {
            api_domain: process.env.VUE_APP_API_HOST,

            TransactionableType:[
                {id: null,                      name: 'Selecione'},
                {id:"App\\Models\\Transaction", name: this.$t('titles.transactions')},
                {id:"App\\Models\\Transfer",    name: this.$t('titles.transfer')},
                {id:"App\\Models\\Project",     name: this.$t('titles.projects')},
                {id:"App\\Models\\User",        name: this.$t('titles.users')},
            ],

            PaginateFilter:{
                name: null,
                dataRange: [null, null],
            },
            PaginateQuery:{
                orderBy: 'id',
                orderDirection: 'desc',
                'where[is_systemic]': 0, 
                'with[]': [ 
                    'createdBy',
                    'createdBy.group',
                    'parent',
                ],
                
                limit: 10,
                page: 1,
            },
            PaginateParams: null,
            Paginate: null,
            TransactionTypes: [],


            fields: [ 
                { key: 'name', label: 'Nome' }, 
                { key: 'createdBy', label: 'Criado por' }, 
                { key: 'created_at', label: 'Criado em' }, 
                { key: 'updated_at', label: 'Atualizado em' }, 
                { key: 'actions', label: 'Ação' }, 
                 
              ],
            items: null,
            saving: false,

        }
    },
    methods:{
        ...mapActions("transactionTypes", ["getTransactionTypes", "deleteTransactionType"]), 

        async getTransactionsTypes(){
            try{
                const response = await this.getTransactionTypes({
                    query: {
                        'with[]': 'subtypes', 
                        'where[is_systemic]': 0, 
                        'whereDoesntHave[]': 'parent', 
                        orderBy: 'name', 
                        orderDirection: 'asc',
                    }
                })
                this.TransactionTypes = [{id: null, name: 'Selecione'}, ...response.data.data]
            } catch(err) {}
        },

        async TransactionsPaginates(){
            let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
            
            PaginateQuery = this.setAdditionalsFilters(PaginateQuery)

            const response = await this.getTransactionTypes({query: PaginateQuery})
            this.Paginate = response.data
            this.items = []
            this.Paginate.data.map(item => { 
                item.deleting = false
                this.items = [...this.items, item]
            })
            
        },

        createdTransactionType(data){
            this.get()
        },
        savedTransactionType(data){ 
            this.items.map(item => {
                if(item.id === data.id) item = data
                return item
            })
        },

        closeConfirm(item){
            let index = `del-doc-type-confirm-${item.id}`
            try{
                this.$refs[index].doClose()
            } catch(err) {
            }
        },
        
        async delTransaction(item){ 
            this.closeConfirm(item)
            

            item.deleting = true 
            const response = await this.deleteTransactionType(item.id)
            item.deleting = false 
            
            if(!response) return false 

            this.$toast(this.$t('titles.transaction_type')+' deletada!')
            this.removeItem(item)
        },

        removeItem(item){
            for(let i in this.items){
                if(this.items[i].id === item.id){
                    this.items.splice(i,1)
                    break 
                }
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.deleting) return 'table-danger'
        }, 
        
        setAdditionalsFilters(PaginateQuery){

            if(this.PaginateFilter.name)
            PaginateQuery['where[name:%like%]'] = this.PaginateFilter.name  

            if(this.PaginateFilter.dataRange[0]){
                PaginateQuery['where[created_at:>=]'] = this.PaginateFilter.dataRange[0]
                PaginateQuery['where[created_at:<=]'] = this.PaginateFilter.dataRange[1]
            }
            
            return PaginateQuery
        },

        get(){
            this.items = null
            this.TransactionsPaginates()
        }
        

    },
    mounted(){
        this.get()
        this.getTransactionsTypes()
    },
    watch:{
        'PaginateQuery.page':{
            handler(){
                this.get()
            }
        }
    }

}