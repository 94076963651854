import { mapActions } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import FormatFormData from '@/utils/api/documents/format-form-data.js';
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import DocumentTypesComponent from "./DocumentTypes/DocumentTypesComponent.vue";
import { uuid } from 'vue-uuid';

export default {
    name: "DocumentsComponent",
    components: {
        vueDropzone: vue2Dropzone,
        'document-types': DocumentTypesComponent
    },
    props:{
        documentableId: {
            required: true,
        },
        documentableType: {
            required: true,
        },
        endpoint: {
            required: true,
        },
        reset: null,

        saveParent: false,
        mobileTheme: false,
        dropzoneSize: {
            default: 'md'
        },
    },
    data() {
        return {
        msg: "Welcome to Your Vue.js App",
        api_domain: process.env.VUE_APP_API_HOST,
        DocumentsPaginate: null,
        DocumentTypesPaginate: null,
        tempAttachments: [],
        attachments: [],
        attachmentsList: {},
        saving: false,
        dropzoneOptions: {
            // The Url Where Dropped or Selected files will be sent
            url: `https://httpbin.org/post`,
            // File Size allowed in MB
            maxFilesize: 102400000,
            // Authentication Headers like Access_Token of your application
            headers: {
            Authorization: `Access Token`,
            },
            // The way you want to receive the files in the server
            paramName: function (n) {
            return "file[]";
            },
            dictDefaultMessage: "<i class='uil-cloud-upload'></i> Carregue seus arquivos aqui",
            includeStyling: false,
            previewsContainer: false,
            thumbnailWidth: 100,
            thumbnailHeight: 140,
            uploadMultiple: true,
            parallelUploads: 20,
            
        },
        loading: false,
        };
    },
    methods: {
        ...mapActions("documentTypes", ["getDocumentTypes"]), 
        ...mapActions("documents", ["getDocuments", 'createDocument', 'updateDocument', 'deleteDocument']), 
        async get(){
            
            this.loading = true
            const DocumentTypesResponse = await this.getDocumentTypes({query: {orderBy: 'name', orderDirection: 'asc'}})
            this.loading = false
            this.DocumentTypesPaginate = DocumentTypesResponse.data.data

            if(this.saveParent) return
            this.loading = true
            const DocumentsResponse = await this.getDocuments({
                endpoint:this.endpoint, 
                documentable_id: this.documentableId,
                query: {
                    orderBy: 'id',
                    orderDirection: 'asc',
                },
            })
            this.loading = false

            this.DocumentsPaginate = DocumentsResponse.data.data
            this.DocumentsPaginate.map(document => {  
            document._id = uuid.v4(); 
            document.extension = "." + document.name.split("/")[1];
            document.thumb = null; 
            document.size = document.size; 
            document.saved = true;
            document.saving = false;
            document.error = false;
            document.filename = document.file_name;
            this.tempAttachments = [document, ...this.tempAttachments]; 
            })
        }, 
        fileAdded(file) {
            let attachment = {};
            attachment.file = file;
            attachment._id = file.upload.uuid;
            attachment.name = file.name;
            attachment.filename = file.name;
            attachment.type = "file";
            attachment.extension = "." + file.type.split("/")[1];
            attachment.thumb = null; 
            attachment.size = file.size;
            attachment.documentable_id = this.documentableId;
            attachment.documentable_type = this.documentableType;
            attachment.document_type_id = null;
            attachment.saved = false;
            attachment.saving = false;
            attachment.error = false;
            this.tempAttachments = [attachment, ...this.tempAttachments]; 
        },

        setTumb(file, dataURL) {
          this.tempAttachments.map((att) => {
              if (att._id === file.upload.uuid) att.thumb = dataURL;
              return att;
          });
        }, 

        createdDocumentType(documentType){
            this.DocumentTypesPaginate = [...this.DocumentTypesPaginate, documentType];
        },

        documentTypeSelected(document, index){
            this.tempAttachments[index].error = false
            this.tempAttachments[index].saved = false 
        },

        getDocumentIndex(document){ 
            for( let i in this.tempAttachments) {
                if(document._id === this.tempAttachments[i]._id)
                return i
            }
            return null
        },

        closeConfirm(document){
            this.$refs[`confirm-${document._id}`][0].doClose()
        },
        
        async delDocument(document){ 
            this.closeConfirm(document)
            let index = this.getDocumentIndex(document)
            if(document.id){ 
                this.saving = true 
                this.tempAttachments[index].saving = true  
                const response = await this.deleteDocument({
                    id: document.id, 
                    endpoint: this.endpoint, 
                    documentable_id: this.documentableId
                })
                this.saving = false 
                this.tempAttachments[index].saving = false  
                
                if(!response) return false 
            } 

            this.tempAttachments.splice(index, 1) 
        },

        sendDocuments(){
            this.tempAttachments.map((document, index) => {

                if(!document.saved){
                    if(document.document_type_id){
                        this.sendDocument(document, index)
                    }else {
                        document.error = true
                        return document
                    }
                }
                
            })
        },

        async sendDocument(document, index) {  

            this.saving = true 
            this.tempAttachments[index].saving = true 
            const formattedFormData = FormatFormData.formatFormData(document);
            let fun = 'createDocument'
            let data = {
                endpoint: this.endpoint, 
                documentable_id: this.documentableId, 
                data: formattedFormData
            }

            if(this.tempAttachments[index].id){
                fun = 'updateDocument'
                data.id = this.tempAttachments[index].id
            } 
            
            const response = await this[fun](data)
            this.saving = false
            this.tempAttachments[index].saving = false
            
            if(response){
                this.tempAttachments[index].id = response.data.id
                this.tempAttachments[index].saved = true
            }
        }, 
        selectDocumentTypeAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('documents.select_document_type', stateUser)
        },
        createtDocumentTypeAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('documents.create_document_type', stateUser)
        },
        saveDocumentsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('documents.btn_save', stateUser)
        },
        editDocumentsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('documents.btn_edit', stateUser)
        },
        dropDocumentsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('documents.dropzone', stateUser)
        },
    },
    mounted(){
        this.get()
    }, 
    watch: {
        tempAttachments: {
            deep: true,
            handler(oldValue, newValue){
                //console.log('watch tempAttachments', this.tempAttachments);
                 this.$emit('files', this.tempAttachments)
            }
        },
        reset:{
            handler(oldValue, newValue){
                this.tempAttachments = []
            }
        }
    },
    computed: {
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        },
        getTempAttachments() {
        return this.tempAttachments;
        },
        getAttachments() {
        return this.attachments;
        },
        
    },
};