import { required, minLength, email, maxLength, sameAs } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import FormatFormData from '@/utils/api/login/format-form-data.js';
import { mapActions } from 'vuex';
import Auth from '@/utils/auth.js';

/**
 * Login component
 */
export default {
    page: {
        title: "Login",
        meta: [{
            name: "description",
            content: appConfig.description,
        }, ],
    },
    data() {
        return {
            formData: {
                email: null,
            },
            passFormData: {
                
                email: null,
                token: null,
                password: null,
                confirmpass: null,
            },
            submitted: false,
            authError: null,
            tryingToLogIn: false,
            isAuthError: false,
            emailSended: false,
            loading: false,
            token: null
        };
    },
    validations: {
        formData: {
            email: {
                required,
                email
            }, 
        },
        passFormData:{
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            },
            confirmpass: {
                required,
                sameAsPassword: sameAs(function() {
                    return this.passFormData.password;
                  })
            },
        }
    }, 
    mounted() {
        document.body.classList.add("authentication-bg");
    },
    methods: {
        ...mapActions('login', ['sendEmailToPasswordReset', 'sendPasswordReset']),

        async submitEmailToPasswordReset(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError) {
                return
            }

            this.loading = true; 
            try{

                const res = await this.sendEmailToPasswordReset(this.formData)
                
                if(res)
                this.emailSended = true
                
            }catch(err){}
            
            
            this.loading = false; 

        },
        async submitPasswordReset(e) {
            e.preventDefault();

            this.$v.passFormData.$touch();
            if (this.$v.passFormData.$anyError) {
                return
            }

            this.loading = true; 
            try{
                const res = await this.sendPasswordReset(this.passFormData)
                if(res){
                    this.$toast('Senha Alterada com sucesso!')
                    this.$router.push({path: '/login'}) 
                }
                
            }catch(err){}
            this.loading = false; 

        },

    },
    created() { 
        if (this.$route.query.token) { 
            this.passFormData.token = this.$route.query.token
        }
    }
};