import projectsModel from '@/models/projects/projects';

const actions = {
    getCurrent() {
        return projectsModel.getCurrent()
    },
    getProjects({ commit, dispatch }, params) {
        return projectsModel.getPaginate(params)
    },
    getProjectById({ commit, dispatch },id) {
        return projectsModel.getById(id)
    },
    getTransactionTypesByProjectId({ commit, dispatch },id) {
        return projectsModel.transactionTypes(id)
    }, 
    createProject({ commit, dispatch },data) {
        return projectsModel.create(data)
    },
    updateProject({ commit, dispatch },params) {
        return projectsModel.update(params)
    },
    deleteProject({ commit, dispatch },id) {
        return projectsModel.delete(id)
    },
    setFavorites({ commit, dispatch },id) {
        return projectsModel.favorite(id)
    },
    deleteFavorite({ commit, dispatch },id) {
        return projectsModel.unfavorite(id)
    }, 
    
    /**
     * Users
     */
    getUsersByProjectId({ commit, dispatch },params) {
        return projectsModel.users(params)
    },
    attachUserProject({ commit, dispatch },params) {
        return projectsModel.usersAttach(params)
    },
    detachUserProject({ commit, dispatch },params) {
        return projectsModel.usersDetach(params)
    },
}

export default actions;