import { validationMixin } from "vuelidate";
import { required, minLength, email, maxLength } from "vuelidate/lib/validators";
import FormatFormData from '@/utils/api/users/format-form-data.js';
import { mapActions } from 'vuex';
import { ADMIN } from "../../../authguard/groups.map";

export default {
    mixins: [validationMixin],
    props:{
        groups: {
            required: true,
            default: null,
        }
    },
    components: {
    },
    data() {
        return {
            formData: {
                username: null,
                name: null,
                cpf: null,
                email: null,
                password: null,
                group_id: null,
            },
            ADMIN,
            GroupsList: null,
            apiFormErrors: {},
            creating: false,
            openForm: false,
            ProjectsUser: [],
        }
    },
    validations: {
        formData: {
            username: {
                required,
                minLength: minLength(3)
            },
            name: {
                required,
                minLength: minLength(6)
            },
            group_id: {
                required,
                minLength: minLength(1)
            },
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            },
        }
    },
    created(){
        this.GroupsList = this.groups
    },
    methods: {
        ...mapActions("projects", ['attachUserProject']),
        ...mapActions('users', ['createUser']),

        updateProjectsUser(data){
            this.ProjectsUser = data 
        },

        async sendProjects(user_id){
            var promises = [] 
            this.ProjectsUser.map(project => {
                if(!project.saved)
                promises = [...promises, 
                    this.sendProject(project, user_id) 
                ]
            });
            const response = await Promise.all(promises)
            this.$bvModal.hide('modal-create')
            this.$toast('Novo ' + this.$t('titles.user') + ' criado!', 'success')
            this.creating = false
        },
        
        
        async sendProject(project, user_id){
            this.creating = true 
            let formData = {
                project_id: project.id,
                user_id: user_id,                
                data:{}
            }  
            const response = await this.attachUserProject( formData ) 
            this.creating = false
            
            if(response){
            } 
        },
        
        async sendForm(e) {
            e.preventDefault();
            
            this.$v.formData.$touch();
            if (this.$v.formData.$anyError || this.creating) {
                return;
            }
            
            this.creating = true
            
            const formattedFormData = FormatFormData.formatFormData(this.formData);
            const response = await this.createUser(formattedFormData)
            this.creating = false
            
            if(response){
                this.$emit('newUser', response.data) 
                this.sendProjects(response.data.id)
                this.resetFormData()
            }
 
        },

        cancelForm(){
            this.resetFormData()
            this.openForm = false
        },

        resetFormData(){
            this.formData.username = null
            this.formData.name = null
            this.formData.cpf = null
            this.formData.email = null
            this.formData.password = null
            this.formData.group_id = null
            this.$v.$reset()
        }
    }
}