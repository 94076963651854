import financialSummariesModel from '@/models/financialSummaries/financialSummaries';
const axios = require("axios").default;
import { validateError } from '@/utils/errors';

const actions = { 
    getfinancialSummaries({} , params) {
        return financialSummariesModel.getPeriod(params)
    }, 
}

export default actions;