const axios = require("axios");
axios.defaults.withCredentials = true;

import Paginate from "@/utils/api/paginate.js";
import Auth from "@/utils/auth.js";
import { validateError } from "@/utils/errors";

const userModel = {
  async getPaginate(params) {
    params.query = Paginate.formatQueryParams(params.query);
    const response = await axios
      .get(
        encodeURI(`${process.env.VUE_APP_API_HOST}/projects/?`) + params.query,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },

  create(params) {
    let endpoint =
      params.parent_id === null || params.parent_id === undefined
        ? "/projects/"
        : "/projects/" + params.parent_id + "/subprojects";
    return axios
      .post(process.env.VUE_APP_API_HOST + endpoint, params.data, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err) => validateError(err));
  },
  update(params) {
    params._method = "PUT";
    return axios
      .post(process.env.VUE_APP_API_HOST + "/projects/" + params.id, params, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err) => validateError(err));
  },
  async delete(id) {
    const formData = new FormData();
    formData.append("_method", "DELETE");
    const response = await axios
      .delete(process.env.VUE_APP_API_HOST + "/projects/" + id, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err) => validateError(err));
    return response;
  },
  favorite(id) {
    const formData = new FormData();
    formData.append("_method", "PUT");
    return axios
      .post(
        process.env.VUE_APP_API_HOST + "/projects/" + id + "/favorite",
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },
  async unfavorite(id) {
    const formData = new FormData();
    formData.append("_method", "DELETE");
    const response = await axios
      .delete(
        process.env.VUE_APP_API_HOST + "/projects/" + id + "/favorite",
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },

  async getById(id) {
    const response = await axios
      .get(
        encodeURI(
          process.env.VUE_APP_API_HOST +
            "/projects/" +
            id +
            "?with[]=transaction_types&with[]=parent&with[]=project_template&with[]=subprojects&with[]=transaction_types.parent&withCount[]=documents&withCount[]=transactions"
        ),
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },
  async transactionTypes(id) {
    const response = await axios
      .get(
        encodeURI(
          process.env.VUE_APP_API_HOST +
            "/projects/" +
            id +
            "/transaction-types" +
            "?orderBy=position&orderDirection=asc&limit=500"
        ),
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },
  count(params) {
    let paginateQueryString =
      Paginate.convertPaginateOptionsAndFiltersToQueryString(params);
    return axios.get(
      process.env.VUE_APP_API_HOST + "/projects/count?" + paginateQueryString,
      {
        headers: { "X-XSRF-TOKEN": Auth.getAuthorizationToken() },
      }
    );
  },

  async notifiables(params) {
    params.query = Paginate.formatQueryParams(params.query);
    return axios
      .get(
        encodeURI(`${process.env.VUE_APP_API_HOST}/projects/?`) + params.query,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },

  /**
   * Users
   */

  async users(params) {
    params.query = Paginate.formatQueryParams(params.query);
    return axios
      .get(
        encodeURI(
          process.env.VUE_APP_API_HOST + "/projects/" + params.id + "/users/?"
        ) + params.query,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },

  async usersAttach(params) {
    params._method = "PUT";
    const response = await axios
      .put(
        encodeURI(
          process.env.VUE_APP_API_HOST +
            "/projects/" +
            params.project_id +
            "/users/" +
            params.user_id,
          params
        ),
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },

  async usersDetach(params) {
    params._method = "DELETE";
    const response = await axios
      .delete(
        encodeURI(
          process.env.VUE_APP_API_HOST +
            "/projects/" +
            params.project_id +
            "/users/" +
            params.user_id,
          params
        ),
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },
};

export default userModel;
