import userModel from '@/models/users/users';

const actions = {
    getCurrent() {
        return userModel.getCurrent()
    },
    getUsers({ commit, dispatch },params) {
        return userModel.getPaginate(params)
    },
    getUserById({ commit, dispatch },id) {
        return userModel.getById(id)
    },
    createUser({ commit, dispatch },data) {
        return userModel.create(data)
    },
    updateUser({ commit, dispatch },params) {
        return userModel.update(params)
    },
    deleteUser({ commit, dispatch },id) {
        return userModel.delete(id)
    },
}

export default actions;