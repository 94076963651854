<script>
export default {};
</script>

<template>
  <b-modal
    id="modal-loading"
    hide-header
    hide-footer
    centered
    :no-close-on-backdrop="true"
    content-class="modal-transparent-background text-center"
    style="background-color: transparent"
    bodyTextVariant="primary"
  >
    <b-spinner label="Loading..."></b-spinner>
  </b-modal>
</template>