<template>
    <span>
        <b-button :variant="btnType" :size="btnSize" v-b-modal="`modal-${token}`" v-html="text"></b-button>
        <b-modal :ref="`modal-${token}`" @shown="focusInput()" :id="`modal-${token}`" hide-footer centered  >
            <h4>Criar tipo de documento</h4>
            <b-form @submit.prevent="sendForm" method="post">
            <b-form-group id="input-group-1" class="mb-3" label-for="name-1">
                <label for="name-1">Nome</label>
                <b-form-input ref="documentName" v-model.trim="$v.formData.name.$model" type="text" placeholder="Ex.: Recibos, Faturas, Notas Fiscais..." :class="{ 'is-invalid': $v.formData.name.$error}" ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" class="mb-3" label-for="name-1">
                <label for="name-1">Descrição</label>
                <b-form-textarea
                id="textarea"
                v-model="formData.description"
                placeholder="Quais documentos serão cadastrados..."
                rows="3"
                max-rows="6"
                ></b-form-textarea> 
            </b-form-group> 
            <div class="d-flex justify-content-end">
                <b-form-group id="input-group-1" class="mb-3" label-for="name-1">
                    <b-button variant="link" @click="closeModal()" type="button" >Cancelar</b-button>
                    <b-button variant="primary" :disabled="creating" type="submit" ><b-spinner small label="salvando" v-if="creating"></b-spinner> Salvar</b-button>
                </b-form-group>
            </div>
            </b-form> 
        </b-modal>
    </span>
</template>

<script>
  import { uuid } from 'vue-uuid';
import FormatFormData from '@/utils/api/documents/format-form-data.js';
import { required, minLength} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
        
export default {
    props:{
      text: {
        default: `<i class="uil-folder-plus"></i>`,
        type: String,
      },
      btnType: {
        default: `link`,
        type: String,
      },
      btnSize: {
        default: `sm`,
        type: String,
      },
      documentType: {
        default: null, 
      },
    },
    data(){
        return {
            token: uuid.v4(),
            creating: false,
            formData: { 
                name: null,  
                description: null, 
            },
        }
    },
    methods:{
        ...mapActions("documentTypes", ["createDocumentType","updateDocumentType"]), 
        focusInput() {
            this.$refs.documentName.focus() 
        },
        async sendForm(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError || this.creating) {
                return;
            }

            this.creating = true

            const formattedFormData = FormatFormData.formatFormData(this.formData);
            
            if(this.documentType){
                 const response = await this.updateDocumentType({id: this.documentType.id, data: formattedFormData})
                if(response){
                    this.documentType.name =  JSON.parse(JSON.stringify(this.formData.name))
                    this.documentType.description =  JSON.parse(JSON.stringify(this.formData.description))
                    this.$emit('saved', {})
                }
            }else{
                const response = await this.createDocumentType({data: formattedFormData})
                if(response)
                this.$emit('created', response.data)
            }
            this.creating = false
            this.closeModal()
        }, 
        closeModal(){
            this.$refs[`modal-${this.token}`].hide()
        }
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(3)
            }, 
        }
    },
    mounted(){
        if(this.documentType){
            this.formData.name = this.documentType.name
            this.formData.description = this.documentType.description
        }
    },
}
</script>