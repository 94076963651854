const axios = require("axios");
axios.defaults.withCredentials = true;

import Paginate from "@/utils/api/paginate.js";
import Auth from "@/utils/auth.js";
import { validateError } from "@/utils/errors";

const templatesModel = {
  async getPaginate(params) {
      params.query = Paginate.formatQueryParams(params.query) 
      const response = await axios.get(encodeURI(`${process.env.VUE_APP_API_HOST}/project-templates/?`) + params.query, { 
          headers: {
            Accept: "application/json",
          },
      })
      .catch((err) => validateError(err));
    return response;
  },
  create(params) { 
    return axios
      .post(process.env.VUE_APP_API_HOST + '/project-templates', params.data, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err) => validateError(err));
  },
  update(params) {
    params.data._method = "PUT";
    return axios
      .post(process.env.VUE_APP_API_HOST + "/project-templates/" + params.id, params.data, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err) => validateError(err));
  },
  async delete(id) {
    const formData = new FormData();
    formData.append("_method", "DELETE");
    const response = await axios
      .delete(process.env.VUE_APP_API_HOST + "/project-templates/" + id, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err) => validateError(err));
    return response;
  },
 
 
};

export default templatesModel;
