import PageHeader from "@/components/PageHeader/PageHeader";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  maxLength,
  ValidationFunc,
} from "vuelidate/lib/validators";
import FormatFormData from "@/utils/api/transactions/format-form-data.js";
import { mapActions } from "vuex";
import { VMoney } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import DocumentsComponent from "@/components/Documents/DocumentsComponent.vue";
import { Util } from "leaflet";

export default {
  mixins: [validationMixin],
  components: {
    PageHeader,
    documents: DocumentsComponent,
    DatePicker,
  },

  data() {
    return {
      title: this.$route.params.name,
      headerItems: [
        {
          text: "Início",
          href: "/",
        },
        {
          text: "Projetos",
          href: "/projects",
        },
        {
          text: this.$route.params.name,
          active: true,
        },
      ],
      Project: null,
      transactionTypesPaginate: null,
      Months: null,
      Dates: [],
      monthStart: this.$moment().subtract(2, "months").toDate(),
      monthFinish: this.$moment().toDate(),
      monthsRange: [
        this.$moment().subtract(2, "months").toDate(),
        this.$moment().toDate(),
      ],
      loading: false,
      json_export_fields: [],
      json_export_data: [],
    };
  },
  methods: {
    ...mapActions("financialSummaries", ["getfinancialSummaries"]),
    ...mapActions("projects", ["getProjectById"]),

    printMonthValue(value) {
      if (value.value === 0) {
        return `R$ 0,00`;
      }

      let is_positive = true;
      let val = value.value;
      if (value.value < 0) {
        val = Math.abs(value.value);
        is_positive = false;
      }

      return `
       <span class="${is_positive ? "text-success" : "text-danger"}">
        <span ><i class="${is_positive ? "uil-plus" : "uil-minus"}"></i></span>
        R$ ${this.$Util.brMoney(val)}
      </span>
      `;
    },

    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },

    async getProjectData() {
      this.transactionTypesPaginate = null;
      this.loading = true;
      const response = await this.getProjectById(this.$route.params.id);
      this.loading = false;

      this.transactionTypesPaginate = [];

      if (response.data.id) {
        this.Project = response.data;
      }
      return;
    },

    async getProjectFinancialSummaries(date, index) {
      const response = await this.getfinancialSummaries({
        id: this.$route.params.id,
        data: {
          reference_date: date,
          period_code: "MONTH",
        },
      });
      response.data._id = this.$Util.getUuid();
      this.Months = [...this.Months, response.data];

      return response;
    },

    formatResponseFinancialSummaries(transaction_types) {
      let typesList = {};
      transaction_types.map((type) => {
        typesList[type.id] = type;
        type.subtypes?.map((subtype) => {
          typesList[subtype.id] = subtype;
        });
      });
      return typesList;
    },

    setValuesInTypes(data, index) {
      let typesList = this.formatResponseFinancialSummaries(
        data.transaction_types
      );

      this.transactionTypesPaginate.map((type) => {
        type.values[index].value +=
          (typesList[type.id].incomes_volume ?? 0) -
          (typesList[type.id].expenses_volume ?? 0);
        type.total[index].value +=
          (typesList[type.id].incomes_volume ?? 0) -
          (typesList[type.id].expenses_volume ?? 0);

        type.total_balance = type.balance ?? 0;

        type.subtypes?.map((subtype) => {
          subtype.values[index].value +=
            (typesList[subtype.id].incomes_volume ?? 0) -
            (typesList[subtype.id].expenses_volume ?? 0);
          type.total[index].value +=
            (typesList[subtype.id].incomes_volume ?? 0) -
            (typesList[subtype.id].expenses_volume ?? 0);

          type.total_balance +=
            (subtype.incomes_volume ?? 0) - (subtype.expenses_volume ?? 0);
        });
      });
    },

    createValuesInTypes(date) {
      this.transactionTypesPaginate.map((type) => {
        type.values = [...type.values, { date, value: 0 }];
        type.total = [...type.total, { date, value: 0 }];
        type.subtypes?.map((subtype) => {
          subtype.values = [...subtype.values, { date, value: 0 }];
        });
      });
    },

    printFinalCalc(month, value = null) {
      let text = '<i class="uil-plus"></i>';
      value =
        value !== null ? value : month.financial_summary.transactions_volume;
      let is_negative = false;

      if (value == 0) return "<b>R$ 0,00</b>";

      if (value < 0) {
        value = value * -1;
        is_negative = true;
        text = '<i class="uil-minus"></i>';
      }

      text += `R$ ${this.$Util.brMoney(value)}`;

      return `<b class="text-${
        is_negative ? "danger" : "success"
      }">${text}</b>`;
    },

    async getRepotsDates() {
      var times = this.$moment(this.monthsRange[1]).diff(
        this.$moment(this.monthsRange[0]),
        "months"
      );
      this.Months = [];
      this.transactionTypesPaginate = [];
      this.Dates = [];
      var id = this.$route.params.id;
      var promises = [];
      for (let i = 0; i <= times; i++) {
        let date =
          this.$moment(this.monthsRange[0])
            .add(i, "months")
            .format("YYYY-MM-") + "01";
        this.Dates = [...this.Dates, date];

        promises = [...promises, this.getProjectFinancialSummaries(date, i)];
      }
      this.loading = true;
      const response = await Promise.all(promises);
      this.loading = false;

      this.transactionTypesPaginate = [];
      this.Months[0].transaction_types.map((transaction_type) => {
        this.setParentData(transaction_type);
        transaction_type.subtypes?.map((subtype) => {
          this.setParentData(subtype, true, transaction_type.name);
        });
      });

      this.Dates.map((date) => {
        this.Months;
        this.createValuesInTypes(date);
      });

      this.Months = this.$Util.quickSort(
        this.Months,
        "financial_summary.reference_date",
        "date"
      );

      this.Months.map((month, index) => {
        this.setValuesInTypes(month, index);
      });

      this.prepareJsonToXlsExport();

      //console.log('this.transactionTypesPaginate', this.transactionTypesPaginate)
      return;

      if (response) {
      }
    },

    setParentData(transaction_type, subtype = false, parent_name = "") {
      let id = subtype ? transaction_type.parent_id : transaction_type.id;
      let name = subtype ? parent_name : transaction_type.name;
      let data = {
        id: id,
        name: name,
        is_expense: transaction_type.is_expense,
        budget_estimative: transaction_type.pivot.budget_estimative,
        balance:
          (transaction_type.pivot.incomes_volume ?? 0) -
          (transaction_type.pivot.expenses_volume ?? 0),
        subtypes: [],
        values: [],
        total: [],
      };

      let subtype_data = {
        parent_id: transaction_type.parent_id,
        id: transaction_type.id,
        name: transaction_type.name,
        is_expense: transaction_type.is_expense,
        budget_estimative: transaction_type.pivot.budget_estimative,
        balance:
          (transaction_type.pivot.incomes_volume ?? 0) -
          (transaction_type.pivot.expenses_volume ?? 0),
        values: [],
      };

      for (let i in this.transactionTypesPaginate) {
        if (this.transactionTypesPaginate[i].id === id) {
          if (subtype) {
            this.transactionTypesPaginate[i].subtypes = [
              ...this.transactionTypesPaginate[i].subtypes,
              subtype_data,
            ];
          }
          return;
        }
      }

      if (subtype) data.subtypes = [...data.subtypes, subtype_data];

      this.transactionTypesPaginate = [...this.transactionTypesPaginate, data];
    },

    setMonthsFromCurrent(times) {
      this.monthStart = this.$moment().subtract(times, "months").toDate();
      this.monthFinish = this.$moment().toDate();
      this.monthsRange = [this.monthStart, this.monthFinish];
      this.getRepotsDates();
    },
    setWidthCol(values) {
      return `width: calc((100% - 150px) / ${values.length});`;
    },
    setWidthCard(values) {
      if (
        150 * values.length <
        document.querySelectorAll(".page-content")[0].clientWidth
      )
        return `max-width: 100%;`;
      return `width: calc((150px * ${values.length}) - 200px);`;
    },

    prepareJsonToXlsExport() {
      var data = [];
      var name = "0-month";
      var budget_estimative_name = "e-type";
      var balance_name = "e-balance";

      this.json_export_fields = {
        Meses: name,
        "Estimativa de Gastos": budget_estimative_name,
        "Saldo Atual": balance_name,
      };

      this.transactionTypesPaginate.map((type) => {
        let balance_subtotal = 0;
        let row = {};
        row[name] = type.name;
        row[budget_estimative_name] =
          "R$ " + this.$Util.brMoney(type.budget_estimative ?? 0);
        balance_subtotal += type.balance ?? 0;
        row[balance_name] = "R$ " + this.$Util.brMoney(type.balance ?? 0);
        row.__class_styles = {
          tr: "teste-tr",
          td: [],
        };

        type.values.map((value, i) => {
          if (i === 3) {
            row.__class_styles.td.push("td-3");
          } else {
            row.__class_styles.td.push("td-ok");
          }

          row[value.date] = "R$ " + this.$Util.brMoney(value.value);
          this.json_export_fields[this.$moment(value.date).format("MM/YYYY")] =
            value.date;
        });
        data = [...data, row];

        type.subtypes?.map((subtype) => {
          row = {};
          row[name] = subtype.name;
          row[budget_estimative_name] =
            "R$ " + this.$Util.brMoney(subtype.budget_estimative ?? 0);
          balance_subtotal += subtype.balance ?? 0;
          row[balance_name] = "R$ " + this.$Util.brMoney(subtype.balance ?? 0);

          subtype.values.map((value) => {
            row[value.date] = "R$ " + this.$Util.brMoney(value.value);
          });
          data = [...data, row];
        });

        row = {};
        row[name] = "Subtotal";
        row[budget_estimative_name] = "";
        row[balance_name] = "R$ " + this.$Util.brMoney(balance_subtotal ?? 0);

        type.total.map((value) => {
          row[value.date] = "R$ " + this.$Util.brMoney(value.value);
        });
        data = [...data, row];

        /* Quebra de linha */
        row = {};
        row[name] = "";
        row[budget_estimative_name] = "";

        data = [...data, row];
      });

      let summaryBalance = { [budget_estimative_name]: "" },
        noExpenseRow = { [budget_estimative_name]: "" },
        expenseRow = { [budget_estimative_name]: "" },
        totalRow = { [budget_estimative_name]: "" },
        prevSummaryBalance = { [budget_estimative_name]: "" };

      summaryBalance[balance_name] = "";
      noExpenseRow[balance_name] =
        "R$ " + this.$Util.brMoney(this.Project.incomes_volume);
      expenseRow[balance_name] =
        "R$ " + this.$Util.brMoney(this.Project.expenses_volume);
      totalRow[balance_name] =
        "R$ " + this.$Util.brMoney(this.Project.transactions_volume);
      prevSummaryBalance[balance_name] =
        "R$ " + this.$Util.brMoney(this.Project.balance);

      summaryBalance[name] = "Saldo do mês anterior";
      noExpenseRow[name] = "Total Entradas";
      expenseRow[name] = "Total Despesas";
      totalRow[name] = "Total de Movimentações";
      prevSummaryBalance[name] = "Saldo do Projeto";
      this.Months.map((month) => {
        summaryBalance[month.financial_summary.reference_date] =
          "R$ " +
          this.$Util.brMoney(month.previous_financial_summary.project_balance);
        noExpenseRow[month.financial_summary.reference_date] =
          "R$ " + this.$Util.brMoney(month.financial_summary.incomes_volume);
        expenseRow[month.financial_summary.reference_date] =
          "R$ " + this.$Util.brMoney(month.financial_summary.expenses_volume);
        totalRow[month.financial_summary.reference_date] =
          "R$ " +
          this.$Util.brMoney(month.financial_summary.transactions_volume);
        prevSummaryBalance[month.financial_summary.reference_date] =
          "R$ " + this.$Util.brMoney(month.financial_summary.project_balance);
      });

      this.json_export_data = [
        ...data,
        summaryBalance,
        noExpenseRow,
        expenseRow,
        totalRow,
        prevSummaryBalance,
      ];
    },

    async get() {
      await this.getProjectData();
      this.getRepotsDates();
    },

    editProjectsIsAuthorized(stateUser) {
      return this.$featureAuthorizer.isAuthorized(
        "projects.index.btn_edit",
        stateUser
      );
    },
  },
  created() {
    this.get();
  },
  computed: {
    stateUser: {
      get() {
        return this.$store.state.auth.user;
      },
    },
    titleExport() {
      return `Resumo de transações entre os meses ${this.$moment(
        this.monthsRange[0]
      ).format("MM/YYYY")} e ${this.$moment(this.monthsRange[1]).format(
        "MM/YYYY"
      )} `;
    },
    get_json_export_fields() {
      return this.json_export_fields;
    },
    get_json_export_data() {
      return this.json_export_data;
    },
  },
};
