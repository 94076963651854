
import PageHeader from "@/components/PageHeader/PageHeader";
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email, maxLength } from "vuelidate/lib/validators";
import FormatFormData from '@/utils/api/projects/format-form-data.js';
import { VMoney } from 'v-money';
import DocumentsComponent from '@/components/Documents/DocumentsComponent.vue';
import TransactionTypesProjectComponent from '@/components/Transactions/TransactionTypes/TransactionTypesProjectComponent.vue';
import draggable from "vuedraggable";

export default {
    components: {
        draggable,
        PageHeader,
        documents: DocumentsComponent,
        'transaction-types-project': TransactionTypesProjectComponent
    },
    directives: {
        money: VMoney
    },
    data() {
        return{
            title: this.$route.params.name, 
            Project: null,
            Users: null,
            formData: {
                id: this.$route.params.id,
                parent_id: null, 
                name: null, 
                budget_estimative: null, 
                description: null, 
            },
            headerItems: [{
                    text: 'Início',
                    href: '/'
                },
                {
                    text: 'Projetos',
                    href: '/projects'
                },
                {
                    text: this.$route.params.name,
                    active: true
                }
            ],
            moneyConfig: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false,
            },
            dropzoneOptions: {
                id: 1,
                url: 'https://httpbin.org/post',
                thumbnailWidth: 200,
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='uil-cloud-upload'></i> Carregar Documentos"
            },
            TransactionTypesPaginate: null,
            TransactionTypes: null,
            transaction_type_temp: null,
            saving: false,
            tabIndex: 0,
        }
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(3)
            },
            budget_estimative: {
                required,
                minLength: minLength(1)
            },
        }
    },
    methods:{
        ...mapActions("projects", ["getProjectById", "updateProject", "getTransactionTypesByProjectId", "deleteProject"]),
        ...mapActions("transactionTypes", ["getTransactionTypes", "syncTransactionTypes"]),
        ...mapActions("users", ["getUsers"]),
        async get(){
            
            const response = await this.getProjectById(this.$route.params.id) 
            if(response.data.id){
                this.Project = response.data
                this.setFormData(response.data)
            } 
        },  
        setFormData(data){
            this.formData.parent_id = data.parent_id
            this.formData.name = data.name
            this.formData.budget_estimative = data.budget_estimative
            this.formData.description = data.description
        },
        async sendForm(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError || this.saving) {
                return;
            }

            this.saving = true
            
            let data = JSON.parse(JSON.stringify(this.formData))
            data.budget_estimative = this.$Util.clearMoney(data.budget_estimative)

            const response = await this.updateProject(data)
            this.saving = false
            
            if(response){
                //this.$emit('created', response.data)
                this.$toast('Dados Salvos', 'success')
            }
        }, 
        sendingEvent(file, xhr, formData){
            
        },

        getUsersList(){
            this.getUsers()
        },

        changeUser(ev){
        },

        async delProject(){
            const response = await this.deleteProject(this.Project.id)

            if(response){
                this.$toast('Projeto deletado com sucesso!')
                this.$router.push({path: '/'})
            }

        },
        confirmDeleteProject(){
          this.$confirmDelete('Deseja realmente deletar este projeto?', this.delProject)  
        },

        isDeletable(project){
            return project.documents_count == 0 && project.transactions_count == 0
        },

        hashActions(){
            switch(this.$route.hash){
                case '#documents':
                    this.tabIndex = 3
                    break;
                case '#users':
                    this.tabIndex = 2
                    break; 
                case '#transaction-types':
                    this.tabIndex = 1
                    break; 
            }
        },

        
    },
    created() {
        this.hashActions()
        this.get()
    },
    computed: {
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        },
        getTransactionTypesComputed() {
        return this.TransactionTypesPaginate;
        }, 
    },
}
