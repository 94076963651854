import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';
import { mapActions } from 'vuex';
import DocumentExportModal from './DocumentExportModal/DocumentExportModal.vue'

export default {
    components: {
        DatePicker,
        DocumentExportModal
    },

    data(){
        return {
            api_domain: process.env.VUE_APP_API_HOST,

            TransactionableType:[
                {id: null,                      name: 'Selecione'},
                {id:"App\\Models\\Transaction", name: this.$t('titles.transactions')},
                {id:"App\\Models\\Transfer",    name: this.$t('titles.transfer')},
                {id:"App\\Models\\Project",     name: this.$t('titles.projects')},
                {id:"App\\Models\\User",        name: this.$t('titles.users')},
            ],

            PaginateFilter:{
                name: null,
                dataRange: [null, null],
            },
            PaginateQuery:{
                orderBy: 'id',
                orderDirection: 'DESC', 
                'with[]': [ 
                    'createdBy',
                    'createdBy.group',
                    'projects',
                    'projects.users',
                    'projects.transaction_types',
                    'users',
                    'users.group',
                    'transaction_types'
                ],
                
                limit: 10,
                page: 1,
            },
            PaginateParams: null,
            Paginate: null,
            TransactionTypes: [],


            fields: [ 
                { key: 'file_url', label: 'Download' }, 
                { key: 'projects', label: 'Projetos' }, 
                { key: 'createdBy', label: 'Criado por' }, 
                { key: 'created_at', label: 'Criado em' }, 
                { key: 'expiration_at', label: 'Expira em' }, 
                { key: 'actions', label: 'Ação' }, 
            ],
            items: null,
            saving: false,

        }
    },
    methods:{
        ...mapActions("documentExports", ["getDocumentExports", "createDocumentExport", "updateDocumentExport", "deleteDocumentExport"]), 
 
        async DocumentExportsPaginates(){
            let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
            
            PaginateQuery = this.setAdditionalsFilters(PaginateQuery)

            const response = await this.getDocumentExports({query: PaginateQuery})
            this.Paginate = response.data
            this.items = []
            this.Paginate.data.map(item => { 
                item.deleting = false
                this.items = [...this.items, item]
            })
            
        },

        getFilename(item){
            return `${this.$Util.formatDate(item.created_at, "Archive") }_${this.$Util.leftPad(item.id, 3)}_Exportacao_de_Arquivos.zip`;
        },

        createdTransactionType(data){
            this.get()
        },
        savedTransactionType(data){ 
            this.items.map(item => {
                if(item.id === data.id) item = data
                return item
            })
        },

        closeConfirm(item){
            let index = `del-doc-type-confirm-${item.id}`
            try{
                this.$refs[index].doClose()
            } catch(err) {
            }
        },
        
        async delTransaction(item){ 
            this.closeConfirm(item)
            

            item.deleting = true 
            const response = await this.deleteDocumentExport(item.id)
            item.deleting = false 
            
            if(!response) return false 

            this.$toast('Exportação deletada!')
            this.removeItem(item)
        },

        removeItem(item){
            for(let i in this.items){
                if(this.items[i].id === item.id){
                    this.items.splice(i,1)
                    break 
                }
            }
        },

        rowClass(item, type) {
            let classes  = 'position-relative'
            if (!item || type !== 'row') return classes

            if(!item.processed_at && item.processing_failed_at)
                classes += ' text-danger'

            if (item.deleting) return 'table-danger'

            return classes
        }, 
        
        setAdditionalsFilters(PaginateQuery){

            if(this.PaginateFilter.name)
            PaginateQuery['where[name:%like%]'] = this.PaginateFilter.name  

            if(this.PaginateFilter.dataRange[0]){
                PaginateQuery['where[created_at:>=]'] = this.$moment(this.PaginateFilter.dataRange[0]).format('YYYY-MM-DD')
                PaginateQuery['where[created_at:<=]'] = this.$moment(this.PaginateFilter.dataRange[1]).format('YYYY-MM-DD')
            }
            
            return PaginateQuery
        },

        get(){
            this.items = null
            this.DocumentExportsPaginates()
        }
        

    },
    mounted(){
        this.get()
    },
    watch:{
        'PaginateQuery.page':{
            handler(){
                this.get()
            }
        }
    }

}