<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.toast:not(.showing):not(.show) {
  opacity: 1 !important;
}

.toast .toast-header .close {
  padding: 0;
  background-color: transparent;
  border: 0;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  margin-left: auto;
}

.toast .toast-header strong {
  margin-right: 0.5rem;
}

.custom-vue-select .vs__dropdown-toggle {
  height: 39px;
}

.modal-transparent-background {
  background: transparent !important;
  border: 0 !important;
}
.mx-datepicker{
  &.is-invalid{
    .mx-input{
      border: 1px solid #f46a6a !important;
    }
    .mx-icon-calendar{
      color: #f46a6a !important;
    }
  }
}

.v-select{
  &.is-invalid{
    .vs__dropdown-toggle{
      border-color: #f46a6a !important;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.text-right{
  text-align: right;
}

.mr-1 { margin-right: 0.25rem !important;}
.mr-2 { margin-right: 0.5rem !important;}
.mr-3 { margin-right: 1.25rem !important;}
.mr-4 { margin-right: 2rem !important;}
.mr-5 { margin-right: 3.5rem !important;}

.ml-1 { margin-left: 0.25rem !important;}
.ml-2 { margin-left: 0.5rem !important;}
.ml-3 { margin-left: 1.25rem !important;}
.ml-4 { margin-left: 2rem !important;}
.ml-5 { margin-left: 3.5rem !important;}

.pr-1 { padding-right: 0.25rem !important;}
.pr-2 { padding-right: 0.5rem !important;}
.pr-3 { padding-right: 1.25rem !important;}
.pr-4 { padding-right: 2rem !important;}
.pr-5 { padding-right: 3.5rem !important;}

.pl-1 { padding-left: 0.25rem !important;}
.pl-2 { padding-left: 0.5rem !important;}
.pl-3 { padding-left: 1.25rem !important;}
.pl-4 { padding-left: 2rem !important;}
.pl-5 { padding-left: 3.5rem !important;}
 
.filter-button-collapse{
  &:active,
  &:visited,
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>

<script>
export default {
  created() {
    document.title = this.$title ;
  },
};
</script>