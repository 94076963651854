import { validationMixin } from "vuelidate";
import { required, minLength, email, maxLength } from "vuelidate/lib/validators";
import FormatFormData from '@/utils/api/projects/format-form-data.js';
import { mapActions } from 'vuex';
import { VMoney } from 'v-money';

export default {
    mixins: [validationMixin],
    components: {
    },
    directives: {
        money: VMoney
    },
    props:{
        parentId: null
    },
    data() {
        return {
            formData: {
                parent_id: this.parentId, 
                name: null, 
                budget_estimative: null, 
                description: null, 
                project_template_id: null,
            },
            apiFormErrors: {},
            creating: false,
            moneyConfig: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false,
            },
            TemplatesPaginate: null,
        }
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(3)
            },
            budget_estimative: {
                required,
                minLength: minLength(1)
            },
        }
    },
    created(){ 
        this.get()
    },
    methods: {
        ...mapActions('templates', ['getTemplates']),
        ...mapActions('projects', ['createProject']),
        async get() { 
            if(!this.parentId){
                try{
                    const response = await this.getTemplates({query: { 'with[]': 'transaction_types', 'orderBy': 'name'}})
                    if(response)
                    this.TemplatesPaginate = response.data.data
                }catch(err) {
                }

            }
        },
        async sendForm(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError || this.creating) {
                return;
            }

            this.creating = true
            
            let data = JSON.parse(JSON.stringify(this.formData))
            data.budget_estimative = this.$Util.clearMoney(data.budget_estimative)

            const formattedFormData = FormatFormData.formatFormData(data);
            const response = await this.createProject({parent_id: this.parentId, data: formattedFormData})
            this.creating = false
            
            if(response){
                //this.$emit('created', response.data)
                 this.$router.push({path: `/projects/edit/${response.data.id}/${response.data.name}`})
            }
        }, 
        
 
    }
}