const axios = require("axios");
axios.defaults.withCredentials = true

import Paginate from '@/utils/api/paginate.js';
import Auth from '@/utils/auth.js';
import { validateError } from '@/utils/errors';

const transactionTypesModel = {
    async getPaginate(params) {
        params.query = Paginate.formatQueryParams(params.query)
        const response = await axios.get(`${process.env.VUE_APP_API_HOST}/document-exports/?${params.query}`, {
            headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err));
        return response
    },
    async getPaginateByProjectId(id) {
        const response = await axios.get(`${process.env.VUE_APP_API_HOST}/projects/${id}/document-exports/?orderBy=position&orderDirection=asc`, {
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err));
        return response
    },
    create(params) { 
        return axios.post(process.env.VUE_APP_API_HOST + '/document-exports/' , params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    update(params) {
        params.data._method = 'PUT';
        return axios.post(process.env.VUE_APP_API_HOST + '/document-exports/' + params.id, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    }, 
    async delete(id) {
        const formData = new FormData()
        formData.append('_method', 'DELETE');
        const response = await axios.delete(process.env.VUE_APP_API_HOST + '/document-exports/' + id, formData, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
        return response
    }, 

    async getById(id) {
        const response = await axios.get(process.env.VUE_APP_API_HOST + '/document-exports/' + id + '?with[]=users&with[]=subprojects&with[]=transaction_types&with[]=parent', {
            headers: {
                'Accept': 'application/json',
            }
        }).catch(err => validateError(err));
        return response
    },
    count(params) {
        let paginateQueryString = Paginate.convertPaginateOptionsAndFiltersToQueryString(params);
        return axios.get(process.env.VUE_APP_API_HOST + '/document-exportscount/?' + paginateQueryString, {
            headers: { 'X-XSRF-TOKEN': Auth.getAuthorizationToken() }
        });
    }
};

export default transactionTypesModel;