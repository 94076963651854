const axios = require("axios");
axios.defaults.withCredentials = true

import Paginate from '@/utils/api/paginate.js';
import Auth from '@/utils/auth.js';
import { validateError } from '@/utils/errors';

const financialSummariesModel = {
    async getPeriod(params) {
        const response = await axios.post(`${process.env.VUE_APP_API_HOST}/projects/${params.id}/financial-summaries/report`, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
        return response
    }, 
};

export default financialSummariesModel;