import { required, minLength, email, maxLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import FormatFormData from '@/utils/api/login/format-form-data.js';
import { mapActions } from 'vuex';
import Auth from '@/utils/auth.js';

/**
 * Login component
 */
export default {
    page: {
        title: "Login",
        meta: [{
            name: "description",
            content: appConfig.description,
        }, ],
    },
    data() {
        return {
            formData: {
                username: null,
                password: null,
            },
            submitted: false,
            authError: null,
            tryingToLogIn: false,
            isAuthError: false,
            creating: true,
            loader: false,
            token: null
        };
    },
    validations: {
        formData: {
            username: {
                required,
                minLength: minLength(1)
            },
            password: {
                required
            }
        }
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
        notificationAutoCloseDuration() {
            return this.$store && this.$store.state.notification ? 5 : 0;
        },
    },
    mounted() {
        document.body.classList.add("authentication-bg");
    },
    methods: {
        ...mapActions('login', ['sendTo']),
        ...mapActions('users', ['getCurrent']),
        ...mapActions('auth', ['setUserData']),

        async getUser() {
            const res_user = await this.getCurrent()
            if (res_user.data.id === undefined)
                return false

                
            Auth.setAuthUserId(res_user.data.id)
            this.setUserData(res_user.data)
            return true
        },

        loading() {
            this.creating = false;
            this.loader = true
        },

        reseting() {
            this.creating = true;
            this.loader = false;
        },

        async checkForm(e) {
            e.preventDefault();

            this.$v.formData.$touch();
            if (this.$v.formData.$anyError) {
                return
            }

            this.loading(); 
            const formattedFormData = FormatFormData.formatFormData(this.formData);
            const res_login = await this.sendTo(formattedFormData)
            this.reseting();

            if (res_login.status !== 200 && res_login.status !== 204)
            return false
            
            Auth.setAuthorizationToken(res_login.config.headers["X-XSRF-TOKEN"])

            if (await this.getUser())
                if (this.$route.query.redirect) { 
                    this.$router.push({ path: atob(this.$route.query.redirect) })
                }else{

                    this.$router.push({ path: '/' })
                }

        },

    },
    created() {
        if (Auth.getAuthorizationToken())
            this.$router.push({ path: "/" });



        if (this.$route.query.toastErrorMessage) {
            this.$toast(this.$route.query.toastErrorMessage, 'error')
        }
    }
};