import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';
import { mapActions } from 'vuex';
import DocumentTypesComponent from "./../DocumentTypes/DocumentTypesComponent.vue";

export default {
    components: {
        DatePicker,
        'document-types': DocumentTypesComponent
    },

    data(){
        return {
            api_domain: process.env.VUE_APP_API_HOST,

            DocumentableType:[
                {id: null,                      name: 'Selecione'},
                {id:"App\\Models\\Transaction", name: this.$t('titles.transactions')},
                {id:"App\\Models\\Project",     name: this.$t('titles.projects')},
                {id:"App\\Models\\User",        name: this.$t('titles.users')},
            ],

            PaginateFilter:{
                name: null,
                dataRange: [null, null],
                document_type: null,
                documentable_type: null,
            },
            PaginateQuery:{
                orderBy: 'id',
                orderDirection: 'desc',
                //'withCount[]': 'documents',
                'with[]': [ 
                    'createdBy',
                    'createdBy.group',
                ],
                limit: 10,
                page: 1,
            },
            PaginateParams: null,
            Paginate: null,
            DocumentTypes: [],


            fields: [ 
                { key: 'name', label: 'Documento' }, 
                { key: 'createdBy', label: 'Criado por' }, 
                { key: 'created_at', label: 'Criado em' }, 
                { key: 'updated_at', label: 'Atualizado em' }, 
                { key: 'actions', label: 'Ação' }, 
                 
              ],
            items: null,
            saving: false,

        }
    },
    methods:{
        ...mapActions("documentTypes", ["getDocumentTypes", "deleteDocumentType"]), 

        async getDocumentsTypes(){
            try{
                const response = await this.getDocumentTypes()
                this.DocumentTypes = [{id: null, name: 'Selecione'}, ...response.data.data]
            } catch(err) {}
        },

        async DocumentsPaginates(){
            let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery))
            
            PaginateQuery = this.setAdditionalsFilters(PaginateQuery)

            const response = await this.getDocumentTypes({query: PaginateQuery})
            this.Paginate = response.data
            this.items = []
            this.Paginate.data.map(item => { 
                this.items = [...this.items, item]
            })
            
        },

        createdDocumentType(data){
            this.get()
        },
        savedDocumentType(data){ 
            this.items.map(item => {
                if(item.id === data.id) item = data
                return item
            })
        },

        closeConfirm(item){
            let index = `del-doc-type-confirm-${item.id}`
            try{
                this.$refs[index].doClose()
            } catch(err) {
            }
        },
        
        async delDocument(item){ 
            this.closeConfirm(item)
            

            item.deleting = true 
            const response = await this.deleteDocumentType(item.id)
            item.deleting = false 
            
            if(!response) return false 

            this.$toast('Documento deletado!')
            this.removeItem(item)
        },

        removeItem(item){
            for(let i in this.items){
                if(this.items[i].id === item.id){
                    this.items.splice(i,1)
                    break 
                }
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.deleting) return 'table-danger'
        }, 
        
        setAdditionalsFilters(PaginateQuery){

            if(this.PaginateFilter.name)
            PaginateQuery['where[name:%like%]'] = this.PaginateFilter.name  

            if(this.PaginateFilter.dataRange[0]){
                PaginateQuery['where[created_at:>=]'] = this.PaginateFilter.dataRange[0]
                PaginateQuery['where[created_at:<=]'] = this.PaginateFilter.dataRange[1]
            }
            
            return PaginateQuery
        },

        get(){
            this.items = null
            this.DocumentsPaginates()
        }
        

    },
    created(){
        document.title = "Configurações - Tipo de Documentos";
    },
    mounted(){
        this.get()
        this.getDocumentsTypes()
    },
    watch:{
        'PaginateQuery.page':{
            handler(){
                this.get()
            }
        }
    }

}