import Vue from "vue";
import transactionsModel from "@/models/transactions/transactions";

const actions = {
  exportTransactions({}, params) {
    return transactionsModel.export(params);
  },
  getTransactions({}, params) {
    return transactionsModel.getPaginate(params);
  },
  getTransactionById({ commit, dispatch }, params) {
    return transactionsModel.getById(params);
  },
  createTransaction({ commit, dispatch }, data) {
    return transactionsModel.create(data);
  },
  updateTransaction({ commit, dispatch }, params) {
    return transactionsModel.update(params);
  },
  confirmTransaction({ commit, dispatch }, params) {
    return transactionsModel.confirm(params);
  },
  cancelTransaction({ commit, dispatch }, params) {
    return transactionsModel.cancel(params);
  },
  deleteTransaction({ commit, dispatch }, params) {
    return transactionsModel.delete(params);
  },
  // Metodos para Status

  is_processed({}, transaction) {
    if (!transaction) return false;

    if (!transaction.processed_at) return false;

    if (
      Vue.prototype.$moment(transaction.processed_at).unix() <=
      Vue.prototype.$moment().unix()
    )
      return true;

    return false;
  },
  is_awaiting_processing({ commit, dispatch }, transaction) {
    if (!transaction) return false;
    if (!transaction.processed_at)
      if (
        Vue.prototype.$moment(transaction.date).format("YYYY-MM-DD") ===
          Vue.prototype.$moment().format("YYYY-MM-DD") &&
        Vue.prototype.$moment(transaction.created_at).format("YYYY-MM-DD") ===
          Vue.prototype.$moment().format("YYYY-MM-DD")
      )
        return true;
    return false;
  },
  is_pending_confirmation({ commit, dispatch }, transaction) {
    if (!transaction) return false;
    if (actions.is_processed(transaction)) {
      return false;
    } else if (actions.is_awaiting_processing(transaction)) {
      return false;
    } else {
      return true;
    }
  },
  is_overdue({ commit, dispatch }, transaction) {
    if (!transaction) return false;
    if (transaction.processed_at) return false;
    if (
      Vue.prototype.$moment(transaction.date).unix() <
      Vue.prototype.$moment().unix()
    )
      return true;
  },
};

export default actions;
