import { required, minLength, email, maxLength, ValidationFunc } from "vuelidate/lib/validators";
import { mapActions } from "vuex"

export default {
    props:{
        transfer: {
            default: null,
        },
        transaction: {
            default: null,
            required: true,
        }
    },

    data() {
      return { 
        formDataCancel:{
            description: null,
        },
        item:{
            is_overdue: null,
            is_awaiting_processing: null,
        },
        canceling: false,
        confirming: false,
        deleting: false,
      }  
    },

    validations:{
        formDataCancel:{
            description:{
                required: true,
                minLength: minLength(4),
            }
        }
    },
    
    methods:{
        ...mapActions("transfers", ["deleteTransfer", "confirmTransfer", "cancelTransfer"]),
        ...mapActions("transactions", ["deleteTansfer", "confirmTransaction", "cancelTransaction", "is_processed", "is_awaiting_processing", "is_pending_confirmation", "is_overdue"]),
        async confirmDeleteTransaction(){

        },

        
        async sendDeleteTransaction(){
            this.deleting = true
            try {
                let fun = this.transaction.transfer_id ? 'deleteTransfer': 'deleteTransaction'
                let id = this.transaction.transfer ? this.transaction.transfer.id: this.transaction.id
                let project_id = this.transaction.transfer ? this.transaction.transfer.from_project_id: this.transaction.project_id
                const response = await this.deleteTransfer({
                    id: id,
                    project_id: project_id,
                })
                if(response){
                    this.$toast(`${this.$t('titles.transaction')} Deletada`)
                    this.$emit('del', this.transaction)
                }
            } catch(error){
                this.$toast(`Não foi possivel deletar a ${this.$t('titles.transaction')}`, 'error')
            }
            this.deleting = false
        },
         
        async sendConfirmTransaction(){
            this.confirming = true
            try {
                let fun = this.transaction.transfer_id ? 'confirmTransfer': 'confirmTransaction'
                let id = this.transaction.transfer ? this.transaction.transfer.id: this.transaction.id
                let project_id = this.transaction.transfer ? this.transaction.transfer.from_project_id: this.transaction.project_id
                const response = await this[fun]({
                    id: id,
                    project_id: project_id,
                })

                if(response){
                    //this.$emit('update', response.data)
                    this.$toast(`${this.$t('titles.transaction')} Confirmada`)
                    
                    let transaction = response.data
                    
                    if(response.data.transactions){
                        transaction = response.data.transactions[0].id === this.transaction.id ? 
                        response.data.transactions[0] : 
                        response.data.transactions[1]
                    }

                    this.transaction.confirmed_at = transaction.confirmed_at
                    this.transaction.processed_at = transaction.confirmed_at
                }

            } catch(error){
                this.$toast(`Não foi possivel confirmar a ${this.$t('titles.transaction')}`, 'error')
            }
            this.confirming = false
        },
        async sendCancelTransaction(){

            this.$v.formDataCancel.$touch();
            if (this.$v.formDataCancel.$anyError || this.canceling) {
                return;
            }

            this.canceling = true
            try {
                let fun = this.transaction.transfer_id ? 'cancelTransfer': 'cancelTransaction'
                let id = this.transaction.transfer ? this.transaction.transfer.id: this.transaction.id
                let project_id = this.transaction.transfer ? this.transaction.transfer.from_project_id: this.transaction.project_id
                const response = await this[fun]({
                    id: id,
                    project_id:  project_id,
                    data: this.formDataCancel
                })

                if(response){
                    //this.$emit('update', response.data)
                    this.$toast(`${this.$t('titles.transaction')} cancelada`)

                    let transaction = response.data
                    
                    if(response.data.transactions){
                        transaction = response.data.transactions[0].id === this.transaction.id ? 
                        response.data.transactions[0] : 
                        response.data.transactions[1]
                    }

                    this.transaction.cancelled_at = transaction.cancelled_at
                }

            } catch(error){
                this.$toast(`Não foi possivel cancelar a ${this.$t('titles.transaction')}`, 'error')
            }
            this.canceling = false

        },

        async init(){
            this.item.is_overdue = await this.is_overdue(this.transaction)
            this.item.is_awaiting_processing = await this.is_awaiting_processing(this.transaction)
        },

        actionsProjectIsAuthorized(stateUser){
            return this.$featureAuthorizer.isAuthorized('projects.transaction.view.actions', stateUser)
        },
    },
    mounted(){
        this.init();
    },
    computed:{
        stateUser: {
            get(){
                return this.$store.state.auth.user
            }
        },
    }
}