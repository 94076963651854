import { render, staticRenderFns } from "./DocumentsComponent.vue?vue&type=template&id=a6c34fa0"
import script from "./DocumentsComponent.js?vue&type=script&lang=js&external"
export * from "./DocumentsComponent.js?vue&type=script&lang=js&external"
import style0 from "./DocumentsComponent.vue?vue&type=style&index=0&id=a6c34fa0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports