import PageHeader from "@/components/PageHeader/PageHeader";
import { validationMixin } from "vuelidate";
import { required, minLength, email, maxLength, ValidationFunc } from "vuelidate/lib/validators";
import FormatFormData from '@/utils/api/transactions/format-form-data.js';
import { mapActions } from 'vuex';
import { VMoney } from 'v-money';

export default {
    components: {
        PageHeader, 
    },
    data() {
        return {
            title: 'Configurações',
            headerItems: [
                {
                    text: 'Início',
                    href: '/'
                }, 
                {
                    text: 'Configurações',
                    active: true
                }
            ], 
            tabCurrent: 0,
        }
    },
}