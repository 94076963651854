import Vue from "vue";
import { listFieldById, FEATURES_SCOPES } from "./groups.map"
import Auth from "./../utils/auth.js";
import userModel from "./../models/users/users";
import routes from "../router/routes.map";

export const AuthGuard = {
    async isUnauthorized(authGroupScopes){ 
        if(!authGroupScopes) return false
        try {
            let list = listFieldById()

            Vue.prototype.$overlay()
            const response = await userModel.getCurrent();
            Vue.prototype.$overlayClose()

            if(authGroupScopes[list[response.data.group_id]]){
                return false
            }
            
        } catch (err) {}

        return true
        
    }
}

Vue.prototype.$featureAuthorizer = {
    isAuthorized(path, stateUser){ 
        for(let i in FEATURES_SCOPES){
            if(FEATURES_SCOPES[i].path === path && FEATURES_SCOPES[i].scopes.indexOf(stateUser.group_id) !== -1)
            return true
        }
        
        return false
    },
    routeIsAuthorized(path, stateUser){ 
        if(this.isTheRoute(routes, path, stateUser.group_id))
        return true

        return false
    },
    isTheRoute(routes, path, group_id){
        for(let i in routes){
            if(routes[i].path === path){
                return this.verifyRoute(routes[i], group_id)
            }else{
                if(routes[i].children) 
                    return this.isTheRoute(routes[i].children, path, group_id)
            }
        }
        return true
    },
    verifyRoute(route, group_id){
        let list = listFieldById()
        if(!route.meta) return true
        if(!route.meta.authGroupScopes) return true
        if(!route.meta.authGroupScopes[list[group_id]]) 
        return false

        return true
    }
}