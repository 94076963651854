//Quando desenvolvi esse código, eu e Deus sabíamos o que estava escrito. Agora só Deus sabe.
export default {
    formatQueryParams(query_params){
        if(query_params === undefined || query_params === null) 
        return ''
        
        let result_string = ''
        Object.keys(query_params).map( (field,i) => {
            try{
                query_params[field].map(item => {
                    result_string += `&${this.encodeQueryParams(field)}=${this.encodeQueryParams(item) + ''}`
                })
            } catch {
                result_string += `&${this.encodeQueryParams(field)}=${this.encodeQueryParams(query_params[field] + '')}`
            }
        })
        return result_string.replace('&', '')
    },

    encodeQueryParams(str){
        return str
        .replace(/\%/gi, '%25')
        .replace(/\=/gi, '%3D')
        .replace(/\:/gi, '%3A')
        .replace(/\//gi, '%2F')
        .replace(/\?/gi, '%3F')
        .replace(/\#/gi, '%23')
        .replace(/\[/gi, '%5B')
        .replace(/\]/gi, '%5D')
        .replace(/\@/gi, '%40')
        .replace(/\!/gi, '%21')
        .replace(/\$/gi, '%24')
        .replace(/\&/gi, '%26')
        .replace(/\'/gi, '%27')
        .replace(/\(/gi, '%28')
        .replace(/\)/gi, '%29')
        .replace(/\*/gi, '%2A')
        .replace(/\+/gi, '%2B')
        .replace(/\,/gi, '%2C')
        .replace(/\;/gi, '%3B')
        .replace(/ /gi,  '%20')
    },

    convertPaginateOptionsAndFiltersToQueryString(options) {
        if (!options) return "";
        let queryString = "";

        if (options.filters) {
            if (options.filters.search) {
                let searchString = this.formatSearch(options.filters.search);
                if (searchString) {
                    queryString += "search=" + searchString;
                }
            }
            if (options.filters.searchGroup) {
                let searchGroupsQueryString = this.formatSearchGroups(options.filters.searchGroup);
                if (queryString && searchGroupsQueryString) {
                    queryString += "&";
                }
                if (searchGroupsQueryString) {
                    queryString += searchGroupsQueryString;
                }
            }

            if (options.filters.searchFields) {
                let searchString = this.formatSearch(options.filters.searchFields);
                if (queryString && searchString) {
                    queryString += "&";
                }
                if (searchString) {
                    queryString += "searchFields=" + searchString;
                }
            }

            if (options.filters.searchJoin) {
                if (queryString) {
                    queryString += "&";
                }
                queryString += "searchJoin=" + options.filters.searchJoin;
            }

            if (typeof options.filters.allocated != 'undefined') {
                if (queryString) {
                    queryString += "&";
                }
                queryString += "allocated=" + options.filters.allocated;
            }

            if (typeof options.filters.notShareholderOfEntityId != 'undefined') {
                if (queryString) {
                    queryString += "&";
                }
                queryString += "notShareholderOfEntityId=" + options.filters.notShareholderOfEntityId;
            }

            if (typeof options.filters.notShareholderOfEntityCnpj != 'undefined') {
                if (queryString) {
                    queryString += "&";
                }
                queryString += "notShareholderOfEntityCnpj=" + options.filters.notShareholderOfEntityCnpj;
            }
        }


        if (options.with) {
            let withQueryString = this.formatWith(options.with);
            if (queryString && withQueryString) {
                queryString += "&";
            }
            if (withQueryString) {
                queryString += "with=" + withQueryString;
            }
        }

        if (options.current_page) {
            if (queryString) {
                queryString += "&";
            }
            queryString += "page=" + options.current_page;
        }

        if (options.per_page) {
            if (queryString) {
                queryString += "&";
            }
            queryString += "limit=" + options.per_page;
        }

        if (options.order_by) {
            if (queryString) {
                queryString += "&";
            }
            queryString += "orderBy=" + options.order_by;
        }

        if (options.order_by) {
            if (queryString) {
                queryString += "&";
            }
            queryString += "orderBy=" + options.order_by;
        }

        if (options.sorted_by) {
            if (queryString) {
                queryString += "&";
            }
            queryString += "sortedBy=" + options.sorted_by;
        }

        //TODO Sort by id asc

        return queryString;
    },
    formatSearch(search) {
        let searchParameters = [];
        for (let key in search) {
            if (!search.hasOwnProperty(key)) continue;
            let value = search[key];
            if (typeof value === "undefined" || value === "") continue;
            if (typeof value !== "object") {
                searchParameters.push({ key: key, value: value });
            } else {
                let objectSearchParameter = this.formatSearchObject(value);
                if (objectSearchParameter) {
                    let parameterKey = key + "." + objectSearchParameter.key;
                    searchParameters.push({ key: parameterKey, value: objectSearchParameter.value });
                }
            }
        }

        let searchString = "";
        if (searchParameters) {
            for (let i in searchParameters) {
                if (i > 0) {
                    searchString += ";";
                }
                searchString += searchParameters[i].key + ":" + searchParameters[i].value;
            }
        }

        return searchString;
    },
    formatSearchObject(searchObject) {
        for (let key in searchObject) {
            if (!searchObject.hasOwnProperty(key)) continue;
            let value = searchObject[key];
            if (!value) continue;
            if (typeof value !== "object") {
                return { key: key, value: value };
            } else {
                let objectSearchParameter = this.formatSearchObject(value);
                if (objectSearchParameter) {
                    let parameterKey = key + "." + objectSearchParameter.key;
                    return { key: parameterKey, value: objectSearchParameter.value }
                }
            }
            return null;
        }
    },
    formatSearchGroups(searchGroups) {
        let queryString = "";
        for (let i in searchGroups) {
            if (!searchGroups[i].search) continue;
            let searchString = this.formatSearch(searchGroups[i].search);
            if (queryString && searchString) {
                queryString += "&";
            }
            if (searchString) {
                queryString += "searchGroup[" + i + "][search]=" + searchString;
            }
        }
        return queryString;
    },
    formatWith(withOptions) {
        let queryString = "";
        for (let i in withOptions) {
            if (queryString) {
                queryString += ";";
            }
            queryString += withOptions[i];
        }
        return queryString;
    }
};