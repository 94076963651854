import PageHeader from "@/components/PageHeader/PageHeader";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  maxLength,
  ValidationFunc,
} from "vuelidate/lib/validators";
import FormatFormData from "@/utils/api/transactions/format-form-data.js";
import { mapActions } from "vuex";
import { VMoney } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import DocumentsComponent from "@/components/Documents/DocumentsComponent.vue";
import Auth from "@/utils/auth.js";

const validateImage = (file) => {};

export default {
  mixins: [validationMixin],
  components: {
    PageHeader,
    documents: DocumentsComponent,
    DatePicker,
  },
  directives: {
    money: VMoney,
  },
  props: {
    projectId: null,
    dateStart: null,
    dateFinish: null,
    reportMode: false,
    transactionTypeId: null,
  },
  data() {
    return {
      UserId: Auth.getAuthUserId(),
      formDataDefault: {},
      formData: {
        project_id: null,
        transaction_type_id: null,
        value: 0,
        date: this.$moment().toDate(),
        name: null,
        is_expense: true,
        description: null,
        is_recurrence: false,
        to_project_id: null,
      },
      resetDocuments: null,
      apiFormErrors: {},
      creating: false,
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      title: this.$route.params.name,
      headerItems: [
        {
          text: "Início",
          href: "/",
        },
        {
          text: "Projetos",
          href: "/projects",
        },
        {
          text: "Rúbrica",
        },
        {
          text: "",
          active: true,
        },
      ],
      ProjectId: null,
      Project: null,
      transactionTypesPaginate: null,
      transactionTypesPaginateForFilter: null,
      transactionTypesPaginateForTransaction: null,
      TransactionsPaginate: null,
      TransactionsDocuments: [],
      TransactionType: null,
      pageTransactions: 1,
      loadingTransactions: true,
      exporting: false,
      PaginateAllResults: 0,
      PaginateQuery: {
        page: 1,
        orderBy: "id",
        orderDirection: "desc",
        limit: 10,
        "with[]": [
          //'transaction_type',
          "createdBy",
          "createdBy.group",
          "transfer",
        ],
      },
      PaginateFilter: {
        dataRange: [
          this.dateStart ? this.$moment(this.dateStart).toDate() : null,
          this.dateFinish ? this.$moment(this.dateFinish).toDate() : null,
        ],
        where: null,
        name: null,
        is_expense: null,
        transaction_type_id: null,
      },
      PaginateQueryFinal: {},
      ProjectsPaginateQuery: {
        orderBy: "name",
        orderDirection: "asc",
        "where[id:<>]": this.$route.params.id,
      },
      ProjectsPaginate: [],
      conditionsTransactions: [
        {
          id: null,
          name: "Todos",
        },
        {
          id: "processed_at",
          name: "Processados",
        },
        {
          id: "cancelled_at",
          name: "Cancelados",
        },
      ],
      NotifiableUsers: null,
    };
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      is_expense: {
        required,
      },
      transaction_type_id: {
        required,
      },
      date: {
        required,
      },
    },
  },
  mounted() {
    if (this.reportMode) this.$bvModal.show("filter-transaction");
  },

  created() {
    this.formDataDefault = JSON.parse(JSON.stringify(this.formData));
    this.ProjectId = this.$route.params
      ? this.$route.params.id
      : this.projectId;
    this.formData.project_id = this.ProjectId;
    this.PaginateFilter.transaction_type_id = this.transactionTypeId;
    this.init();
  },
  methods: {
    ...mapActions("documents", ["createDocument"]),
    ...mapActions("transfers", ["createTransfer"]),
    ...mapActions("projects", [
      "getUsersByProjectId",
      "getProjectById",
      "getProjects",
    ]),
    ...mapActions("transactions", [
      "exportTransactions",
      "getTransactions",
      "createTransaction",
      "updateTransaction",
    ]),
    ...mapActions("transactionTypes", ["getTransactionTypes"]),
    ...mapActions("financialSummaries", ["getfinancialSummaries"]),

    listAllTransactionsTypes(types) {
      let allPypes = [];
      types.map((type) => {
        allPypes.push(type);
        type.subtypes?.map((subtype) => {
          allPypes.push(subtype);
          return subtype;
        });
        return type;
      });
      return allPypes;
    },

    async getProjectFinancialSummaries() {
      let date = this.$moment().format("YYYY-MM-") + "01";
      const response = await this.getfinancialSummaries({
        id: this.$route.params.id,
        data: {
          reference_date: date,
          period_code: "MONTH",
        },
      });

      this.TransactionType = this.listAllTransactionsTypes(
        response.data.transaction_types
      ).filter(
        (type) => type.id === parseInt(this.$route.params.transaction_type_id)
      )[0];
    },

    async getProjectData() {
      const response = await this.getProjectById(this.ProjectId);
      if (response) {
        response.data.transaction_types.map((transaction_type) => {
          if (transaction_type.parent)
            transaction_type.name = `${transaction_type.parent.name} > ${transaction_type.name}`;

          return transaction_type;
        });
        this.transactionTypesPaginate = response.data.transaction_types;
        this.getTransactionTypesSystemicPaginate();
        this.Project = response.data;
      }
    },

    async getTransactionTypesSystemicPaginate() {
      const response = await this.getTransactionTypes({
        query: { "where[is_systemic]": 1 },
      });
      this.transactionTypesPaginateForFilter = [];
      if (response) {
        //console.log(response.data.data);
        response.data.data.map((transaction_type) => {
          if (transaction_type.is_expense) {
            transaction_type.name += ` ⇩`;
          } else {
            transaction_type.name += ` ⇧`;
          }

          this.transactionTypesPaginateForFilter = [
            ...this.transactionTypesPaginate,
            transaction_type,
          ];

          return transaction_type;
        });
        this.transactionTypesPaginate = this.$Util.quickSort(
          this.transactionTypesPaginate,
          "name",
          "text"
        );
      }
    },

    async getProjectsPaginate() {
      const response = await this.getProjects({
        query: this.ProjectsPaginateQuery,
      });
      this.ProjectsPaginate = response.data.data;

      if (this.ProjectsPaginate.length !== 0) {
        this.formData.to_project_id = this.ProjectsPaginate[0].id;
      }
    },

    async getProjectNotifiableUsers() {
      const response = await this.getUsersByProjectId({
        id: this.ProjectId,
        query: { "withScopes[]": "transactionNotifiable", "with[]": "group" },
      });

      this.NotifiableUsers = [];
      response.data.data.map((user) => {
        user.notifiable = true;
        this.NotifiableUsers = [...this.NotifiableUsers, user];
      });
    },

    getFilters() {
      let PaginateQuery = JSON.parse(JSON.stringify(this.PaginateQuery));

      if (this.PaginateFilter.dataRange[0]) {
        PaginateQuery["where[date:>=]"] = this.$moment(
          this.PaginateFilter.dataRange[0]
        ).format("YYYY-MM-DD");
        PaginateQuery["where[date:<=]"] = this.$moment(
          this.PaginateFilter.dataRange[1]
        ).format("YYYY-MM-DD");
      }
      if (this.PaginateFilter.where) {
        switch (this.PaginateFilter.where) {
          case "cancelled_at":
            PaginateQuery["whereNotNull[]"] = "cancelled_at";
            break;
          case "processed_at":
            PaginateQuery["whereNull[]"] = "cancelled_at";
            PaginateQuery["whereNotNull[]"] = "processed_at";
            break;
          case "":
            break;
        }
      }

      if (this.PaginateFilter.name)
        PaginateQuery["where[name:like]"] = this.PaginateFilter.name;

      if (this.PaginateFilter.is_expense !== null)
        PaginateQuery["where[is_expense]"] = this.PaginateFilter.is_expense
          ? 1
          : 0;

      PaginateQuery["where[transaction_type_id]"] = this.TransactionType?.id;

      this.PaginateQuery.page++;
      this.PaginateQueryFinal = PaginateQuery;
      return PaginateQuery;
    },

    exportTransactionsFiltered() {
      this.exporting = true;

      let PaginateQuery = this.getFilters();

      this.exportTransactions({
        id: this.ProjectId,
        query: { ...PaginateQuery, page: 1 },
      })
        .then((result) => {
          this.saveStreamCSV(
            `${this.$moment().format("YYYY-MM-DD--HH-mm")}-Transacoes.csv`,
            result.data
          );
        })
        .catch((err) => console.error(err))
        .finally(() => (this.exporting = false));
    },

    saveStreamCSV(filename, text) {
      if (window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        var blobObject = new Blob([text], { type: "text/csv" });
        window.navigator.msSaveBlob(blobObject, filename);
      } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        var anchor = document.createElement("a");
        anchor.download = filename;
        if (window.URL.createObjectURL) {
          // Everything else new.
          var blobObject = new Blob([text], { type: "text/csv" });
          anchor.href = window.URL.createObjectURL(blobObject);
        } else {
          // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
          // Load up the data into the URI for "download."
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(text);
        }
        // Now, click it.
        if (document.createEvent) {
          var event = document.createEvent("MouseEvents");
          event.initEvent("click", true, true);
          anchor.dispatchEvent(event);
        } else {
          anchor.click();
        }
      }
    },

    async getProjectTransactionsPaginate() {
      this.loadingTransactions = true;

      let PaginateQuery = this.getFilters();
      try {
        const response = await this.getTransactions({
          id: this.ProjectId,
          query: PaginateQuery,
        });

        if (response) {
          this.PaginateAllResults = response.data.meta.total;
          response.data.data.map((transaction) => {
            this.TransactionsPaginate = [
              ...this.TransactionsPaginate,
              transaction,
            ];
            return transaction;
          });
        }
      } catch (error) {}

      this.loadingTransactions = false;
    },

    moreItems() {
      this.getProjectTransactionsPaginate();
    },
    async init() {
      this.getProjectData();
      this.getProjectNotifiableUsers();
      this.getProjectsPaginate();
      await this.getProjectFinancialSummaries();
      this.get();
    },

    async get() {
      this.PaginateQuery.page = 1;
      this.TransactionsPaginate = [];
      this.getProjectTransactionsPaginate();
    },
    async sendForm(e) {
      e.preventDefault();

      this.$v.formData.$touch();
      if (this.$v.formData.$anyError || this.creating) {
        return;
      }

      for (let i in this.TransactionsDocuments) {
        if (!this.TransactionsDocuments[i].document_type_id) {
          this.$toast(`Selecione o tipo dos documentos`, "error");
          return false;
        }
      }
      console.log("this.TransactionsDocuments", this.TransactionsDocuments);

      this.creating = true;

      let data = JSON.parse(JSON.stringify(this.formData));
      data.is_expense = data.is_expense ? 1 : 0;
      data.value = this.$Util.clearMoney(data.value);
      data.date = this.$moment(data.date).format("YYYY-MM-DD");

      if (data.is_recurrence)
        data.recurrence_day = this.$moment(data.date).format("DD");

      if (this.NotifiableUsers) {
        if (this.NotifiableUsers.length > 0) {
          data.transaction_user_notifications = {
            _ids: [],
          };
          this.NotifiableUsers.map((user) => {
            if (user.notifiable)
              data.transaction_user_notifications._ids = [
                ...data.transaction_user_notifications._ids,
                user.id,
              ];
          });
        }
      }

      if (this.formData.transaction_type_id === "transfer") {
        data.to_project_id = JSON.parse(
          JSON.stringify(this.formData.to_project_id)
        );
        const formattedFormData = FormatFormData.formatFormData(data);
        const response = await this.createTransfer({
          project_id: this.ProjectId,
          data: formattedFormData,
        });
        this.creating = false;
        if (response) {
          this.formData.id = response.data.id;
          this.get();
          this.sendDocuments();
        }
      } else {
        const formattedFormData = FormatFormData.formatFormData(data);
        const response = await this.createTransaction({
          project_id: this.ProjectId,
          data: formattedFormData,
        });
        this.creating = false;
        if (response) {
          this.formData.id = response.data.id;
          this.TransactionsPaginate = [
            response.data,
            ...this.TransactionsPaginate,
          ];
          this.sendDocuments();
        }
      }
    },

    async sendDocuments() {
      var promises = [];
      this.TransactionsDocuments.map((document) => {
        promises = [...promises, this.sendDocument(document)];
        console.log("Promisse documente criada");
      });
      const response = await Promise.all(promises);
      //this.$toast(`${this.$t('titles.documents')} Salvos`)
      this.$alt(`${this.$t("titles.transaction")} criada`);

      this.$refs.create_transaction_form.reset();
      this.formData = JSON.parse(JSON.stringify(this.formDataDefault));
      this.formData.date = this.$moment().toDate();
      this.$v.formData.$reset();
      this.setResetDocuments();
    },

    async sendDocument(document) {
      const formattedFormData = FormatFormData.formatFormData(document);
      return this.createDocument({
        endpoint:
          this.formData.transaction_type_id === "transfer"
            ? "transfers"
            : "transactions",
        documentable_id: this.formData.id,
        data: formattedFormData,
      });
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    setTransactionsDocuments(data) {
      this.TransactionsDocuments = data;
      console.log("teste event", this.TransactionsDocuments);
    },

    setResetDocuments() {
      this.resetDocuments = this.$Util.getUuid();
    },

    updateTransaction(newTransaction) {
      this.TransactionsPaginate.map((transaction) => {
        if (transaction.id === newTransaction.id) transaction = newTransaction;
        return transaction;
      });
    },

    delTransaction(deleteTransaction) {
      for (let i in this.TransactionsPaginate) {
        let transaction = this.TransactionsPaginate[i];
        if (transaction.id === deleteTransaction.id) {
          this.TransactionsPaginate.splice(i, 1);
          return;
        }
      }
    },

    /** featureAuthorizer  */

    createTransactionIsAuthorized(stateUser) {
      return this.$featureAuthorizer.isAuthorized(
        "projects.transactions.btn_create",
        stateUser
      );
    },
    editProjectIsAuthorized(stateUser) {
      return this.$featureAuthorizer.isAuthorized(
        "projects.transaction.btn_project_edit",
        stateUser
      );
    },
    projectBalanceIsAuthorized(stateUser) {
      return this.$featureAuthorizer.isAuthorized(
        "projects.transaction.project_balance",
        stateUser
      );
    },
    createTransfersIsAuthorized(stateUser) {
      if (stateUser.id != this.UserId) return false;

      if (this.transactionTypesPaginateForTransaction) return true;

      if (
        this.$featureAuthorizer.isAuthorized(
          "projects.transfer.create",
          stateUser
        )
      ) {
        this.transactionTypesPaginateForTransaction = [
          {
            id: null,
            name: `Selecionar ${this.$t("titles.transaction_type")}`,
          },
          { id: "transfer", name: `Transferência entre Projetos` },
          ...this.transactionTypesPaginate,
        ];
      } else {
        this.transactionTypesPaginateForTransaction = [
          {
            id: null,
            name: `Selecionar ${this.$t("titles.transaction_type")}`,
          },
          ...this.transactionTypesPaginate,
        ];
      }

      return true;
    },
  },
  computed: {
    stateUser: {
      get() {
        return this.$store.state.auth.user;
      },
    },
    getResetDocuments() {
      return this.resetDocuments;
    },
  },
};
