<template>
  <div class="d-flex align-items-center avatar-container">
    <b-avatar
      size="sm"
      variant="primary"
      :src="
        user.avatar_file_name ? `${api_domain}/users/${user.id}/avatar` : null
      "
      class="bg-soft-info mr-2"
      :text="$Util.nameInicials(user.name)"
    />
    <div>
      <span class="font-size-11 d-block my-0">{{ user.name }}</span>
      <small v-if="user.group" class="font-size-10 small d-block my-0">{{
        user.group.name
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCard",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api_domain: process.env.VUE_APP_API_HOST,
    };
  },
};
</script>
