export default {
    setAuthorizationToken(authorizationToken, remember = false) {
       // if (remember) {
            localStorage.setItem('authorizationToken', authorizationToken);
        //} else {
        //    sessionStorage.setItem('authorizationToken', authorizationToken);
        //}
    },

    getAuthorizationToken() {
        //let authorizationToken = sessionStorage.getItem('authorizationToken');
        //if (!authorizationToken) {
          let authorizationToken = localStorage.getItem('authorizationToken');
        //}
        return authorizationToken ? authorizationToken : null;
    },

    removeAuthorizationToken() {
        localStorage.removeItem('authorizationToken');
        sessionStorage.removeItem('authorizationToken');
    },

    setAuthUserId(userId, remember = false) {
        //if (remember) {
            localStorage.setItem('authUserId', userId);
        //} else {
        //    sessionStorage.setItem('authUserId', userId);
        //}
    },
    removeAuthUserId() {
        localStorage.removeItem('authUserId');
        //sessionStorage.removeItem('authUserId');
    },

    getAuthUserId() {
       // let authUserId = sessionStorage.getItem('authUserId');
        //if (!authUserId) {
        let authUserId = localStorage.getItem('authUserId');
        //}
        return authUserId ? authUserId : null;
    }
}