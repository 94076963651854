const axios = require("axios");
axios.defaults.withCredentials = true;

import Paginate from "@/utils/api/paginate.js";
import Auth from "@/utils/auth.js";
import { validateError } from "@/utils/errors";

const transferModel = {
  async getPaginate(params) {
    params.query = Paginate.formatQueryParams(params.query);
    const response = await axios
      .get(
        encodeURI(
          `${process.env.VUE_APP_API_HOST}/projects/${params.id}/transactions/?`
        ) + params.query,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },
  async export(params) {
    params.query = Paginate.formatQueryParams(params.query);
    const response = await axios
      .get(
        encodeURI(
          `${process.env.VUE_APP_API_HOST}/projects/${params.id}/transactions/export/?`
        ) + params.query,
        {
          headers: {
            Accept: "text/csv",
          },
        }
      )
      .catch((err) => validateError(err));
    return response;
  },
  create(params) {
    return axios
      .post(
        `${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transactions/`,
        params.data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },
  update(params) {
    params.data.append("_method", "PUT");
    return axios
      .post(
        `${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transactions/`,
        params.data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },
  confirm(params) {
    params.data = { _method: "PUT" };
    return axios
      .post(
        `${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transactions/${params.id}/confirm`,
        params.data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },
  cancel(params) {
    params.data._method = "PUT";
    return axios
      .post(
        `${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transactions/${params.id}/cancel`,
        params.data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },
  delete(params) {
    params.data = { _method: "DELETE" };
    return axios
      .delete(
        `${process.env.VUE_APP_API_HOST}/projects/${params.project_id}/transactions/${params.id}`,
        params.data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },

  getById(params) {
    return axios
      .get(
        process.env.VUE_APP_API_HOST +
          "/projects/" +
          params.project_id +
          "/transactions/" +
          params.transaction_id +
          "/?with[]=project",
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .catch((err) => validateError(err));
  },
  count(params) {
    let paginateQueryString =
      Paginate.convertPaginateOptionsAndFiltersToQueryString(params);
    return axios.get(
      process.env.VUE_APP_API_HOST +
        "/transactions/count?" +
        paginateQueryString,
      {
        headers: { "X-XSRF-TOKEN": Auth.getAuthorizationToken() },
      }
    );
  },
};

export default transferModel;
