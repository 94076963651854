<template>
<div @click="toggle" class="switch-button-control">
    <div class="switch-button" :class="{ enabled: isEnabled }"  :style="{'--color-switch': color}">
      <div class="button"></div>
    </div>
    <div class="switch-button-label">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
    name: 'SwitchButtonComponent',
    model: {
        prop: "isEnabled",
        event: "toggle"
    },
    props: {
        isEnabled: null,
        int: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "#2191fa"
        },
        confirm: {
            type: Boolean,
            required: false,
            default: false
        },
        confirmEnable: {
            type: Boolean,
            required: false,
            default: false
        },

    },
    methods: {
        toggle: function() {
            if(this.confirm){

                let showConfirm = !this.int ? !this.isEnabled : this.isEnabled === 0 ? true : false

                if(showConfirm === this.confirmEnable) {
                    this.$swal({
                        title: 'Deseja mesmo realizar esta ação?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Confirmar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) 
                            this.$emit("toggle", !this.int ? !this.isEnabled : this.isEnabled === 0 ? 1 : 0);
                    })
                }else {
                    this.$emit("toggle", !this.int ? !this.isEnabled : this.isEnabled === 0 ? 1 : 0);
                }

            } else {
                this.$emit("toggle", !this.int ? !this.isEnabled : this.isEnabled === 0 ? 1 : 0);
            }
        }
    }
}
</script>
<style lang="scss">
    // For switch-button styling
    $switch-button-color: var(--color-switch);
    $color-inative: #ddd;
    $trasitions: all .3s ease-in-out;
    $btn-size: 20px;
    .switch-button-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        transition: $trasitions;
        .switch-button {
            display: flex;
            justify-content: start;
            width: calc($btn-size * 2);
            background-color: currentColor;
            border: 1px solid currentColor;
            cursor: pointer;
            transition: $trasitions;
            border-radius: 100px;
            color: $color-inative;
            .button {
                background: white;
                height: $btn-size;
                width: $btn-size;
                border-radius: 100px; 
                transition: $trasitions;
                margin: 0 !important;
                transform: translateX(calc(0%));
                box-shadow: 0 0 4px #0001;
            }
            &.enabled {
                color: $switch-button-color;
                background-color: $switch-button-color;
                box-shadow: none;
                .button {
                    transform: translateX(calc(100% - 2px));
                }
            }
        }
        .switch-button-label {
            margin-left: 10px;
        }
    }
</style>