<script>
const axios = require("axios").default;
import router from "@/router";
import { layoutComputed } from "@/store/helpers";
import Auth from "@/utils/auth.js";

import Topbar from "@/components/PageTopbar/PageTopbar";
import Sidebar from "@/components/LeftSidebar/LeftSidebar";
//import RightSidebar from "@/components/RightSidebar/RightSidebar";
import Footer from "@/components/Footer/Footer";
import ModalLoading from "@/components/ModalLoading.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  components: {
    Topbar,
    Sidebar,
    //RightSidebar,
    Footer,
    ModalLoading,
  },
  computed: {
    dataUser: {
      get() {
        return this.$store.state.auth.user;
      },
      set(value) {
        this.$store.commit("SET_USER_DATA", value);
      },
    },
  },
  name: "Default",
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");

    try {
      if (!this.$store.state.auth.user.id.isInteger())
        if (Auth.getAuthorizationToken()) {
          this.getUser();
        }
    } catch {}
  },
  methods: {
    ...mapActions("users", ["getCurrent"]),
    ...mapActions("auth", ["setUserData"]),

    async getUser() {
      const res_user = await this.getCurrent();
      if (res_user.data.id === undefined) return false;

      this.setUserData(res_user.data);
      return true;
    },

    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  },
  mounted() {},
};
</script>

<template>
  <!-- Begin page -->
  <div id="layout-wrapper">
    <Topbar />
    <Sidebar :is-condensed="isMenuCondensed" :type="'light'" :width="'fluid'" />
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- <pre>{{$Permissions}}</pre> -->
          <router-view></router-view>
        </div>
      </div>
      <Footer />
    </div>
    <!-- end main content-->
    <!-- <RightSidebar />-->
    <ModalLoading></ModalLoading>
  </div>
</template>
