const axios = require("axios");
axios.defaults.withCredentials = true

import Paginate from '@/utils/api/paginate.js';
import Auth from '@/utils/auth.js';
import { validateError } from '@/utils/errors';

const userModel = {
    async getPaginate(params) {
      params.query = Paginate.formatQueryParams(params.query) 
      const response = await axios.get(encodeURI(`${process.env.VUE_APP_API_HOST}/users/?`) + params.query, { 
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err));
        return response
    },
    create(data) {
        return axios.post(process.env.VUE_APP_API_HOST + '/users/', data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    update(params) {
        params.data.append('_method', 'PUT');
        return axios.post(process.env.VUE_APP_API_HOST + '/users/' + params.id, params.data, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
    },
    async delete(id) {
        const formData = new FormData()
        formData.append('_method', 'DELETE');
        const response = await axios.delete(process.env.VUE_APP_API_HOST + '/users/' + id, formData, {
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch(err => validateError(err));
        return response
    },

    async getCurrent(token = null) {
        const response = await axios.get(process.env.VUE_APP_API_HOST + '/user/', {
                headers: {
                    'Accept': 'application/json',
                }
            })
            .catch(err => validateError(err))
        return response
    },

    async getById(id) {
        const response = await axios.get(process.env.VUE_APP_API_HOST + '/users/' + id + '/?with[]=projects', {
            headers: {
                'Accept': 'application/json',
            }
        }).catch(err => validateError(err));
        return response
    },
    count(params) {
        let paginateQueryString = Paginate.convertPaginateOptionsAndFiltersToQueryString(params);
        return axios.get(process.env.VUE_APP_API_HOST + '/users/count?' + paginateQueryString, {
            headers: { 'X-XSRF-TOKEN': Auth.getAuthorizationToken() }
        });
    }
};

export default userModel;