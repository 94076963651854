import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import FormatFormData from '@/utils/api/login/format-form-data.js';
import { mapActions } from 'vuex';
import Auth from '@/utils/auth.js';
import { validateError } from '@/utils/errors';

/**
 * Login component
 */
export default {
    page: {
        title: "Logout",
        meta: [{
            name: "description",
        }, ],
    },
    mounted() {
        Auth.removeAuthorizationToken()
        this.$router.push({ path: '/login' })
    },
};