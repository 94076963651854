import Vue from 'vue';
import router from '@/router'

const SUCCESS = [200, 204]
const UNAUTHORIZED = 401
const FORBIDDEN = 403
const NOTFOUND = 404
const INVALID_DATA = 422
const CSRF_TOKEN_MISMATCH = 419

export const validateError = function(error) {

    if(!error.response) return false

    switch (error.response.status) {

        case CSRF_TOKEN_MISMATCH:
            console.log('CSRF_TOKEN_MISMATCH', error.response)
            Vue.prototype.$toast(error.response.data.message, 'error')
                if (router.history.current.path !== '/login')
                    router.push({ path: "/logout" });
            break;
        case FORBIDDEN:
            console.log('FORBIDDEN', error.response)
            Vue.prototype.$toast(error.response.data.message, 'error') 
            break;
        case NOTFOUND:
            console.log('NOTFOUND', error.response)
            Vue.prototype.$toast(error.response.data.message, 'error') 
            router.push({ path: "/" });
            break;
        case UNAUTHORIZED:
            console.log('UNAUTHORIZED', error.response)
            Vue.prototype.$toast(error.response.data.message, 'error')
                if (router.history.current.path !== '/login')
                    router.push({ path: "/logout" });
            break;
        case INVALID_DATA:
            console.log('INVALID_DATA', error.response)
            let msg = error.response.data.message
            if(error.response.data.errors){
                error = error.response.data.errors
                msg = ''
                Object.keys(error).map((field, i) => {
                    if(i>0) msg += '/'
                    msg += `${field}: ${error[field]}`
                })
            }
            Vue.prototype.$toast(msg, 'error') 
            break;
        default:
            Vue.prototype.$toast(error.response.data.message, 'error')
    }

}