<template>
  <div>
    <PageHeader :title="title" :items="headerItems"></PageHeader>
    <div class="row" v-if="Paginate">
      <div class="col-md-6" v-for="(item, i) in Paginate" :key="i">
        <div class="card user-list-item-card">
          <div class="card-body">
            <div class="clearfix"></div>

            <div class="text-muted align-items-center mb-2 d-flex">
              <b class="mr-2">Gerado por:</b>
              <div class="">
                <user-card :user="item.created_by" />
              </div>
            </div>
            <p class="text-muted mb-2 d-flex">
              <b class="mr-2">Projeto(s): </b>
              <small class="text-truncate text-truncate-line-3">
                <small
                  v-for="(project, index) in item.projects"
                  :key="`project-${project.id}`"
                  >{{ index > 0 ? ";" : "" }} {{ project.name }}</small
                >
              </small>
            </p>
            <p class="text-muted mb-2">
              <b class="mr-2">Criado em: </b>
              {{ $Util.formatDate(item.created_at) }}
            </p>
            <div class="text-muted align-items-center mb-2 d-flex">
              <b class="mr-2">Expira em : </b>
              <div>
                {{ diffDays(item.expiration_at) }} dias -
                {{ $Util.formatDate(item.expiration_at, "Date") }}
              </div>
            </div>
          </div>
          <div role="group" class="btn-group">
            <a
              v-b-tooltip.hover
              :title="`Baixar: ${getFilename(item)}`"
              v-if="item.processed_at"
              class="btn btn-outline-light no-wrap text-truncate"
              :href="`${api_domain}/document-exports/${item.id}/file`"
              :download="getFilename(item)"
            >
              <div class="py-3">
                <i class="uil-file-alt me-2"></i>{{ getFilename(item) }}
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- empty data -->
      <div class="col-md-12" v-if="Paginate && !loading">
        <b-card v-if="Paginate.length === 0" style="" no-body class="p-3">
          <h3 class="text-muted text-center py-5 my-5">
            Nenhuma exportação encontrada!
          </h3>
        </b-card>
      </div>

      <!-- loading -->

      <div
        class="col-md-4"
        :class="{ 'd-none': !loading }"
        v-for="(item, i) in 5"
        :key="'a' + i"
      >
        <div class="card user-list-item-card text-center justify-content-end">
          <div class="card-body">
            <div class="d-flex justify-content-end pt-3">
              <b-skeleton animation="fade" width="35px" />
            </div>
            <div class="clearfix"></div>
            <div class="d-flex justify-content-center pb-3">
              <b-skeleton
                animation="fade"
                type="avatar"
                width="100px"
                height="100px"
              />
            </div>
            <div class="mb-4">
              <div class="d-flex justify-content-center">
                <b-skeleton animation="fade" width="80%" />
              </div>
              <div class="d-flex justify-content-center py-1">
                <b-skeleton animation="fade" width="60%" />
              </div>
              <div class="d-flex justify-content-center">
                <b-skeleton animation="fade" width="80%" />
              </div>
            </div>
          </div>
          <div role="group" class="btn-group d-flex justify-content-center">
            <b-skeleton animation="fade" width="20%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PageHeader from "@/components/PageHeader/PageHeader";
export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      api_domain: process.env.VUE_APP_API_HOST,
      title: "Documentos Exportados",
      headerItems: [
        {
          text: "Início",
          href: "/",
        },
        {
          text: "Documentos Exportados",
          active: true,
        },
      ],
      Paginate: null,
      loading: null,
    };
  },
  created() {
    document.title = "Documentos Exportados";
    this.paginateDocumentExports();
  },
  methods: {
    ...mapActions("documentExports", [
      "getDocumentExports",
      "createDocumentExport",
      "updateDocumentExport",
      "deleteDocumentExport",
    ]),

    async paginateDocumentExports() {
      this.loading = true;
      try {
        const response = await this.getDocumentExports({
          query: {
            "with[]": ["created_by", "created_by.group", "projects"],
          },
        });
        this.Paginate = response.data.data;
      } catch (err) {}
      this.loading = false;
    },
    diffDays(date) {
      const moment1 = this.$moment();
      const moment2 = this.$moment(date);
      return moment2.diff(moment1, "days");
    },
    getFilename(item) {
      return `${this.$Util.formatDate(
        item.created_at,
        "Archive"
      )}_${this.$Util.leftPad(item.id, 3)}_Exportacao_de_Arquivos.zip`;
    },
  },
};
</script>
